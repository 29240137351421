import AllApis from "app/Apis";
import {
  ALL_INVENTORY_RAW_MATERIAL_FAIL,
  ALL_INVENTORY_RAW_MATERIAL_REQUEST,
  ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
  ALL_ISSUED_DYING_FAIL,
  ALL_ISSUED_DYING_REQUEST,
  ALL_ISSUED_DYING_SUCCESS,
  ALL_ISSUED_GROUPING_FAIL,
  ALL_ISSUED_GROUPING_REQUEST,
  ALL_ISSUED_GROUPING_SUCCESS,
  ALL_ISSUED_SMOKING_FAIL,
  ALL_ISSUED_SMOKING_REQUEST,
  ALL_ISSUED_SMOKING_SUCCESS,
  ALL_OTHER_GOODS_CONSUMED_FAIL,
  ALL_OTHER_GOODS_CONSUMED_REQUEST,
  ALL_OTHER_GOODS_CONSUMED_SUCCESS,
  ALL_OTHER_GOODS_FAIL,
  ALL_OTHER_GOODS_REQUEST,
  ALL_OTHER_GOODS_SUCCESS,
  ALL_RAW_MATERIAL_HISTORY_FAIL,
  ALL_RAW_MATERIAL_HISTORY_REQUEST,
  ALL_RAW_MATERIAL_HISTORY_SUCCESS,
  ALL_READY_SHEET_FORM_FAIL,
  ALL_READY_SHEET_FORM_HISTORY_FAIL,
  ALL_READY_SHEET_FORM_HISTORY_REQUEST,
  ALL_READY_SHEET_FORM_HISTORY_SUCCESS,
  ALL_READY_SHEET_FORM_REQUEST,
  ALL_READY_SHEET_FORM_SUCCESS,
} from "app/utils/constants/inventoryConstant";

import { Axios } from "index";

//raw Material
export const getAllRawMaterial =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          // status: "available",
        },
        searchFields: {
          string: [
            "item_name",
            "item_code",
            "item_log_no",
            "item_grade",
            "item_pallete_no",
            "item_physical_location",
            "supplier_details.supplier_name",
            "invoice_no",
            "item_remark",
            "status",
          ],
          numbers: [
            "item_length",
            "item_width",
            "item_available_pattas",
            "item_available_sqm",
            "item_bundle_no",
            "item_rate_per_sqm",
            "item_available_quantities.natural",
            "item_available_quantities.smoked",
            "item_available_quantities.dyed",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_INVENTORY_RAW_MATERIAL_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.raw_material.list}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_INVENTORY_RAW_MATERIAL_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//other goods
export const getAllOtherGoods =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_name",
            "supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "units",
            "other_goods_remarks",
          ],
          numbers: ["received_quantity", "available_quantity", "rate"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_OTHER_GOODS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.other_goods.list}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_OTHER_GOODS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_OTHER_GOODS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//other goods consumed
export const getAllOtherGoodsConsumed =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_name",
            "supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "units",
            "processes",
            "other_goods_consumed_remarks",
          ],
          numbers: ["received_quantity", "consumption_quantity", "rate"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_OTHER_GOODS_CONSUMED_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.other_goods.consumptionList}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_OTHER_GOODS_CONSUMED_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_OTHER_GOODS_CONSUMED_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//issued grouping
export const getAllIssuedGrouping =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_id.item_name",
            "item_id.item_code",
            "item_id.item_log_no",
            "item_id.item_grade",
            "item_id.item_pallete_no",
            "item_id.item_physical_location",
            "item_id.supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
          numbers: [
            "item_id.item_bundle_no",
            "item_id.item_length",
            "item_id.item_width",
            "item_id.item_available_sqm",
            "item_id.item_available_pattas",
            "item_id.item_available_quantities.natural",
            "item_id.item_available_quantities.smoked",
            "item_id.item_available_quantities.dyed",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUED_GROUPING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.grouping.list}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUED_GROUPING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUED_GROUPING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//raw Material
export const getAllRawMaterialHistory =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_name",
            "item_code",
            "item_log_no",
            "item_grade",
            "item_pallete_no",
            "item_physical_location",
            "supplier_details.supplier_name",
          ],
          numbers: [
            "item_length",
            "item_width",
            "item_available_pattas",
            "item_available_sqm",
            "item_bundle_no",
            "item_available_sqm",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_RAW_MATERIAL_HISTORY_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.raw_material.history}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_RAW_MATERIAL_HISTORY_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_RAW_MATERIAL_HISTORY_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//issued smoking
export const getAllIssuedSmoking =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "issued for smoking",
        },
        searchFields: {
          string: [
            "item_id.item_name",
            "item_id.item_code",
            "item_id.item_log_no",
            "item_id.item_grade",
            "item_id.item_pallete_no",
            "item_id.item_physical_location",
            "item_id.supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
          numbers: [
            "item_id.item_length",
            "item_id.item_width",
            "item_id.item_available_sqm",
            "item_id.item_bundle_no",
            "item_id.item_received_pattas",
            "item_id.item_available_quantities.natural",
            "item_id.item_available_quantities.smoked",
            "item_id.item_available_quantities.dyed",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUED_SMOKING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.raw_material.list_issued_smoking}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUED_SMOKING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUED_SMOKING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//issued dyed
export const getAllIssuedDying =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "issued for dying",
        },
        searchFields: {
          string: [
            "item_id.item_name",
            "item_id.item_code",
            "item_id.item_log_no",
            "item_id.item_grade",
            "item_id.item_pallete_no",
            "item_id.item_physical_location",
            "item_id.supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
          numbers: [
            "item_id.item_length",
            "item_id.item_width",
            "item_id.item_available_sqm",
            "item_id.item_bundle_no",
            "item_id.item_available_pattas",
            "item_id.item_available_quantities.natural",
            "item_id.item_available_quantities.smoked",
            "item_id.item_available_quantities.dyed",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUED_DYING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.raw_material.list_issued_dying}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUED_DYING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUED_DYING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//Ready Sheet Form list

export const getAllReadySheetFormList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "ready_sheet_form_physical_location",
            "ready_sheet_form_pallete_no",
            "status",
            "remarks",
          ],
          numbers: [
            "group_no",
            "ready_sheet_form_sqm",
            "ready_sheet_form_length",
            "ready_sheet_form_width",
            "ready_sheet_form_no_of_pcs_original",
            "ready_sheet_form_no_of_pcs_available",
            "ready_sheet_form_rejected_pcs",
          ],
          arrayField: [
            "cutting_id.cutting_id.item_details.item_data.item_name",
            "cutting_id.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_READY_SHEET_FORM_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.ready_sheet_form.list}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_READY_SHEET_FORM_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_READY_SHEET_FORM_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//Ready Sheet Form History
export const getAllReadySheetFormHistory =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "ready_sheet_form_physical_location",
            "ready_sheet_form_pallete_no",
            "status",
            "remarks",
          ],
          numbers: [
            "group_no",
            "ready_sheet_form_sqm",
            "ready_sheet_form_length",
            "ready_sheet_form_width",
            "ready_sheet_form_no_of_pcs_original",
            "ready_sheet_form_no_of_pcs_available",
            "ready_sheet_form_rejected_pcs",
            "ready_sheet_form_approved_pcs",
          ],
          arrayField: [
            "cutting_id.cutting_id.item_details.item_data.item_name",
            "cutting_id.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_READY_SHEET_FORM_HISTORY_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.ready_sheet_form.historyList}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_READY_SHEET_FORM_HISTORY_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_READY_SHEET_FORM_HISTORY_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
