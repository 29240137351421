import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { supplierList } from "app/services/apis/supplierList";
import { City, Country, State } from "country-state-city";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { Axios } from "index";

export default function AddStock() {
  const { state, pathname } = useLocation();
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [rowData, setRowData] = useState({});
  const navigate = useNavigate();
  const [supplierNameList, setSupplierNameList] = useState([]);

  const stock = {
    date_of_inward: getCurrentDate(),
    invoice_no: "",
    supplier_details: {
      supplier_name: "",
      // country: "",
      // state: "",
      // city: "",
      // pincode: "",
      // bill_address: "",
      // delivery_address: "",
      // contact_Person_name: "",
      // contact_Person_number: "",
      // country_code: "",
      // email_id: "",
      // pan_no: "",
      // gst_no: "",
    },
    item_details: [
      {
        item_name: "",
        item_code: "",
        item_log_no: "",
        item_bundle_no: "",
        item_length: "",
        item_width: "",
        item_received_quantities: {
          natural: "",
          dyed: "",
          smoked: "",
          total: "",
        },
        item_received_pattas: "",
        item_received_sqm: "",
        item_available_quantities: {
          natural: "",
          dyed: "",
          smoked: "",
          total: "",
        },
        item_available_pattas: "",
        item_available_sqm: "",
        item_rejected_quantities: {
          natural: "",
          dyed: "",
          smoked: "",
          total: "",
        },
        item_rejected_pattas: 0,
        item_rejected_sqm: 0,
        item_pallete_no: "",
        item_physical_location: "",
        item_grade: "",
        item_rate_per_sqm: "",
        item_remark: "",
      },
    ],
  };

  const validationSchema = yup.object({
    date_of_inward: yup.date().required("Date of Inward is required"),
    invoice_no: yup
      .string("Enter Invoice No.")
      .required("Invoice No. is required"),
    supplier_details: yup.object({
      supplier_name: yup
        .string("Enter Supplier Name")
        .required("Supplier Name is required"),
      // bill_address: yup
      //   .string("Enter Bill Address")
      //   .required("Bill Address is required"),
      // delivery_address: yup
      //   .string("Enter Delivery Address")
      //   .required("Delivery Address is required"),

      // city: yup.string("Enter City").required("City is required"),
      // state: yup.string("Enter State").required("State is required"),
      // pincode: yup
      //   .string("Enter Pincode")
      //   // .required("Pincode is required")
      //   .matches(
      //     /^\d{6}$/,
      //     "Invalid PIN code. Please enter a valid 6-digit PIN code."
      //   ),
      // pan_no: yup.string().when("country", {
      //   is: "India",
      //   then: yup
      //     .string()
      //     // .required("PAN No is required")
      //     .matches(
      //       /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      //       "Invalid PAN No. Please enter a valid PAN No."
      //     ),
      //   otherwise: yup.string(),
      // }),

      // gst_no: yup.string().when("country", {
      //   is: "India",
      //   then: yup
      //     .string()
      //     // .required("GST No is required")
      //     .matches(
      //       /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      //       "Invalid GST No. Please enter a valid GST No."
      //     ),
      //   otherwise: yup.string(),
      // }),
      // country_code: yup
      //   .string("Enter Country Code")
      //   .required("Country Code is required"),
      // contact_Person_name: yup
      //   .string("Enter Contact Person Name")
      //   .required("Contact Person Name is required"),
      // contact_Person_number: yup
      //   .string("Enter Contact Person Phone No")
      //   .required("Contact Person Phone No is required"),
      // email_id: yup
      //   .string()
      //   .email("Enter a valid Supplier Email address")
      //   .required("Supplier Email is required"),
      // country: yup
      //   .string()
      //   .required("Country is required")
      //   .test(
      //     "supplier_country-not-select",
      //     "Please select a valid Country",
      //     (value) => value !== "Select"
      //   ),
    }),
    item_details: yup.array().of(
      yup.object().shape({
        item_name: yup.string().required("Required"),
        item_code: yup.string().required("Required"),
        item_log_no: yup
          .string()
          .matches(
            /^[a-zA-Z0-9/]*$/,
            "Item log number cannot contain symbols except /"
          )
          .required("Required"),
        item_bundle_no: yup.string().required("Required"),
        item_length: yup
          .number()
          .positive()
          .moreThan(0, "Must be greater than 0")
          .required("Required"),
        item_width: yup
          .number()
          .positive()
          .moreThan(0, "Must be greater than 0")
          .required("Required"),
        item_received_quantities: yup
          .object()
          .shape({
            natural: yup.number().min(0),
            dyed: yup.number().min(0),
            smoked: yup.number().min(0),
            total: yup.number().min(0),
          })
          .test(
            "only-one-nonzero",
            "Only one field should have a value greater than 0",
            function (values) {
              const { natural, dyed, smoked } = values;
              const countNonZero = [natural, dyed, smoked].filter(
                (value) => value > 0
              ).length;
              return countNonZero === 1;
            }
          ),
        item_received_pattas: yup.number().positive().required("Required"),
        item_received_sqm: yup
          .number()
          .positive()
          .moreThan(0, "Must be greater than 0")
          .required("Required"),
        item_available_quantities: yup.object().shape({
          natural: yup.number().min(0),
          dyed: yup.number().min(0),
          smoked: yup.number().min(0),
          total: yup.number().min(0),
        }),
        item_available_pattas: yup.number().positive().required("Required"),
        item_available_sqm: yup.number().positive().required("Required"),
        item_rejected_quantities: yup.object().shape({
          natural: yup.number().min(0),
          dyed: yup.number().min(0),
          smoked: yup.number().min(0),
          total: yup.number().min(0),
        }),
        item_rejected_pattas: yup.number(),
        item_rejected_sqm: yup.number(),
        item_pallete_no: yup.string().required("Required"),
        item_physical_location: yup.string().required("Required"),
        item_grade: yup.string().required("Required"),
        item_rate_per_sqm: yup.number().positive().required("Required"),
      })
    ),
  });

  const handleSubmit = async (values) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.raw_material.addInventory,
        values,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Inventory Added",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/inventory/rawmaterial");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode, countryCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) =>
        city.stateCode === stateIsoCode && city.countryCode == countryCode
    );
    setCityList(filteredCities);
  };

  const handleOnChange = (e, i, setFieldValue, values, name, value) => {
    let latestArray = [...values.item_details];
    if (name == "item_pallete_no") {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
      };
      let location = palleteList.filter((pal) => pal.pallete_no == value);
      latestArray[i] = {
        ...latestArray[i],
        item_physical_location: location[0]?.item_physical_location,
      };
    }
    if (name == "item_length" || name == "item_width") {
      const sqm =
        ((name === "item_length"
          ? Number(value)
          : Number(values?.item_details?.[i]?.item_length || 0)) *
          (name === "item_width"
            ? Number(value)
            : Number(values?.item_details?.[i]?.item_width || 0)) *
          (Number(values?.item_details?.[i]?.item_received_pattas) || 0)) /
        10000;

      latestArray[i] = {
        ...latestArray[i],
        item_received_sqm: sqm?.toFixed(2),
        item_available_sqm: sqm?.toFixed(2),
        [name]: value,
      };
    } else {
      latestArray[i] = {
        ...latestArray[i],
        [name]: value,
      };
    }

    setFieldValue("item_details", latestArray);
  };

  const handleOnChangeQty = (setFieldValue, values, name, value, index) => {
    const i = index;
    let quantity = {
      item_received_quantities: {
        ...values?.item_details?.[i]?.item_received_quantities,
        [name]: Number(value),
      },
      item_received_pattas: values?.item_received_pattas,
    };

    const { natural, dyed, smoked } = quantity.item_received_quantities;
    const totalReceived =
      (parseFloat(natural) || 0) +
      (parseFloat(dyed) || 0) +
      (parseFloat(smoked) || 0);

    quantity.item_received_quantities.total = totalReceived;
    quantity.item_received_pattas = totalReceived;

    let sqm = 0;
    if (
      values?.item_details?.[i]?.item_length != 0 &&
      values?.item_details?.[i]?.item_width != 0
    ) {
      sqm =
        (Number(values?.item_details?.[i]?.item_length || 0) *
          Number(values?.item_details?.[i]?.item_width || 0) *
          (Number(totalReceived) || 0)) /
        10000;
    }
    const latestArray = [...values.item_details];
    latestArray[i] = {
      ...latestArray[i],
      item_received_quantities: quantity.item_received_quantities,
      item_received_pattas: quantity.item_received_pattas,
      item_available_quantities: quantity.item_received_quantities,
      item_available_pattas: quantity.item_received_pattas,
      ...(sqm !== 0 && { item_received_sqm: sqm }),
      ...(sqm !== 0 && { item_available_sqm: sqm }),
    };
    setFieldValue("item_details", latestArray);
  };

  const removeRow = (values, setFieldValue, index) => {
    setLoader({ ...loader, removeProduct: true });
    setTimeout(() => {
      const updateData = [...values["item_details"]];
      updateData.splice(index, 1);
      setFieldValue("item_details", updateData);
      setLoader({ ...loader, removeProduct: false });
    }, 200);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state?.supplier_master?.supplier_country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state?.supplier_master?.supplier_state
      );
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode);
    }
  }, []);

  useEffect(async () => {
    try {
      const data = await supplierList();
      if (data.data.status == true) {
        setSupplierNameList(data.data.result);
      }

      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name}`);
      setItemNameList(itemNameList?.data?.result);

      const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
      setItemCodeList(itemCodeList?.data?.result);

      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);

      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/inventory/edit" ? "Edit Stock" : "Add Stock"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={false}
          initialValues={stock}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div sx={{ display: "flex", width: "100%", columnGap: 3 }}>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Inward Date*</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={
                          values?.date_of_inward !== ""
                            ? dayjs(values?.date_of_inward)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "date_of_inward",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="date_of_inward"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div sx={{ width: "20%", mb: 2 }}>
                    <Typography variant="h5">Supplier Name*</Typography>

                    <Select
                      size="small"
                      sx={{ width: "100%" }}
                      name="supplier_name"
                      onChange={(e, options) => {
                        // for dropdown list
                        const country = Country.getAllCountries().filter(
                          (country) =>
                            country.name === options?.props?.action?.country
                        );
                        const stateList = State.getAllStates().filter(
                          (country) =>
                            country.name === options?.props?.action?.state
                        );

                        filterStates(country[0]?.isoCode);
                        filterCities(
                          stateList[0]?.isoCode,
                          stateList[0]?.countryCode
                        );

                        setFieldValue(
                          "supplier_details.supplier_name",
                          options?.props?.action?._id
                        );
                        setFieldValue(
                          "supplier_details.bill_address",
                          options?.props?.action?.bill_address
                        );
                        setFieldValue(
                          "supplier_details.city",
                          options?.props?.action?.city
                        );
                        setFieldValue(
                          "supplier_details.state",
                          options?.props?.action?.state
                        );
                        setFieldValue(
                          "supplier_details.pincode",
                          options?.props?.action?.pincode
                        );
                        setFieldValue(
                          "supplier_details.gst_no",
                          options?.props?.action?.gst_no
                        );
                        setFieldValue(
                          "supplier_details.contact_Person_name",
                          options?.props?.action?.contact_Person_name
                        );
                        setFieldValue(
                          "supplier_details.country_code",
                          options?.props?.action?.country_code
                        );
                        setFieldValue(
                          "supplier_details.contact_Person_number",
                          options?.props?.action?.contact_Person_number
                        );
                        setFieldValue(
                          "supplier_details.email_id",
                          options?.props?.action?.email_id
                        );
                        setFieldValue(
                          "supplier_details.supplier_name",
                          options?.props?.action?.supplier_name
                        );
                        setFieldValue(
                          "supplier_details.country",
                          options?.props?.action?.country
                        );
                        setFieldValue(
                          "supplier_details.pan_no",
                          options?.props?.action?.pan_no
                        );
                        setFieldValue(
                          "supplier_details.delivery_address",
                          options?.props?.action?.delivery_address
                        );
                      }}
                      defaultValue={values?._id}
                    >
                      {supplierNameList?.map((item) => {
                        return (
                          <MenuItem value={item._id} action={item}>
                            {item?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.supplier_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Invoice No.*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="invoice_no"
                      onChange={(e) => {
                        setFieldValue("invoice_no", e.target.value);
                      }}
                      value={values?.invoice_no}
                      status={true}
                    />

                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"invoice_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  {/* <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">
                      Contact Supplier Email ID
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="email_id"
                      value={values.supplier_details.email_id}
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.email_id",
                          e.target.value
                        );
                      }}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.email_id"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div> */}
                </Div>

                {/* <Div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: 3,
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Country</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.country}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue(
                          "supplier_details.country",
                          e?.target?.value
                        );
                        setFieldValue("supplier_details.state", "");
                        setFieldValue("supplier_details.city", "");
                        filterStates(key.key.replace(/[.$]/g, ""));
                        setFieldValue(
                          "supplier_details.countryCode",
                          key.key.replace(/[.$]/g, "")
                        );
                      }}
                    >
                      {countryList?.map((country) => {
                        return (
                          <MenuItem
                            value={country?.name}
                            key={country?.isoCode}
                          >
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage
                        name="supplier_details.country"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">State</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.state}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue(
                          "supplier_details.state",
                          e?.target?.value
                        );
                        setFieldValue("supplier_details.city", "");
                        filterCities(
                          key.key.replace(/[.$]/g, ""),
                          values.supplier_details.countryCode
                        );
                      }}
                    >
                      {StateList?.map((state) => {
                        return (
                          <MenuItem value={state?.name} key={state?.isoCode}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.state"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">City</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_details?.city}
                      size="small"
                      onChange={(e) => {
                        setFieldValue("supplier_details.city", e.target.value);
                      }}
                    >
                      {cityList?.map((city) => {
                        return (
                          <MenuItem key={city.name} value={city.name}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_details.city"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Pincode</Typography>

                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.pincode"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.pincode",
                          e.target.value
                        );
                      }}
                      value={values.supplier_details?.pincode}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.pincode"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">PAN No</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.pan_no"
                      value={values?.supplier_details?.pan_no}
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.pan_no",
                          e.target.value
                        );
                      }}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.pan_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">GST NO</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_details.gst_no"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_details.gst_no",
                          e.target.value
                        );
                      }}
                      value={values?.supplier_details?.gst_no}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.gst_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">
                      Contact Person Phone No*
                    </Typography>
                    <Div
                      sx={{
                        display: "flex",
                        mt: -1,
                        width: "100%",
                      }}
                    >
                      <Select
                        size="small"
                        value={values?.supplier_details?.country_code}
                        sx={{ mt: 1 }}
                        onChange={(e) =>
                          setFieldValue(
                            "supplier_details.country_code",
                            e.target.value
                          )
                        }
                      >
                        {codeList?.map((country_code) => {
                          return (
                            <MenuItem value={country_code} key={country_code}>
                              {country_code}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <TextField
                        sx={{ width: "100%", mt: 1 }}
                        size="small"
                        name="supplier_details.contact_Person_number"
                        value={values?.supplier_details?.contact_Person_number}
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.contact_Person_number",
                            e.target.value
                          );
                        }}
                        status={true}
                      />
                    </Div>
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_details.contact_Person_number"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "100%", display: "flex", columnGap: 3 }}>
                    <Div sx={{ width: "41.5%" }}>
                      <Typography variant="h5">Bill Address</Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        name="supplier_details.bill_address"
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.bill_address",
                            e.target.value
                          );
                        }}
                        value={values?.supplier_details?.bill_address}
                        status={true}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage
                          name={"supplier_details.bill_address"}
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "41%" }}>
                      <Typography variant="h5">Delivery Address</Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        name="supplier_details.delivery_address"
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_details.delivery_address",
                            e.target.value
                          );
                        }}
                        value={values?.supplier_details?.delivery_address}
                        status={true}
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage
                          name={"supplier_details.delivery_address"}
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div> */}
              </Div>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "250px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Name
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "200px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Item Type
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "200px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Log No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "200px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Bundle No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "180px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Naturals
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "180px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Dyed
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "180px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Smoked
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "180px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Add Received Pattas
                      </TableCell> */}
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "180px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Total Received Pattas
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Length
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "120px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Width
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "150px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Total Sqm
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "150px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Rate per sqm
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "150px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Grade
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "230px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Pallet No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "150px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Physical Location
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "300px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Remark
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "150px",
                          verticalAlign: "middle",
                          color: "white",
                          px: 1,
                          position: "sticky",
                          right: 0,
                          height: "58px",
                          zIndex: 1,
                          bgcolor: "#202020",
                        }}
                      >
                        Remove
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.item_details?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={itemNameList || []}
                            name="item_name"
                            value={
                              itemNameList.filter(
                                (item) => item.item_name == row?.item_name
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.item_name}
                            onChange={(e, newValue) => {
                              if (newValue == null) {
                                const updateData = [...values["item_details"]];
                                updateData[i] = initialValues.item_details[0];
                                setFieldValue("item_details", updateData);
                              } else {
                                handleOnChange(
                                  e,
                                  i,
                                  setFieldValue,
                                  values,
                                  "item_name",
                                  newValue?.item_name
                                );
                              }
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.item_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={!!errors?.item_details?.[i]?.item_name}
                                helperText={
                                  errors?.item_details?.[i]?.item_name || ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={itemCodeList || []}
                            name="item_code"
                            value={
                              itemCodeList.filter(
                                (item) => item.item_code == row?.item_code
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.item_code}
                            onChange={(e, newValue) => {
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                "item_code",
                                newValue?.item_code
                              );
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.item_code}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={!!errors?.item_details?.[i]?.item_code}
                                helperText={
                                  errors?.item_details?.[i]?.item_code || ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            size="small"
                            value={row.item_log_no}
                            error={!!errors?.item_details?.[i]?.item_log_no}
                            helperText={
                              errors?.item_details?.[i]?.item_log_no || ""
                            }
                            name="item_log_no"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            // type="number"
                            size="small"
                            value={row.item_bundle_no}
                            error={!!errors?.item_details?.[i]?.item_bundle_no}
                            helperText={
                              errors?.item_details?.[i]?.item_bundle_no || ""
                            }
                            name="item_bundle_no"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TextField
                            disabled={
                              values?.item_details?.[
                                i
                              ]?.item_code?.toLowerCase() != "natural"
                            }
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            error={
                              !!errors?.item_details?.[i]
                                ?.item_received_quantities
                            }
                            helperText={
                              errors?.item_details?.[i]
                                ?.item_received_quantities || ""
                            }
                            size="small"
                            onChange={(e) =>
                              handleOnChangeQty(
                                setFieldValue,
                                values,
                                "natural",
                                e.target.value,
                                i
                              )
                            }
                            value={row?.item_received_quantities?.natural}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TextField
                            type="number"
                            disabled={
                              values?.item_details?.[
                                i
                              ]?.item_code?.toLowerCase() != "dyed"
                            }
                            inputProps={{
                              min: 0,
                            }}
                            error={
                              !!errors?.item_details?.[i]
                                ?.item_received_quantities
                            }
                            helperText={
                              errors?.item_details?.[i]
                                ?.item_received_quantities || ""
                            }
                            size="small"
                            onChange={(e) =>
                              handleOnChangeQty(
                                setFieldValue,
                                values,
                                "dyed",
                                e.target.value,
                                i
                              )
                            }
                            value={row?.item_received_quantities?.dyed}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <TextField
                            disabled={
                              values?.item_details?.[
                                i
                              ]?.item_code?.toLowerCase() != "smoked"
                            }
                            type="number"
                            inputProps={{
                              min: 0,
                            }}
                            error={
                              !!errors?.item_details?.[i]
                                ?.item_received_quantities
                            }
                            helperText={
                              errors?.item_details?.[i]
                                ?.item_received_quantities || ""
                            }
                            size="small"
                            onChange={(e) =>
                              handleOnChangeQty(
                                setFieldValue,
                                values,
                                "smoked",
                                e.target.value,
                                i
                              )
                            }
                            value={row?.item_received_quantities?.smoked}
                          />
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <Div
                            onClick={() => {
                              setOpen(true);
                              setRowData({ data: row, index: i });
                            }}
                          >
                            <AddCircleIcon sx={{ cursor: "pointer" }} />
                          </Div>
                        </TableCell> */}
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            size="small"
                            disabled
                            value={row.item_received_pattas}
                            error={
                              !!errors?.item_details?.[i]?.item_received_pattas
                            }
                            helperText={
                              errors?.item_details?.[i]?.item_received_pattas ||
                              ""
                            }
                            name="item_received_pattas"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            type="number"
                            size="small"
                            disabled={!row?.item_received_pattas > 0}
                            error={!!errors?.item_details?.[i]?.item_length}
                            helperText={
                              errors?.item_details?.[i]?.item_length || ""
                            }
                            value={row.item_length}
                            name="item_length"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            type="number"
                            size="small"
                            disabled={!row?.item_received_pattas > 0}
                            error={!!errors?.item_details?.[i]?.item_width}
                            helperText={
                              errors?.item_details?.[i]?.item_width || ""
                            }
                            value={row.item_width}
                            name="item_width"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            type="number"
                            size="small"
                            value={row.item_received_sqm}
                            error={
                              !!errors?.item_details?.[i]?.item_received_sqm
                            }
                            helperText={
                              errors?.item_details?.[i]?.item_received_sqm || ""
                            }
                            name="item_received_sqm"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            type="number"
                            size="small"
                            value={row.item_rate_per_sqm}
                            error={
                              !!errors?.item_details?.[i]?.item_rate_per_sqm
                            }
                            helperText={
                              errors?.item_details?.[i]?.item_rate_per_sqm || ""
                            }
                            name="item_rate_per_sqm"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={gradeList || []}
                            name="item_grade"
                            value={
                              gradeList.filter(
                                (item) => item.grade_name == row?.item_grade
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.grade_name}
                            onChange={(e, newValue) => {
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                "item_grade",
                                newValue?.grade_name
                              );
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.grade_name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={!!errors?.item_details?.[i]?.item_grade}
                                helperText={
                                  errors?.item_details?.[i]?.item_grade || ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            id="tags-standard"
                            options={palleteList || []}
                            name="item_pallete_no"
                            value={
                              palleteList.filter(
                                (item) =>
                                  item.pallete_no == row?.item_pallete_no
                              )?.[0] || null
                            }
                            getOptionLabel={(option) => option?.pallete_no}
                            onChange={(e, newValue) => {
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                "item_pallete_no",
                                newValue?.pallete_no
                              );
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option?.pallete_no}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                error={
                                  !!errors?.item_details?.[i]?.item_pallete_no
                                }
                                helperText={
                                  errors?.item_details?.[i]?.item_pallete_no ||
                                  ""
                                }
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                }}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            multiline
                            size="small"
                            value={row?.item_physical_location}
                            error={
                              !!errors?.item_details?.[i]
                                ?.item_physical_location
                            }
                            sx={{ width: "100%" }}
                            helperText={
                              errors?.item_details?.[i]
                                ?.item_physical_location || ""
                            }
                            name="item_physical_location"
                            disabled
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          <TextField
                            multiline
                            size="small"
                            value={row.item_remark}
                            error={!!errors?.item_details?.[i]?.item_remark}
                            sx={{ width: "100%" }}
                            helperText={
                              errors?.item_details?.[i]?.item_remark || ""
                            }
                            name="item_remark"
                            onChange={(e) =>
                              handleOnChange(
                                e,
                                i,
                                setFieldValue,
                                values,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100%",
                            verticalAlign: "middle",
                            color: "white",
                            textAlign: "left",
                            px: 1,
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                            bgcolor: "white",
                          }}
                        >
                          <LoadingButton
                            loading={loader.removeProduct}
                            variant="outlined"
                            color="error"
                            disabled={values.item_details.length == 1}
                            onClick={() => removeRow(values, setFieldValue, i)}
                            sx={{
                              mb: errors?.item_details?.[i] ? 3 : 0,
                            }}
                          >
                            Remove
                          </LoadingButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <LoadingButton
                  loading={loader.addProduct}
                  variant="outlined"
                  sx={{ marginX: 2, marginY: 1, width: "150px" }}
                  onClick={() => {
                    setLoader({ ...loader, addProduct: true });
                    setTimeout(() => {
                      const updateData = [...values["item_details"]];
                      updateData.push(initialValues.item_details[0]);
                      setFieldValue("item_details", updateData);

                      setLoader({ ...loader, addProduct: false });
                    }, 300);
                  }}
                >
                  Add Product
                </LoadingButton>
              </TableContainer>
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/inventory/rawmaterial");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Add Stock
                </LoadingButton>
              </Div>
              {/* <BundleModal
                setFieldValue={setFieldValue}
                open={open}
                setOpen={setOpen}
                rowData={rowData}
                errors={errors}
                values={values}
              /> */}
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
