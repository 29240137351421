
//Created Group
export const ALL_CREATED_GROUPING_REQUEST = "ALL_CREATED_GROUPING_REQUEST";
export const ALL_CREATED_GROUPING_SUCCESS = "ALL_CREATED_GROUPING_SUCCESS";
export const ALL_CREATED_GROUPING_FAIL = "ALL_CREATED_GROUPING_FAIL";


//Group History
export const ALL_GROUPING_HISTORY_REQUEST = "ALL_GROUPING_HISTORY_REQUEST";
export const ALL_GROUPING_HISTORY_SUCCESS = "ALL_GROUPING_HISTORY_SUCCESS";
export const ALL_GROUPING_HISTORY_FAIL = "ALL_GROUPING_HISTORY_FAIL";

//Group History Normal
export const ALL_GROUPING_HISTORY_NORMAL_REQUEST = "ALL_GROUPING_HISTORY_NORMAL_REQUEST";
export const ALL_GROUPING_HISTORY_NORMAL_SUCCESS = "ALL_GROUPING_HISTORY_NORMAL_SUCCESS";
export const ALL_GROUPING_HISTORY_NORMAL_FAIL = "ALL_GROUPING_HISTORY_NORMAL_FAIL";

