import AllApis from "app/Apis";
import {
  ALL_READY_FOR_DISPATCH_FAIL,
  ALL_READY_FOR_DISPATCH_REQUEST,
  ALL_READY_FOR_DISPATCH_SUCCESS,
  GROUP_DISPATCH_FAIL,
  GROUP_DISPATCH_REQUEST,
  GROUP_DISPATCH_SUCCESS,
  RAW_DISPATCH_FAIL,
  RAW_DISPATCH_REQUEST,
  RAW_DISPATCH_SUCCESS,
} from "app/utils/constants/dispatchConstant";
import { Axios } from "index";

//readyForDispatch
export const getAllReadyForDispatch =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "available",
        },
        searchFields: {
          string: [],
          numbers: [
            "group_no",
            "qc_length",
            "qc_width",
            "qc_no_of_pcs_available",
            "qc_sqm",
          ],
          arrayField: [
            "cutting_details.cutting_id.item_details.item_data.item_name",
            "cutting_details.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_READY_FOR_DISPATCH_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dispatch.readyForDispatchInventory}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_READY_FOR_DISPATCH_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_READY_FOR_DISPATCH_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// raw dipstached orders
export const getRawDispatchedList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["invoice_no"],
          numbers: ["order_details.order_no"],
          arrayField: [
            "raw_dispatch_details.dispatch.raw_details.item_name",
            "raw_dispatch_details.dispatch.raw_details.item_code",
            "order_details.order_no",
            "order_details.customer_name",
            "order_details.place",
            "order_details.order_mode",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: RAW_DISPATCH_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dispatch.rawDispatched}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: RAW_DISPATCH_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: RAW_DISPATCH_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
// group dipstached orders
export const getGroupDispatchedList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["invoice_no"],
          numbers: ["order_details.order_no"],
          arrayField: [
            "order_details.group_order_details.order_item_name",
            "order_details.group_order_details.order_item_code",
            "order_details.order_no",
            "order_details.customer_name",
            "order_details.place",
            "order_details.order_mode",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: GROUP_DISPATCH_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dispatch.groupDispatched}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: GROUP_DISPATCH_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: GROUP_DISPATCH_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
