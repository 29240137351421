/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllGroupHistory } from "app/redux/actions/groupingAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CuttingBundleModal } from "../Modal/cuttingBundleQty";

export default function GroupHistoryList({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupsHistory, error, TotalPage, loading } = useSelector(
    (state) => state.groupingReducer
  );
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllGroupHistory(searchTerm, sort, sortBy, newPage, filter));
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  // px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "cutting_item_details.item_name"}
                  direction={sort}
                  onClick={() => handleSort("cutting_item_details.item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "cutting_item_details.item_code"}
                  direction={sort}
                  onClick={() => handleSort("cutting_item_details.item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_length"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Length
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_width"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Width
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_pcs"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_pcs")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No. Of Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_no_of_pattas_available"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_id.group_no_of_pattas_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  No of Pattas
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_sqm_available"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_sqm_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Sqm
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.total_item_sqm_available"}
                  direction={sort}
                  onClick={() =>
                    handleSort("group_id.total_item_sqm_available")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Total Item Sqm Avl
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "70px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_grade"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "105px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.orientation"}
                  direction={sort}
                  onClick={() => handleSort("group_id.orientation")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Orientation
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.book_type"}
                  direction={sort}
                  onClick={() => handleSort("group_id.book_type")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Formation Type
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group Pallet No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_id.group_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("group_id.group_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Date of Grouping
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupsHistory?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left"}}>
                  {row?.group_id?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.cutting_item_details?.[0]?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.cutting_item_details?.[0]?.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_pcs}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_no_of_pattas_available}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_sqm_available}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.total_item_sqm_available}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_grade}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.orientation}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.book_type}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.group_id?.group_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.group_id?.group_physical_location}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.group_id?.date_of_grouping)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.cutting_item_details);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CuttingBundleModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
