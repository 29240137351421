import Div from "@jumbo/shared/Div/Div";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import AllApis from "app/Apis";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import ImageUpload from "app/components/UploadImages";
import { Axios } from "index";
import { getCurrentDate } from "app/components/Function/getCurrentDate";

export default function CreateSmoking() {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [selectedBundles, setSelectedBundles] = useState(state);
  const createGroup = {
    item_details: [],
    smoke_images: [],
    in_time: "",
    out_time: "",
    process_time: 0,
    liters_of_ammonia_used: "",
    date_of_smoking: getCurrentDate(),
    individual_smoked_remarks: "",
  };

  const calculateTimeSpent = (name, value, data, setFieldValue) => {
    const arrivalTime = name == "in_time" ? value?.$d : data?.in_time;
    const departureTime = name == "out_time" ? value?.$d : data?.out_time;
    if (arrivalTime && departureTime) {
      const timeDifference = Math.abs(
        departureTime.getTime() - arrivalTime.getTime()
      );
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      setFieldValue("process_time", `${hours}.${minutes}`);
    }
    setFieldValue([name], value?.$d);
  };

  const validationSchema = yup.object().shape({
    // smoke_images: yup.array().of(yup.string()).required("Upload Images"),
    in_time: yup.date().required("In Time is required"),
    out_time: yup
      .date()
      .required("Out Time is required")
      .min(
        yup.ref("in_time"),
        ({ min }) => `Out Time must be greater than In Time`,
        { excludeEmptyString: true, exclusive: true }
      )
      .test("not-same", "In and Out Time cannot be the same", function (value) {
        const inTime = this.resolve(yup.ref("in_time"));
        return !inTime || !value || value.getTime() !== inTime.getTime();
      }),
    process_time: yup.string().required("Process Time is required"),
    liters_of_ammonia_used: yup
      .number()
      .typeError("Litres Of Ammonia must be a Number")
      .required("Litres of Ammonia Used is required"),
    date_of_smoking: yup.date().required("Date of smoking is required"),
  });
  const handleSubmit = async (values) => {
    const value = {
      ...values,
      item_details: selectedBundles.map((ele) => ele.item_id._id),
    };
    const form = new FormData();

    // Iterate over the entries of the object and append each key-value pair to the FormData object
    Object.entries(value).forEach((entry) => {
      if (entry[0] == "smoke_images") {
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] == "item_details") {
        form.append(entry[0], JSON.stringify(entry[1]));
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.smoking.createSmokingIndividual,
        form,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Individual Smoking Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/factory/smoking");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const removeRow = (index, setFieldValue, values) => {
    const updateData = [...selectedBundles];
    updateData.splice(index, 1);
    setSelectedBundles(updateData);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Individual Smoking</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={createGroup}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Log No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "108px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Bundle No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Length
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "70px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Width
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "105px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Avl Pattas
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "130px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Avl Total Sqm
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "75px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Grade
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pallet No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "155px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Physical Location
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "85px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Details
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "95px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Supplier
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                            position: "sticky",
                            right: 0,
                            height: "58px",
                            zIndex: 1,
                            bgcolor: "#202020",
                          }}
                        >
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedBundles?.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_code}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_log_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_bundle_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_length}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_width}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_available_pattas}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_available_sqm}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_grade}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_pallete_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.item_physical_location}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 2 }}>
                            <Div
                              onClick={() => {
                                setOpen(true);
                                setRowData(row?.item_id);
                              }}
                            >
                              <InfoIcon sx={{ cursor: "pointer" }} />
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_id?.supplier_details?.supplier_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            <RemoveCircleOutlineIcon
                              sx={{ color: "red", ml: 2, cursor: "pointer" }}
                              onClick={() =>
                                selectedBundles.length > 1 &&
                                removeRow(i, setFieldValue, values)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%", display: "flex", columnGap: 5 }}>
                <Div
                  sx={{
                    width: "50%",
                  }}
                >
                  <Div
                    sx={{
                      width: "100%",
                      display: "flex",
                      columnGap: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <Div sx={{ width: "32%" }}>
                      <Typography variant="h5">Date of Smoking*</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input": {
                              padding: 1,
                            },
                          }}
                          format="DD-MM-YYYY"
                          maxDate={dayjs()}
                          defaultValue={
                            values?.date_of_smoking !== ""
                              ? dayjs(values?.date_of_smoking)
                              : null
                          }
                          onChange={(newValue) => {
                            setFieldValue(
                              "date_of_smoking",
                              newValue
                                .startOf("day")
                                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                            );
                          }}
                        />
                        <Div sx={{ height: "30px", mt: 0.5 }}>
                          <ErrorMessage
                            name="date_of_smoking"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Div>
                      </LocalizationProvider>
                    </Div>
                    <Div sx={{ width: "32%" }}>
                      <Typography variant="h5">
                        Litres Of Ammonia Used*
                      </Typography>
                      <TextField
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        size="small"
                        sx={{ width: "100%" }}
                        value={values?.liters_of_ammonia_used}
                        onChange={(e) =>
                          setFieldValue(
                            "liters_of_ammonia_used",
                            e.target.value
                          )
                        }
                      />
                      <Div sx={{ height: "30px", mt: 0.5 }}>
                        <ErrorMessage
                          name="liters_of_ammonia_used"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ display: "flex", columnGap: 3, width: "100%" }}>
                      <Div sx={{ width: "32%" }}>
                        <Typography variant="h5">In Time*</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            format="DD-MM-YYYY   hh:mm a"
                            name="in_time"
                            value={
                              values?.in_time ? dayjs(values?.in_time) : null
                            }
                            sx={{
                              width: "100%",
                              "& .MuiInputBase-input": {
                                padding: 1,
                              },
                            }}
                            label={values?.in_time ? "In Time" : null}
                            onChange={(newValue) => {
                              calculateTimeSpent(
                                "in_time",
                                newValue,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <Div sx={{ height: "30px", mt: 0.5 }}>
                          <ErrorMessage
                            name="in_time"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Div>
                      </Div>
                      <Div sx={{ width: "32%" }}>
                        <Typography variant="h5">Out Time*</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            format="DD-MM-YYYY   hh:mm a"
                            name="out_time"
                            sx={{
                              width: "100%",
                              "& .MuiInputBase-input": {
                                padding: 1,
                              },
                            }}
                            value={
                              values?.out_time ? dayjs(values?.out_time) : null
                            }
                            // referenceDate={dayjs('2024-04-17T15:30')}
                            onChange={(newValue) => {
                              calculateTimeSpent(
                                "out_time",
                                newValue,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                        </LocalizationProvider>
                        <Div sx={{ height: "30px", mt: 0.5 }}>
                          <ErrorMessage
                            name="out_time"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Div>
                      </Div>
                      <Div>
                        <Typography variant="h5">
                          Process Time in Hrs
                        </Typography>
                        <TextField
                          name="process_time"
                          sx={{ ...disabledStyling, width: "100%" }}
                          disabled
                          value={values?.process_time}
                          size="small"
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "67%" }}>
                      <Typography variant="h5">Remark</Typography>
                      <TextField
                        multiline
                        size="small"
                        value={values?.individual_smoked_remarks}
                        sx={{ width: "100%" }}
                        name="individual_smoked_remarks"
                        onChange={(e) =>
                          setFieldValue(
                            "individual_smoked_remarks",
                            e.target.value
                          )
                        }
                      />
                    </Div>
                  </Div>
                </Div>
                <Div sx={{ width: "50%" }}>
                  <Div sx={{ width: "100%" }}>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"smoke_images"}
                    />
                    <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                      <ErrorMessage
                        name="smoke_images"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>

              {open && (
                <RawInventoryModal
                  open={open}
                  setOpen={setOpen}
                  rowData={rowData}
                />
              )}
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/factory/smoking");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Smoking
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
