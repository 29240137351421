import Page from "@jumbo/shared/Page";
import AddOtherGoods from "app/pages/Inventory/other goods/AddOtherGoods";
import orderMiddleware from "./middleware/auth/orderValidRoute";
import ListOrder from "app/pages/VeneerOrder/Orders/List";
import CreateGroupOrder from "app/pages/VeneerOrder/Orders/Create/createGroup";
import CreateRawOrder from "app/pages/VeneerOrder/Orders/Create/createIndividual";
import EditRawOrder from "app/pages/VeneerOrder/Orders/Edit/editIndividual";
import EditGroupOrder from "app/pages/VeneerOrder/Orders/Edit/editGroup";

export const orderRoutes = [
  {
    middleware: [
      {
        element: orderMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/orders",
        element: <Page component={ListOrder} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/orders/create-group-order",
        element: (
          <Page component={CreateGroupOrder} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/orders/create-raw-order",
        element: (
          <Page component={CreateRawOrder} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard/orders/edit-raw-order",
        element: <Page component={EditRawOrder} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/orders/edit-group-order",
        element: (
          <Page component={EditGroupOrder} layout={"vertical-default"} />
        ),
      },
      // {
      //   path: "/dashboard/orders/edit",
      //   element: <Page component={AddOtherGoods} layout={"vertical-default"} />,
      // },
    ],
  },
];
