import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const ReadySheetDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Ready Sheet Form Details:-
        </Typography>
        <Div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Group No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Length
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Width
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Pcs
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Sqm
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Pallet No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Ready Sheet Physical Location
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.ready_sheet_form_history_details?.group_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_length
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_width
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_approved_pcs
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_sqm
                    }
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_pallete_no
                    }
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {
                      rowData?.ready_sheet_form_history_details
                        ?.ready_sheet_form_physical_location
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
