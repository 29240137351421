/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import { getAllRawMaterialHistory } from "app/redux/actions/inventoryAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function RawMaterialHistoryTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const { history, error, TotalPage,loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getAllRawMaterialHistory(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  return (
    <>
      {loading && <FullScreenLoader />}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  // px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_code"}
                  direction={sort}
                  onClick={() => handleSort("item_code")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_log_no"}
                  direction={sort}
                  onClick={() => handleSort("item_log_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_bundle_no"}
                  direction={sort}
                  onClick={() => handleSort("item_bundle_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Bundle No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_length"}
                  direction={sort}
                  onClick={() => handleSort("item_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Length
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "70px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_width"}
                  direction={sort}
                  onClick={() => handleSort("item_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Width
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "105px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_available_pattas"}
                  direction={sort}
                  onClick={() => handleSort("item_available_pattas")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Pattas
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_available_sqm"}
                  direction={sort}
                  onClick={() => handleSort("item_available_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Avl Total Sqm
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "75px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_grade"}
                  direction={sort}
                  onClick={() => handleSort("item_grade")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Grade
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("item_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_physical_location"}
                  direction={sort}
                  onClick={() => handleSort("item_physical_location")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "85px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "155px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() => handleSort("supplier_details.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Supplier
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Inward Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left"}}>
                  {row.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_code}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_log_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_bundle_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_available_pattas}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_available_sqm}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_grade}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.item_pallete_no}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row.item_physical_location}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.supplier_details.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row.date_of_inward)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <RawInventoryModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
              tab={"History"}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
