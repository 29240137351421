import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDropzone } from "react-dropzone";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  UpdateImages,
  listImageBasedOnGroupNo,
} from "app/services/apis/ListApi/listImageBasedOnGroupNo";
import axios from "axios";

const ViewImageForGroup = ({ openView, setOpenView, data }) => {
  const [images, setImage] = useState([]);

  const getImageDetails = async () => {
    const res = await listImageBasedOnGroupNo(data.group_id.group_no);
    setImage(res);
  };
  useEffect(() => {
    getImageDetails();
  }, [data]);

  //   const { id } = useParams();
  //   const [oldImage, setOldImageImages] = useState(data);
  const [newPhotos, setNewPhotos] = useState([]);
  const [deleteImage, setDeleteImages] = useState([]);

  const handleRemovePhoto = (fileToRemove) => {
    const updatedData = images
      ? {
          ...images,
          smoke_images: Array.isArray(images.smoke_images)
            ? images.smoke_images.filter((file) => file !== fileToRemove)
            : [],
        }
      : null;
    setDeleteImages((prevDeletedImages) => [
      ...prevDeletedImages,
      fileToRemove,
    ]);
    setImage(updatedData);
  };

  const handleRemoveNewPhoto = (fileToRemove) => {
    const updatedData = newPhotos.filter((file) => file !== fileToRemove);
    setNewPhotos(updatedData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setNewPhotos((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(
    () => () => {
      newPhotos.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [newPhotos]
  );

  const handleUploadImage = async (id) => {
    const formData = new FormData();
    newPhotos.forEach((file) => {
      formData.append(`smoke_images`, file);
    });
    formData.append(`deleteImages`, JSON.stringify([...deleteImage]));
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/image/update-image-group-smoke?id=${
          images._id
        }&group_no=${
          images.group_no
        }&folder_name=${"smoke_images"}&imageType=${"smoke_images"}`,
        formData,
        config
      );
      if (response.data.status == true) {
        Swal.fire({
          customClass: {
            container: "popupImportant",
          },
          position: "center",
          icon: "success",
          text: "Image Updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        customClass: {
          container: "popupImportant",
        },
        position: "center",
        icon: "error",
        text: "Image Not Updated",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(error);
    }
  };

  return (
    <Dialog
      open={openView}
      onClose={() => setOpenView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <DialogContent
        headerSx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
        sx={{ mb: 3.75 }}
      >
        <ImageListItem>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ marginTop: "10px", width: "118px" }}
          >
            <input {...getInputProps()} />
            <Button size="small" variant="contained">
              Add Smoke Images
            </Button>
          </div>
        </ImageListItem>
        <DialogTitle
          style={{
            backgroundColor: "#7352C7",
            color: "white",
            marginTop: "10px",
          }}
          id="alert-dialog-title"
        >
          Smoke Images
        </DialogTitle>
        <ImageList
          sx={{ width: "100%", maxHeight: 500 }}
          cols={4}
          rowHeight={500}
        >
          {images?.smoke_images?.length > 0 || newPhotos?.length > 0 ? (
            images?.smoke_images?.map((file, index) => (
              <ImageListItem key={index}>
                <HighlightOffIcon
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    cursor: "pointer",
                    color: "red",
                  }}
                  onClick={() => handleRemovePhoto(file)}
                />
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_PATH}/images/smoke/${file}`}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </ImageListItem>
            ))
          ) : (
            <ImageListItem style={{ height: "200px" }}>
              <img
                src="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg"
                alt="No Image Found"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </ImageListItem>
          )}

          {newPhotos?.map((file) => (
            <ImageListItem key={file.name}>
              <HighlightOffIcon
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  cursor: "pointer",
                  color: "red",
                }}
                onClick={() => handleRemoveNewPhoto(file)}
              />
              <img
                src={file.preview}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <DialogTitle
          style={{ backgroundColor: "#7352C7", color: "white" }}
          id="alert-dialog-title"
        >
          Dying Images
        </DialogTitle>
        <ImageList
          sx={{ width: "100%", maxHeight: 500 }}
          cols={4}
          rowHeight={500}
        >
          {images && images?.dying_images?.length > 0 ? (
            images?.dying_images?.map((file, index) => (
              <ImageListItem key={index}>
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_PATH}/images/dying/${file}`}
                  loading="lazy"
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </ImageListItem>
            ))
          ) : (
            <ImageListItem style={{ height: "200px" }}>
              <img
                src="https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg" // Replace with your placeholder image path
                alt="No Image Found"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </ImageListItem>
          )}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          type="submit"
          variant="contained"
          onClick={() => handleUploadImage(newPhotos)}
        >
          update images
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setOpenView(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewImageForGroup;
