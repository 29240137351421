import {
  ALL_CUTTING_DONE_FAIL,
  ALL_CUTTING_DONE_REQUEST,
  ALL_CUTTING_DONE_SUCCESS,
  ALL_DYED_GROUPS_FAIL,
  ALL_DYED_GROUPS_REQUEST,
  ALL_DYED_GROUPS_SUCCESS,
  ALL_DYED_INDIVIDUALS_FAIL,
  ALL_DYED_INDIVIDUALS_REQUEST,
  ALL_DYED_INDIVIDUALS_SUCCESS,
  ALL_FINISHING_DONE_FAIL,
  ALL_FINISHING_DONE_REQUEST,
  ALL_FINISHING_DONE_SUCCESS,
  ALL_ISSUED_FOR_DYING_GROUPS_FAIL,
  ALL_ISSUED_FOR_DYING_GROUPS_REQUEST,
  ALL_ISSUED_FOR_DYING_GROUPS_SUCCESS,
  ALL_ISSUED_FOR_SMOKING_GROUPS_FAIL,
  ALL_ISSUED_FOR_SMOKING_GROUPS_REQUEST,
  ALL_ISSUED_FOR_SMOKING_GROUPS_SUCCESS,
  ALL_ISSUE_FOR_CUTTING_FAIL,
  ALL_ISSUE_FOR_CUTTING_REQUEST,
  ALL_ISSUE_FOR_CUTTING_SUCCESS,
  ALL_ISSUE_FOR_FINISHING_FAIL,
  ALL_ISSUE_FOR_FINISHING_REQUEST,
  ALL_ISSUE_FOR_FINISHING_SUCCESS,
  ALL_ISSUE_FOR_PRESSING_FAIL,
  ALL_ISSUE_FOR_PRESSING_REQUEST,
  ALL_ISSUE_FOR_PRESSING_SUCCESS,
  ALL_ISSUE_FOR_TAPPING_FAIL,
  ALL_ISSUE_FOR_TAPPING_REQUEST,
  ALL_ISSUE_FOR_TAPPING_SUCCESS,
  ALL_PRESSING_DONE_FAIL,
  ALL_PRESSING_DONE_REQUEST,
  ALL_PRESSING_DONE_SUCCESS,
  ALL_SMOKED_GROUPS_FAIL,
  ALL_SMOKED_GROUPS_REQUEST,
  ALL_SMOKED_GROUPS_SUCCESS,
  ALL_SMOKED_INDIVIDUALS_FAIL,
  ALL_SMOKED_INDIVIDUALS_REQUEST,
  ALL_SMOKED_INDIVIDUALS_SUCCESS,
  ALL_TAPPING_DONE_FAIL,
  ALL_TAPPING_DONE_REQUEST,
  ALL_TAPPING_DONE_SUCCESS,
} from "app/utils/constants/factoryConstants";

const INIT_STATE = {
  loading: false,
  error: null,
  issueForCutting: [],
  cuttingDone: [],
  issuedForSmokingGroups: [],
  issuedForDyingGroups: [],
  smokedIndividuals: [],
  dyedIndividuals: [],
  smokedGroups: [],
  dyedGroups: [],
  pressingDone: [],
  issuedForPressing: [],
  finishingDone: [],
  issuedForFinishing: [],
  TotalPage: null,
};

export const factoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //Tapping Done
    case ALL_TAPPING_DONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_TAPPING_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        tappingDone: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_TAPPING_DONE_FAIL:
      return {
        loading: false,
        tappingDone: [],
        error: action.payload,
      };

    //Issue For Tapping List
    case ALL_ISSUE_FOR_TAPPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUE_FOR_TAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        issueForTapping: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUE_FOR_TAPPING_FAIL:
      return {
        loading: false,
        issueForTapping: [],
        error: action.payload,
      };

    //Issue For Cutting List
    case ALL_ISSUE_FOR_CUTTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUE_FOR_CUTTING_SUCCESS:
      return {
        ...state,
        loading: false,
        issueForCutting: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUE_FOR_CUTTING_FAIL:
      return {
        loading: false,
        issueForCutting: [],
        error: action.payload,
      };

    //cutting Done
    case ALL_CUTTING_DONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_CUTTING_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        cuttingDone: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_CUTTING_DONE_FAIL:
      return {
        loading: false,
        cuttingDone: [],
        error: action.payload,
      };

    //issued For Smoking Group List
    case ALL_ISSUED_FOR_SMOKING_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUED_FOR_SMOKING_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        issuedForSmokingGroups: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUED_FOR_SMOKING_GROUPS_FAIL:
      return {
        loading: false,
        issuedForSmokingGroups: [],
        error: action.payload,
      };

    //smoked Individuals
    case ALL_SMOKED_INDIVIDUALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_SMOKED_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        loading: false,
        smokedIndividuals: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_SMOKED_INDIVIDUALS_FAIL:
      return {
        loading: false,
        smokedIndividuals: [],
        error: action.payload,
      };

    //smoked Grouped
    case ALL_SMOKED_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_SMOKED_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        smokedGroups: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_SMOKED_GROUPS_FAIL:
      return {
        loading: false,
        smokedGroups: [],
        error: action.payload,
      };

    //issued For Dying Group List
    case ALL_ISSUED_FOR_DYING_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUED_FOR_DYING_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        issuedForDyingGroups: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUED_FOR_DYING_GROUPS_FAIL:
      return {
        loading: false,
        issuedForDyingGroups: [],
        error: action.payload,
      };

    //dyed Individuals
    case ALL_DYED_INDIVIDUALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_DYED_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        loading: false,
        dyedIndividuals: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DYED_INDIVIDUALS_FAIL:
      return {
        loading: false,
        dyedIndividuals: [],
        error: action.payload,
      };

    //dyed Grouped
    case ALL_DYED_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_DYED_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        dyedGroups: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_DYED_GROUPS_FAIL:
      return {
        loading: false,
        dyedGroups: [],
        error: action.payload,
      };

    //Pressing Done
    case ALL_PRESSING_DONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PRESSING_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        pressingDone: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PRESSING_DONE_FAIL:
      return {
        loading: false,
        pressingDone: [],
        error: action.payload,
      };

    //Issue For Pressing List
    case ALL_ISSUE_FOR_PRESSING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUE_FOR_PRESSING_SUCCESS:
      return {
        ...state,
        loading: false,
        issueForPressing: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUE_FOR_PRESSING_FAIL:
      return {
        loading: false,
        issueForPressing: [],
        error: action.payload,
      };

    //Finishing Done
    case ALL_FINISHING_DONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_FINISHING_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        finishingDone: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_FINISHING_DONE_FAIL:
      return {
        loading: false,
        finishingDone: [],
        error: action.payload,
      };

    //Issue For Finishing List
    case ALL_ISSUE_FOR_FINISHING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ISSUE_FOR_FINISHING_SUCCESS:
      return {
        ...state,
        loading: false,
        issueForFinishing: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ISSUE_FOR_FINISHING_FAIL:
      return {
        loading: false,
        issueForFinishing: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
