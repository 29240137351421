//Raw Material
export const ALL_INVENTORY_RAW_MATERIAL_REQUEST =
  "ALL_INVENTORY_RAW_MATERIAL_REQUEST";
export const ALL_INVENTORY_RAW_MATERIAL_SUCCESS =
  "ALL_INVENTORY_RAW_MATERIAL_SUCCESS";
export const ALL_INVENTORY_RAW_MATERIAL_FAIL =
  "ALL_INVENTORY_RAW_MATERIAL_FAIL";

//Raw Material History
export const ALL_RAW_MATERIAL_HISTORY_REQUEST =
  "ALL_RAW_MATERIAL_HISTORY_REQUEST";
export const ALL_RAW_MATERIAL_HISTORY_SUCCESS =
  "ALL_RAW_MATERIAL_HISTORY_SUCCESS";
export const ALL_RAW_MATERIAL_HISTORY_FAIL = "ALL_RAW_MATERIAL_HISTORY_FAIL";

//Other Goods
export const ALL_OTHER_GOODS_REQUEST = "ALL_OTHER_GOODS_REQUEST";
export const ALL_OTHER_GOODS_SUCCESS = "ALL_OTHER_GOODS_SUCCESS";
export const ALL_OTHER_GOODS_FAIL = "ALL_OTHER_GOODS_FAIL";

//Other Goods Consumed
export const ALL_OTHER_GOODS_CONSUMED_REQUEST =
  "ALL_OTHER_GOODS_CONSUMED_REQUEST";
export const ALL_OTHER_GOODS_CONSUMED_SUCCESS =
  "ALL_OTHER_GOODS_CONSUMED_SUCCESS";
export const ALL_OTHER_GOODS_CONSUMED_FAIL = "ALL_OTHER_GOODS_CONSUMED_FAIL";

//Issued Grouping
export const ALL_ISSUED_GROUPING_REQUEST = "ALL_ISSUED_GROUPING_REQUEST";
export const ALL_ISSUED_GROUPING_SUCCESS = "ALL_ISSUED_GROUPING_SUCCESS";
export const ALL_ISSUED_GROUPING_FAIL = "ALL_ISSUED_GROUPING_FAIL";

//Issued Smoking
export const ALL_ISSUED_SMOKING_REQUEST = "ALL_ISSUED_SMOKING_REQUEST";
export const ALL_ISSUED_SMOKING_SUCCESS = "ALL_ISSUED_SMOKING_SUCCESS";
export const ALL_ISSUED_SMOKING_FAIL = "ALL_ISSUED_SMOKING_FAIL";

//Issued Dying
export const ALL_ISSUED_DYING_REQUEST = "ALL_ISSUED_DYING_REQUEST";
export const ALL_ISSUED_DYING_SUCCESS = "ALL_ISSUED_DYING_SUCCESS";
export const ALL_ISSUED_DYING_FAIL = "ALL_ISSUED_DYING_FAIL";

//Ready Sheet Form List
export const ALL_READY_SHEET_FORM_REQUEST = "ALL_READY_SHEET_FORM_REQUEST";
export const ALL_READY_SHEET_FORM_SUCCESS = "ALL_READY_SHEET_FORM_SUCCESS";
export const ALL_READY_SHEET_FORM_FAIL = "ALL_READY_SHEET_FORM_FAIL";

//Ready Sheet Form History
export const ALL_READY_SHEET_FORM_HISTORY_REQUEST =
  "ALL_READY_SHEET_FORM_HISTORY_REQUEST";
export const ALL_READY_SHEET_FORM_HISTORY_SUCCESS =
  "ALL_READY_SHEET_FORM_HISTORY_SUCCESS";
export const ALL_READY_SHEET_FORM_HISTORY_FAIL =
  "ALL_READY_SHEET_FORM_HISTORY_FAIL";
