import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, TextField, Typography } from "@mui/material";
import AllApis from "app/Apis";

import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import { getAllReadySheetFormList } from "app/redux/actions/inventoryAction";

export const ReadySheetFormApprovalModal = ({ open, setOpen, rowData }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
  };
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259",
    },
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setData(rowData);
  }, [open]);

  const createGroup = {
    ready_sheet_form_id: data._id,
    no_of_pcs_to_approve: "",
    ready_sheet_form_no_of_pcs_available:
      data.ready_sheet_form_no_of_pcs_available,
  };
  const handleOnChange = (e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue("no_of_pcs_to_approve", value);
  };

  const validationSchema = yup.object().shape({
    no_of_pcs_to_approve: yup
      .number()
      .typeError("no_of_pcs_to approve must be a number")
      .required("no of pcs to approve is required"),
  });
  const handleSubmit = async (values) => {
    setOpen(false);
    const value = {
      ready_sheet_form_id: data._id,
      no_of_pcs_to_approve: values.no_of_pcs_to_approve,
    };

    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve Ready Sheet Form",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await Axios.post(
              AllApis.ready_sheet_form.approved,
              value,
              config
            );
            if (response) {
              dispatch(
                getAllReadySheetFormList("", "desc", "updated_at", 1, {})
              );
              Swal.fire({
                title: "Approved successfully",
                icon: "success",
                timer: 1000,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            Swal.fire({
              title: error.message,
              icon: "error",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div sx={{ display: "flex", minWidth: 600 }}></Div>
      <DialogContent>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="h4" fontWeight={600}>
            Add Approved Quantity
          </Typography>
          <Formik
            validateOnChange={true}
            initialValues={createGroup}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors }) => (
              <Form noValidate autoComplete="off">
                <Div sx={{ mt: 5, width: "100%" }}>
                  <Div
                    sx={{
                      display: "flex",
                      width: "100%",
                      columnGap: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <Div sx={{ width: "45%" }}>
                      <Typography variant="h5">Available Quantity</Typography>
                      <TextField
                        disabled
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={values?.ready_sheet_form_no_of_pcs_available}
                        name="ready_sheet_form_no_of_pcs_available"
                        onChange={(e) => ""}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="ready_sheet_form_no_of_pcs_available"
                          component="Div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "45%" }}>
                      <Typography variant="h5">
                        No of Pieces To Approve*
                      </Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        type="number"
                        inputProps={{
                          min: 0,
                          max: data?.ready_sheet_form_no_of_pcs_available,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (
                            isNaN(inputValue) ||
                            inputValue < 0 ||
                            inputValue >
                              data?.ready_sheet_form_no_of_pcs_available
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleOnChange(e, setFieldValue)}
                        value={data?.no_of_pcs_to_approve}
                        name="no_of_pcs_to_approve"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="no_of_pcs_to_approve"
                          component="Div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 5,
                  }}
                >
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    Approve
                  </LoadingButton>
                </Div>
              </Form>
            )}
          </Formik>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
