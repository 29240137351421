import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllOtherGoodsConsumed } from "app/redux/actions/inventoryAction";
import { useDispatch, useSelector } from "react-redux";

export default function OtherGoodsTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const { otherGoodsConsumed, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getAllOtherGoodsConsumed(searchTerm, sort, sortBy, newPage, filter)
    );
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  // px: 1,
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={() => handleSort("item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "received_quantity"}
                  direction={sort}
                  onClick={() => handleSort("received_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Received Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "available_quantity"}
                  direction={sort}
                  onClick={() => handleSort("available_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Available Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "consumption_quantity"}
                  direction={sort}
                  onClick={() => handleSort("consumption_quantity")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Consumed Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "processes"}
                  direction={sort}
                  onClick={() => handleSort("processes")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Processes
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "units"}
                  direction={sort}
                  onClick={() => handleSort("units")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Units
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "rate"}
                  direction={sort}
                  onClick={() => handleSort("rate")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "supplier_details.supplier_name"}
                  direction={sort}
                  onClick={() => handleSort("supplier_details.supplier_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Remark
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Date of Consumption
              </TableCell>
              <TableCell
                sx={{
                  px: 1,
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {otherGoodsConsumed?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left"}}>
                  {row?.item_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.received_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.available_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.consumption_quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.processes}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.units}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.rate}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row.supplier_details.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.other_goods_consumed_remarks
                    ? row?.other_goods_consumed_remarks
                    : "-"}
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    textAlign: "left",
                    minWidth: "100px",
                  }}
                >
                  {formatDate(row?.date_of_consumption)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
