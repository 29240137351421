/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import { getAllRawMaterial } from "app/redux/actions/inventoryAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SmokingModal } from "./Modals/issueForSmoking";
import { DyingModal } from "./Modals/issuedForDying";
import { RejectModal } from "./Modals/rejectModal";

export default function RawMaterialTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
  addGroup,
  setAddGroup,
  palleteList,
  totalSqm,
}) {
  const dispatch = useDispatch();
  const { rawMaterial, error, TotalPage, loading } = useSelector(
    (state) => state.inventoryReducer
  );
  const [open, setOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [smokingOpen, setSmokingOpen] = useState(false);
  const [dyingOpen, setDyingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState({});

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const navigate = useNavigate();
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setAddGroup([]);
    dispatch(getAllRawMaterial(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "reject":
        setRejectOpen(true);
        setRowData(menuItem.data);
        break; // Add break statement here
      case "smokingpattas":
        setSmokingOpen(true);
        setRowData(menuItem.data);
        break;
      case "dyingpattas":
        setDyingOpen(true);
        setRowData(menuItem.data);
        break;
      case "edit":
        navigate("/dashboard/inventory/edit", { state: menuItem.data });
        break;
      case "delete":
        deleteRawVeneer(menuItem?.data?._id);
        break;
      default:
        break;
    }
  };

  const deleteRawVeneer = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.delete(
            AllApis.raw_material.deleteInventory(id),
            config
          );
          if (response) {
            dispatch(getAllRawMaterial(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Deleted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const handleAddGroup = async (type) => {
    let module;
    let msg;
    if (type == "group") {
      module = "addGrouping";
      msg = "Grouping";
    } else if (type == "smoke") {
      module = "addSmoking";
      msg = "Smoking";
    } else if (type == "dying") {
      module = "addDying";
      msg = "Dying";
    }
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(
        AllApis.raw_material[module],
        { item_details: addGroup },
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: `Added To ${msg}`,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllRawMaterial(searchTerm, sort, sortBy, page, filter));
        setAddGroup([]);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked == true) {
      const selectedArr = [...addGroup];
      // if (selectedArr.length == 0) {
      //   setDisableCheckbox({
      //     item_name: row?.item_name,
      //     item_code: row?.item_code,
      //   });
      // }
      selectedArr.push(row?._id);
      setAddGroup(selectedArr);
    } else {
      const selectedArr = [...addGroup];
      const deletedIds = selectedArr.filter((ele) => ele != row?._id);
      // if (deletedIds.length == 0) {
      //   setDisableCheckbox({
      //     item_name: "",
      //     item_code: "",
      //   });
      // }
      setAddGroup(deletedIds);
    }
  };

  const editRawVeneer = async (id, name, value) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    if (value != null) {
      setIsLoading(true);
      try {
        const response = await Axios.patch(
          AllApis.raw_material.editRawMaterial(id),
          {
            [name]: name == "item_pallete_no" ? value.pallete_no : value,
            item_physical_location: value.item_physical_location,
          },
          config
        );
        if (response?.data?.status) {
          Swal.fire({
            title: "Updated",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
          dispatch(getAllRawMaterial(searchTerm, sort, sortBy, page, filter));
        }
      } catch (error) {
        Swal.fire({
          title: error?.response?.data.message,
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ pb: 2, fontWeight: 800 }}>
          Total Sqm Available: {totalSqm}
        </Typography>
      </Div>
      {addGroup?.length > 0 && permissions?.inventory_create == true && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#202020",
            p: 2,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" sx={{ color: "white", mt: 1 }}>
            {addGroup?.length} Bundle Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 3 }}>
            <LoadingButton
              variant="contained"
              onClick={() => handleAddGroup("group")}
            >
              Send To Grouping
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => handleAddGroup("smoke")}
            >
              Send To Smoking
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={() => handleAddGroup("dying")}
            >
              Send To Dying
            </LoadingButton>
          </Div>
        </Div>
      )}
      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {permissions?.inventory_create == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "50px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    <Checkbox
                      disabled={rawMaterial?.every(
                        (ele) => ele?.status !== "available"
                      )}
                      onClick={(event) => {
                        if (event?.target?.checked == true) {
                          //per page logic
                          // const allAvlRawIds = rawMaterial?.filter(
                          //   (ele) => ele.status == "available"
                          // );
                          // const allRawIds = allAvlRawIds?.map((ele) => ele?._id);
                          // setAddGroup(allRawIds);

                          //multiple pages
                          const allAvlRawIds = rawMaterial?.filter(
                            (ele) => ele.status == "available"
                          );
                          const allRawIds = allAvlRawIds?.map(
                            (ele) => ele?._id
                          );
                          setAddGroup([...addGroup, ...allRawIds]);
                        } else {
                          //per page
                          // setAddGroup([]);

                          //multiple page
                          setAddGroup(
                            addGroup.filter(
                              (item) =>
                                !rawMaterial
                                  .filter((item) => item.status === "available")
                                  .map((item) => item._id)
                                  .includes(item)
                            )
                          );
                        }
                      }}
                      color="primary"
                      checked={
                        addGroup?.length > 0 &&
                        //per page
                        // addGroup.every((groupId) =>
                        //   rawMaterial
                        //     ?.filter((ele) => ele.status == "available")
                        //     .some((avlItem) => avlIte  m._id === groupId)
                        // )

                        //multiple page
                        rawMaterial
                          .filter((item) => item.status === "available")
                          .map((item) => item._id)
                          .every((id) => addGroup.includes(id))
                      }
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "130px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "invoice_no"}
                    direction={sort}
                    onClick={() => handleSort("invoice_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Invoice No.
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "300px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_name"}
                    direction={sort}
                    onClick={() => handleSort("item_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_code"}
                    direction={sort}
                    onClick={() => handleSort("item_code")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Type
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "90px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_log_no"}
                    direction={sort}
                    onClick={() => handleSort("item_log_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Log No
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "108px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_bundle_no"}
                    direction={sort}
                    onClick={() => handleSort("item_bundle_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Bundle No
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "90px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_length"}
                    direction={sort}
                    onClick={() => handleSort("item_length")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Length
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "70px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_width"}
                    direction={sort}
                    onClick={() => handleSort("item_width")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Width
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "105px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_available_pattas"}
                    direction={sort}
                    onClick={() => handleSort("item_available_pattas")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    No. Of Pattas
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "108px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_available_quantities.natural"}
                    direction={sort}
                    onClick={() =>
                      handleSort("item_available_quantities.natural")
                    }
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Available Naturals
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "108px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_available_quantities.smoked"}
                    direction={sort}
                    onClick={() =>
                      handleSort("item_available_quantities.smoked")
                    }
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Available Smoked
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "108px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_available_quantities.dyed"}
                    direction={sort}
                    onClick={() => handleSort("item_available_quantities.dyed")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Available Dyed
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_rate_per_sqm"}
                    direction={sort}
                    onClick={() => handleSort("item_rate_per_sqm")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Rate per Sqm
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "130px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_available_sqm"}
                    direction={sort}
                    onClick={() => handleSort("item_available_sqm")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Item Sqm
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "75px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_grade"}
                    direction={sort}
                    onClick={() => handleSort("item_grade")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Grade
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "200px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_pallete_no"}
                    direction={sort}
                    onClick={() => handleSort("item_pallete_no")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Pallet No
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "200px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "item_physical_location"}
                    direction={sort}
                    onClick={() => handleSort("item_physical_location")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Physical Location
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "85px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Details
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "315px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "supplier_details.supplier_name"}
                    direction={sort}
                    onClick={() => handleSort("supplier_details.supplier_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Supplier
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Inward Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "180px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Remarks
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "115px",
                    verticalAlign: "middle",
                    px: 1,
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "created_employee_id.first_name"}
                    direction={sort}
                    onClick={() => handleSort("created_employee_id.first_name")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Created By
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: "95px",
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  <TableSortLabel
                    active={sortBy === "status"}
                    direction={sort}
                    onClick={() => handleSort("status")}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white",
                      },
                    }}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rawMaterial?.map((row, i) => (
                <TableRow key={i}>
                  {permissions?.inventory_create && (
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      <Checkbox
                        disabled={row?.status !== "available"}
                        onClick={(event) => handleCheckbox(event, row)}
                        color="primary"
                        checked={addGroup?.some((ele) => ele == row._id)}
                      />
                    </TableCell>
                  )}
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.invoice_no || "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_log_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_bundle_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_available_pattas}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_available_quantities?.natural || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_available_quantities?.smoked || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_available_quantities?.dyed || 0}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_rate_per_sqm}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_available_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.item_grade}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={palleteList || []}
                      name="item_pallete_no"
                      value={row?.item_pallete_no}
                      isOptionEqualToValue={(option, value) =>
                        option.pallete_no === value
                      }
                      getOptionLabel={(option) => {
                        if (option instanceof Object) {
                          return option.pallete_no;
                        } else {
                          return option;
                        }
                      }}
                      onChange={(e, newValue) => {
                        editRawVeneer(row._id, "item_pallete_no", newValue);
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.pallete_no}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {/* <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="physical_location"
                      value={row?.item_physical_location}
                      onChange={(e) => {
                        editRawVeneer(
                          row._id,
                          "item_physical_location",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                      <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                      <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
                      <MenuItem value={"VEENEER SECTION"}>
                        VEENEER SECTION
                      </MenuItem>
                      <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
                    </Select> */}
                    <TextField
                      multiline
                      size="small"
                      value={row?.item_physical_location}
                      sx={{ width: "100%", ...disabledStyling }}
                      name="item_physical_location"
                      disabled
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 2 }}>
                    <Div
                      onClick={() => {
                        setOpen(true);
                        setRowData(row);
                      }}
                    >
                      <InfoIcon sx={{ cursor: "pointer" }} />
                    </Div>
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row.supplier_details.supplier_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {formatDate(row.date_of_inward)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_remark ? row?.item_remark : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.created_employee_id?.first_name}{" "}
                    {row?.created_employee_id?.last_name}
                  </TableCell>

                  {/* <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_material_inventory.raw_invoice_date == null
                    ? "N/A"
                    : displayDateFun(row.raw_material_inventory.raw_invoice_date)}
                </TableCell> */}
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: "95px",
                      zIndex: 1,
                      bgcolor: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.status}
                  </TableCell>
                  {row?.status === "available" &&
                  ["inventory_edit", "inventory_create"].some(
                    (ele) => permissions[ele] == true
                  ) ? (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        px: 1,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "white",
                      }}
                    >
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <CancelIcon />,
                            title: "Reject Pattas",
                            action: "reject",
                            data: row,
                            show: permissions?.inventory_edit,
                          },
                          // {
                          //   icon: <AddCircleOutlineIcon />,
                          //   title: "Issue For Smoking",
                          //   action: "smokingpattas",
                          //   data: row,
                          //   show: permissions?.inventory_create,
                          // },
                          // {
                          //   icon: <AddCircleOutlineIcon />,
                          //   title: "Issue For Dying",
                          //   action: "dyingpattas",
                          //   data: row,
                          //   show: permissions?.inventory_create,
                          // },
                          {
                            icon: <ModeEditIcon />,
                            title: "Edit",
                            action: "edit",
                            data: row,
                            show:
                              permissions?.inventory_edit &&
                              row?.item_available_quantities?.total ==
                                row?.item_received_quantities?.total,
                          },
                          {
                            icon: <DeleteIcon />,
                            title: "Delete",
                            action: "delete",
                            data: row,
                            show:
                              permissions?.inventory_edit &&
                              row?.item_available_quantities?.total ==
                                row?.item_received_quantities?.total,
                          },
                        ].filter((ele) => ele?.show == true)}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{
                        textAlign: "left",
                        px: 1,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "white",
                      }}
                    >
                      <Typography>N/A</Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            {open && (
              <RawInventoryModal
                open={open}
                setOpen={setOpen}
                rowData={rowData}
              />
            )}
            {smokingOpen && (
              <SmokingModal
                open={smokingOpen}
                setOpen={setSmokingOpen}
                rowData={rowData}
                setAddGroup={setAddGroup}
              />
            )}
            {rejectOpen && (
              <RejectModal
                open={rejectOpen}
                setOpen={setRejectOpen}
                rowData={rowData}
              />
            )}
            {dyingOpen && (
              <DyingModal
                open={dyingOpen}
                setOpen={setDyingOpen}
                rowData={rowData}
                setAddGroup={setAddGroup}
              />
            )}
          </Table>
          <Pagination
            count={TotalPage || 1} // Replace with the actual total number of pages
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
      </Div>
    </>
  );
}
