import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { MenuItem, Select, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { getAllOtherGoods } from "app/redux/actions/inventoryAction";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";

const AvailableQuantityModal = ({ open, onClose, rowData }) => {
  const [consumed, setConsumed] = useState({}); // Separate state for consumed quantity
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setConsumed(rowData);
  }, [rowData]);

  const handleClose = () => {
    onClose();
  };
  const validationSchema = yup.object({
    date_of_consumption: yup
      .date()
      .required("Required")
      .test(
        "date-check",
        "Date of consumption cannot be before the date of inward",
        function (value) {
          const dateOfInward = new Date(consumed?.date_of_inward);
          const dateOfConsumption = new Date(value);
          return dateOfConsumption >= dateOfInward;
        }
      ),
    consumption_quantity: yup.number().positive().required("is Required"),
    processes: yup
      .string()
      .test("is-select", "Required", (value) => value !== "Select")
      .required("Required"),
  });

  const handleOnSave = async (values) => {
    const body = {
      ...consumed,
      ...values,
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setIsSubmitting(true);
      const response = await Axios.post(
        AllApis.other_goods.consumption(consumed?._id),
        body,
        config
      );
      onClose(false);
      dispatch(getAllOtherGoods("", "desc", "updated_at", 1, {}));
      if (response?.data?.status) {
        Swal.fire({
          title: "Consumption Added Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Formik
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={{
          processes: "Select",
          consumption_quantity: consumed?.available_quantity,
          date_of_consumption: getCurrentDate(),
          other_goods_consumed_remarks: "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => handleOnSave(values)}
      >
        {({ values, errors, touched, setFieldValue, initialValues }) => (
          <DialogContent>
            <Div sx={{ minWidth: 600 }}>
              <DialogTitle sx={{ fontWeight: "700", marginLeft: "-22px" }}>
                <Typography variant="h3" color="initial" fontWeight={600}>
                  Add Consume Goods
                </Typography>
              </DialogTitle>
            </Div>
            <Div>
              <Div sx={{ display: "flex", gap: 5, mb: 2 }}>
                <Typography variant="h4" fontWeight={600}>
                  Name : {rowData?.item_name}
                </Typography>

                <Typography variant="h4" fontWeight={600}>
                  Rate : {rowData?.rate}
                </Typography>
                <Typography variant="h4" fontWeight={600}>
                  Unit : {rowData?.units}
                </Typography>
                <Typography variant="h4" fontWeight={600}>
                  Available Quantity : {rowData?.available_quantity}
                </Typography>
              </Div>

              <Form>
                <Div sx={{ display: "flex", width: "100%", columnGap: 3 ,flexWrap:"wrap"}}>
                  <Div sx={{ width: "30%" }}>
                    <Typography variant="h5">Consumption Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={
                          values?.date_of_consumption !== ""
                            ? dayjs(values?.date_of_consumption)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "date_of_consumption",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="date_of_consumption"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div sx={{ width: "30%" }}>
                    <Typography variant="h5">Consumed Quantity</Typography>
                    <TextField
                      size="small"
                      type="number"
                      name={"consumption_quantity"}
                      value={values?.consumption_quantity}
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 1,
                        max: consumed?.available_quantity,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseFloat(
                          e.target.value + e.key,
                          10
                        );

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > consumed?.available_quantity
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(event) =>
                        setFieldValue(
                          "consumption_quantity",
                          event.target.value
                        )
                      }
                    />
                    <ErrorMessage
                      name="consumption_quantity"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "30%" }}>
                    <Typography variant="h5">Processes</Typography>

                    <Select
                      fullWidth
                      // error={!!errors?.processes}
                      name="processes"
                      value={values.processes}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        setFieldValue("processes", e.target.value)
                      }
                    >
                      <MenuItem value={"Select"}>Select</MenuItem>
                      <MenuItem value={"Cutting"}>Cutting</MenuItem>
                      <MenuItem value={"Tapping"}>Tapping</MenuItem>
                      <MenuItem value={"Pressing"}>Pressing</MenuItem>
                      <MenuItem value={"Finishing"}>Finishing</MenuItem>
                    </Select>
                    {errors?.processes && (
                      <ErrorMessage
                        name="processes"
                        component="div"
                        style={{ color: "red" }}
                      />
                    )}
                  </Div>

                  <Div sx={{ width: "100%" }}>
                    <Typography variant="h5">Remarks</Typography>

                    <TextField
                      multiline
                      size="small"
                      value={values?.other_goods_consumed_remarks}
                      sx={{ width: "100%" }}
                      name="other_goods_consumed_remarks"
                      onChange={(e) =>
                        setFieldValue(
                          "other_goods_consumed_remarks",
                          e.target.value
                        )
                      }
                    />
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 4 }}
                    loading={isSubmitting}
                  >
                    Add Consumption
                  </LoadingButton>
                </Div>
              </Form>
            </Div>
          </DialogContent>
        )}
      </Formik>
    </Dialog>
  );
};

export default AvailableQuantityModal;
