import Div from "@jumbo/shared/Div";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { PattasTable } from "app/components/Modal/pattasTable";

export const IssuedDyingPattasModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      {/* <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      >
        <DialogTitle sx={{ fontWeight: "700" }}>
          <Div>
            <Typography variant="h3" color="initial" fontWeight={600}>
              Dying Details:-
            </Typography>
          </Div>
        </DialogTitle>
      </Div> */}
      <DialogContent>
        <Div sx={{ mb: 2 }}>
          <Typography variant="h3" color="initial" fontWeight={600}>
            Dying Details
          </Typography>
        </Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <PattasTable
            data={rowData.issued_dying_quantity}
            heading={"Issued Dying Quantity"}
          />
        </Div>
      </DialogContent>
    </Dialog>
  );
};
