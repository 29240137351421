import Page from "@jumbo/shared/Page";
import ReadySheetFormRouteMiddleware from "./middleware/auth/readysheetformValidRoute";
import ReadySheetFormformList from "app/pages/Inventory/ReadySheetForm/List";

export const readySheetFormRoute = [
  {
    middleware: [
      {
        element: ReadySheetFormRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/inventory/readysheetform",
        element: <Page component={ReadySheetFormformList} layout={"vertical-default"} />,
      },
    //   {
    //     path: "/dashboard/inventory/readysheetform/add",
    //     element: <Page component={AddReadySheetForm} layout={"vertical-default"} />,
    //   },
    ],
  },
];
