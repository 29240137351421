import AllApis from "app/Apis";
import {
  ALL_COMPLETE_GROUP_ORDERS_FAIL,
  ALL_COMPLETE_GROUP_ORDERS_REQUEST,
  ALL_COMPLETE_GROUP_ORDERS_SUCCESS,
  ALL_COMPLETE_RAW_ORDERS_FAIL,
  ALL_COMPLETE_RAW_ORDERS_REQUEST,
  ALL_COMPLETE_RAW_ORDERS_SUCCESS,
  ALL_PENDING_GROUP_ORDERS_FAIL,
  ALL_PENDING_GROUP_ORDERS_REQUEST,
  ALL_PENDING_GROUP_ORDERS_SUCCESS,
  ALL_PENDING_RAW_ORDERS_FAIL,
  ALL_PENDING_RAW_ORDERS_REQUEST,
  ALL_PENDING_RAW_ORDERS_SUCCESS,
} from "app/utils/constants/ordersConstant";
import { Axios } from "index";

//pending raw orders
export const getPendingRawOrderList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["purchase_order_no", "customer_name", "place", "order_mode"],
          numbers: ["order_no"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "raw_order_details.order_item_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_PENDING_RAW_ORDERS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.order.pendingRawOrders}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_PENDING_RAW_ORDERS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PENDING_RAW_ORDERS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//pending group orders
export const getPendingGroupOrderList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["purchase_order_no", "customer_name", "place", "order_mode"],
          numbers: ["order_no"],
          arrayField: [
            "group_order_details.order_item_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_PENDING_GROUP_ORDERS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.order.pendingGroupOrders}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_PENDING_GROUP_ORDERS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PENDING_GROUP_ORDERS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//complete raw orders
export const getCompleteRawOrderList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["purchase_order_no", "customer_name", "place", "order_mode"],
          numbers: ["order_no"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "raw_order_details.order_item_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_COMPLETE_RAW_ORDERS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.order.completeRawOrders}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_COMPLETE_RAW_ORDERS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_COMPLETE_RAW_ORDERS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//complete group orders
export const getCompleteGroupOrderList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["purchase_order_no", "customer_name", "place", "order_mode"],
          numbers: ["order_no"],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_COMPLETE_GROUP_ORDERS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.order.completeGroupOrders}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_COMPLETE_GROUP_ORDERS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_COMPLETE_GROUP_ORDERS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
