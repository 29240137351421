import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export const CuttingBundleModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Cutting Bundles Details:-
        </Typography>
        <Div>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                <TableCell sx={{ color: "white" }}>Rate Per Sqm</TableCell>
                <TableCell sx={{ color: "white" }}>Log No.</TableCell>
                <TableCell sx={{ color: "white" }}>Bundle No.</TableCell>
                {rowData[0]?.final_cutting_quantity && (
                  <>
                    <TableCell sx={{ color: "white" }}>
                      Cutting Length
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>Cutting Width</TableCell>
                    <TableCell sx={{ color: "white" }}>No. Of Pattas</TableCell>
                    <TableCell sx={{ color: "white" }}>Sqm</TableCell>
                  </>
                )}
                <TableCell sx={{ color: "white" }}>Natural</TableCell>
                <TableCell sx={{ color: "white" }}>Dyed</TableCell>
                <TableCell sx={{ color: "white" }}>Smoked</TableCell>
                <TableCell sx={{ color: "white" }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((ele) => {
                return (
                  <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                    <TableCell>{ele?.item_name}</TableCell>
                    <TableCell>{ele?.item_code}</TableCell>
                    <TableCell>{ele?.item_rate_per_sqm}</TableCell>
                    <TableCell>{ele?.item_log_no}</TableCell>
                    <TableCell>{ele?.item_bundle_no}</TableCell>
                    {ele?.final_cutting_quantity && (
                      <>
                        <TableCell>{ele?.cutting_length}</TableCell>
                        <TableCell>{ele?.cutting_width}</TableCell>
                        <TableCell>{ele?.cutting_no_of_pattas}</TableCell>
                        <TableCell>{ele?.cutting_sqm}</TableCell>
                      </>
                    )}
                    <TableCell>{ele?.cutting_quantity?.natural}</TableCell>
                    <TableCell>{ele?.cutting_quantity?.dyed}</TableCell>
                    <TableCell>{ele?.cutting_quantity?.smoked}</TableCell>
                    <TableCell>{ele?.cutting_quantity?.total}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
