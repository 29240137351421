import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

export const IssueForCuttingModal = ({
  open,
  setOpen,
  rowData,
  indexNo,
  rejectedBundles,
  setRejectedBundles,
}) => {
  const [data, setData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState({
    cutting_quantity: {
      natural: 0,
      dyed: 0,
      smoked: 0,
      total: 0,
    },
    group_sqm: 0,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let rejectedQty = {
      cutting_quantity: {
        ...quantity.cutting_quantity,
        [name]: Number(value),
      },
    };
    const { natural, dyed, smoked } = rejectedQty.cutting_quantity;
    const totalReceived =
      (parseFloat(natural) || 0) +
      (parseFloat(dyed) || 0) +
      (parseFloat(smoked) || 0);
    rejectedQty.cutting_quantity.total = totalReceived;
    rejectedQty.group_sqm = parseFloat(
      (
        (rowData?.item_length *
          rowData?.item_width *
          (rowData?.item_available_pattas - totalReceived)) /
        10000
      ).toFixed(2)
    );
    setQuantity(rejectedQty);
  };

  const handleIssue = async () => {
    const rejectedArr = [...rejectedBundles["cutting_item_details"]];
    rejectedArr[indexNo] = {
      ...quantity,
      item_id: rowData?._id,
    };
    setRejectedBundles({
      ...rejectedBundles,
      cutting_item_details: rejectedArr,
    });
    setOpen(false);
  };

  useEffect(() => {
    setData(rowData);
    rejectedBundles?.["cutting_item_details"]?.[indexNo]?.item_id
      ? setQuantity(rejectedBundles["cutting_item_details"][indexNo])
      : setQuantity({
          cutting_quantity: {
            natural: 0,
            dyed: 0,
            smoked: 0,
            total: 0,
          },
          group_sqm: 0,
        });
  }, [open]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      ></Div>
      <DialogContent>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Div>
            <Typography variant="h4" fontWeight={600}>
              Add Issue For Cutting Quantity
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell sx={{ color: "white" }}>
                    Available Natural
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Available Dyed</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Available Smoked
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Available Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.natural}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.dyed}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.smoked}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.item_available_quantities?.total}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell sx={{ color: "white" }}>Issued Natural</TableCell>
                  <TableCell sx={{ color: "white" }}>Issued Dyed</TableCell>
                  <TableCell sx={{ color: "white" }}>Issued Smoked</TableCell>
                  <TableCell sx={{ color: "white" }}>Issued Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="natural"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.natural,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.natural
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.cutting_quantity?.natural}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="dyed"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.dyed,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.dyed
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.cutting_quantity?.dyed}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="smoked"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.item_available_quantities?.smoked,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.item_available_quantities?.smoked
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.cutting_quantity?.smoked}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={quantity?.cutting_quantity?.total}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Div sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LoadingButton
                disabled={quantity?.cutting_quantity?.total == 0}
                loading={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mx: "auto", textAlign: "center" }}
                onClick={() => handleIssue()}
              >
                Issue
              </LoadingButton>
            </Div>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
