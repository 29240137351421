import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const CreateCuttingModal = ({
  open,
  setOpen,
  rowData,
  indexNo,
  rejectedBundles,
  setRejectedBundles,
}) => {
  const [data, setData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState({
    final_cutting_quantity: {
      natural: 0,
      dyed: 0,
      smoked: 0,
      total: 0,
    },
    cutting_length: 0,
    cutting_width: 0,
    cutting_sqm: 0,
    cutting_no_of_pattas: 0,
  });

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    let rejectedQty = {
      final_cutting_quantity: {
        ...quantity.final_cutting_quantity,
        [name]: Number(value),
      },
    };
    const { natural, dyed, smoked } = rejectedQty.final_cutting_quantity;
    const totalReceived =
      (parseFloat(natural) || 0) +
      (parseFloat(dyed) || 0) +
      (parseFloat(smoked) || 0);
    rejectedQty.final_cutting_quantity.total = totalReceived;
    rejectedQty.cutting_no_of_pattas = totalReceived;
    setQuantity((prev) => ({ ...prev, ...rejectedQty }));
  };

  const handleAddToCuttting = async () => {
    const rejectedArr = [...rejectedBundles["item_details"]];
    rejectedArr[indexNo] = {
      ...quantity,
      item_id: rowData?._id,
    };
    setRejectedBundles({
      ...rejectedBundles,
      item_details: rejectedArr,
    });
    setOpen(false);
  };

  const handleCutting = (e) => {
    const { name, value } = e.target;
    setQuantity((prev) => ({ ...prev, [name]: Number(value) }));
  };
  useEffect(() => {
    setData(rowData);
    rejectedBundles?.["item_details"]?.[indexNo]?.item_id
      ? setQuantity(rejectedBundles["item_details"][indexNo])
      : setQuantity({
          final_cutting_quantity: {
            natural: 0,
            dyed: 0,
            smoked: 0,
            total: 0,
          },
          cutting_length: 0,
          cutting_width: 0,
          cutting_sqm: 0,
          cutting_no_of_pattas: 0,
        });
  }, [open]);

  useEffect(() => {
    if (quantity?.cutting_length != 0 && quantity?.cutting_width != 0) {
      const sqm = parseFloat(
        (Number(quantity?.cutting_length) *
          Number(quantity?.cutting_width) *
          quantity?.cutting_no_of_pattas) /
          10000
      ).toFixed(2);
      setQuantity((prev) => ({ ...prev, cutting_sqm: sqm }));
    }
  }, [
    quantity?.cutting_no_of_pattas,
    quantity?.cutting_length,
    quantity?.cutting_width,
  ]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <Div
        sx={{
          display: "flex",
          minWidth: 600,
        }}
      ></Div>
      <DialogContent>
        <Div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Div>
            <Typography variant="h4" fontWeight={600}>
              Add For Cutting Quantity
            </Typography>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell sx={{ color: "white" }}>
                    Available Natural
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Available Dyed</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Available Smoked
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Available Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.cutting_quantity?.natural}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.cutting_quantity?.dyed}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.cutting_quantity?.smoked}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={data?.cutting_quantity?.total}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell sx={{ color: "white" }}>Cutting Natural</TableCell>
                  <TableCell sx={{ color: "white" }}>Cutting Dyed</TableCell>
                  <TableCell sx={{ color: "white" }}>Cutting Smoked</TableCell>
                  <TableCell sx={{ color: "white" }}>Cutting Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="natural"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.cutting_quantity?.natural,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.cutting_quantity?.natural
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.final_cutting_quantity?.natural}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="dyed"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.cutting_quantity?.dyed,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.cutting_quantity?.dyed
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.final_cutting_quantity?.dyed}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    {" "}
                    <TextField
                      size="small"
                      name="smoked"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: data?.cutting_quantity?.smoked,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > data?.cutting_quantity?.smoked
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e)}
                      value={quantity?.final_cutting_quantity?.smoked}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <TextField
                      sx={disabledStyling}
                      disabled
                      size="small"
                      value={quantity?.final_cutting_quantity?.total}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Div
              sx={{
                mt: 3,
                display: "flex",
                width: "100%",
                columnGap: 4.6,
                pl: 3,
              }}
            >
              <TextField
                size="small"
                name="cutting_length"
                type="number"
                label="Cutting Length"
                sx={{
                  width: "21.5%",
                  "& .MuiInputLabel-root": {
                    color: "#475259", // Change label color to blue
                  },
                }}
                inputProps={{
                  min: 0,
                }}
                onKeyDown={(e) => {
                  const inputValue = parseInt(e.target.value + e.key, 10);
                  if (isNaN(inputValue) || inputValue < 0) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => handleCutting(e)}
                value={quantity?.cutting_length || 0}
              />
              <TextField
                size="small"
                name="cutting_width"
                type="number"
                label="Cutting Width"
                sx={{
                  width: "21.5%",
                  "& .MuiInputLabel-root": {
                    color: "#475259", // Change label color to blue
                  },
                }}
                inputProps={{
                  min: 0,
                }}
                onKeyDown={(e) => {
                  const inputValue = parseInt(e.target.value + e.key, 10);
                  if (isNaN(inputValue) || inputValue < 0) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => handleCutting(e)}
                value={quantity?.cutting_width || 0}
              />
              <TextField
                disabled
                size="small"
                name="cutting_no_of_pattas"
                type="number"
                label="No Of Pattas"
                sx={{ width: "21.5%", ...disabledStyling }}
                inputProps={{
                  min: 0,
                }}
                onKeyDown={(e) => {
                  const inputValue = parseInt(e.target.value + e.key, 10);
                  if (isNaN(inputValue) || inputValue < 0) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => handleCutting(e)}
                value={quantity?.cutting_no_of_pattas || 0}
              />
              <TextField
                size="small"
                name="cutting_sqm"
                type="number"
                label="Cutting Sqm"
                sx={{
                  width: "21.5%",
                  "& .MuiInputLabel-root": {
                    color: "#475259", // Change label color to blue
                  },
                }}
                inputProps={{
                  min: 0,
                }}
                onKeyDown={(e) => {
                  const inputValue = parseInt(e.target.value + e.key, 10);
                  if (isNaN(inputValue) || inputValue < 0) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => handleCutting(e)}
                value={quantity?.cutting_sqm || 0}
              />
            </Div>
            <Div sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ mx: "auto", textAlign: "center" }}
                onClick={() => handleAddToCuttting()}
              >
                Add To Cutting
              </LoadingButton>
            </Div>
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
