import Page from "@jumbo/shared/Page";
import otherGoodsMiddleware from "./middleware/auth/otherGoodsValidRoute";
import ListPurchase from "app/pages/Inventory/other goods/ListOtherGoods";
import AddOtherGoods from "app/pages/Inventory/other goods/AddOtherGoods";
import EditOtherGoods from "app/pages/Inventory/other goods/EditOtherGoods";

export const otherGoodsRoutes = [
  {
    middleware: [
      {
        element: otherGoodsMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/inventory/othergoods",
        element: <Page component={ListPurchase} layout={"vertical-default"} />,
      },

      {
        path: "/dashboard/inventory/othergoods/add",
        element: <Page component={AddOtherGoods} layout={"vertical-default"} />,
      },
      {
        path: "/dashboard/inventory/othergoods/edit",
        element: <Page component={EditOtherGoods} layout={"vertical-default"} />,
      },
    ],
  },
];
