export const LOAD_SUPPLIER_MASTER_REQUEST = "LOAD_SUPPLIER_MASTER_REQUEST";
export const LOAD_SUPPLIER_MASTER_SUCCESS = "LOAD_SUPPLIER_MASTER_SUCCESS";
export const LOAD_SUPPLIER_MASTER_FAIL = "LOAD_SUPPLIER_MASTER_FAIL";

export const ALL_SUPPLIER_MASTER_REQUEST = "ALL_SUPPLIER_MASTER_REQUEST";
export const ALL_SUPPLIER_MASTER_SUCCESS = "ALL_SUPPLIER_MASTER_SUCCESS";
export const ALL_SUPPLIER_MASTER_FAIL = "ALL_SUPPLIER_MASTER_FAIL";

export const LOAD_COSTING_MASTER_REQUEST = "LOAD_COSTING_MASTER_REQUEST";
export const LOAD_COSTING_MASTER_SUCCESS = "LOAD_COSTING_MASTER_SUCCESS";
export const LOAD_COSTING_MASTER_FAIL = "LOAD_COSTING_MASTER_FAIL";

export const ALL_COSTING_MASTER_REQUEST = "ALL_COSTING_MASTER_REQUEST";
export const ALL_COSTING_MASTER_SUCCESS = "ALL_COSTING_MASTER_SUCCESS";
export const ALL_COSTING_MASTER_FAIL = "ALL_COSTING_MASTER_FAIL";

export const LOAD_PRODUCT_MASTER_REQUEST = "LOAD_PRODUCT_MASTER_REQUEST";
export const LOAD_PRODUCT_MASTER_SUCCESS = "LOAD_PRODUCT_MASTER_SUCCESS";
export const LOAD_PRODUCT_MASTER_FAIL = "LOAD_PRODUCT_MASTER_FAIL";

export const ALL_PRODUCT_MASTER_REQUEST = "ALL_PRODUCT_MASTER_REQUEST";
export const ALL_PRODUCT_MASTER_SUCCESS = "ALL_PRODUCT_MASTER_SUCCESS";
export const ALL_PRODUCT_MASTER_FAIL = "ALL_PRODUCT_MASTER_FAIL";

//port master
export const ALL_GRADE_MASTER_REQUEST = "ALL_GRADE_MASTER_REQUEST";
export const ALL_GRADE_MASTER_SUCCESS = "ALL_GRADE_MASTER_SUCCESS";
export const ALL_GRADE_MASTER_FAIL = "ALL_GRADE_MASTER_FAIL";

//pallete master
export const ALL_PALLETE_MASTER_REQUEST = "ALL_PALLETE_MASTER_REQUEST";
export const ALL_PALLETE_MASTER_SUCCESS = "ALL_PALLETE_MASTER_SUCCESS";
export const ALL_PALLETE_MASTER_FAIL = "ALL_PALLETE_MASTER_FAIL";

//fabricColor master
export const ALL_FABRIC_COLOR_MASTER_REQUEST =
  "ALL_FABRIC_COLOR_MASTER_REQUEST";
export const ALL_FABRIC_COLOR_MASTER_SUCCESS =
  "ALL_FABRIC_COLOR_MASTER_SUCCESS";
export const ALL_FABRIC_COLOR_MASTER_FAIL = "ALL_FABRIC_COLOR_MASTER_FAIL";

//party master
export const ALL_PARTY_MASTER_REQUEST = "ALL_PARTY_MASTER_REQUEST";
export const ALL_PARTY_MASTER_SUCCESS = "ALL_PARTY_MASTER_SUCCESS";
export const ALL_PARTY_MASTER_FAIL = "ALL_PARTY_MASTER_FAIL";

//unit master
export const ALL_UNIT_MASTER_REQUEST = "ALL_UNIT_MASTER_REQUEST";
export const ALL_UNIT_MASTER_SUCCESS = "ALL_UNIT_MASTER_SUCCESS";
export const ALL_UNIT_MASTER_FAIL = "ALL_UNIT_MASTER_FAIL";

// item name master
export const ALL_ITEM_NAME_MASTER_REQUEST = "ALL_ITEM_NAME_MASTER_REQUEST";
export const ALL_ITEM_NAME_MASTER_SUCCESS = "ALL_ITEM_NAME_MASTER_SUCCESS";
export const ALL_ITEM_NAME_MASTER_FAIL = "ALL_ITEM_NAME_MASTER_FAIL";

// item Type master
export const ALL_ITEM_CODE_MASTER_REQUEST = "ALL_ITEM_CODE_MASTER_REQUEST";
export const ALL_ITEM_CODE_MASTER_SUCCESS = "ALL_ITEM_CODE_MASTER_SUCCESS";
export const ALL_ITEM_CODE_MASTER_FAIL = "ALL_ITEM_CODE_MASTER_FAIL";

//fabric master
export const ALL_FABRIC_MASTER_REQUEST = "ALL_FABRIC_MASTER_REQUEST";
export const ALL_FABRIC_MASTER_SUCCESS = "ALL_FABRIC_MASTER_SUCCESS";
export const ALL_FABRIC_MASTER_FAIL = "ALL_FABRIC_MASTER_FAIL";
