import Page from "@jumbo/shared/Page";
import AddSupplier from "app/pages/Master/SupplierMaster/AddSupplier";
import ListSupplier from "app/pages/Master/SupplierMaster/ListSupplier";
import AddUnit from "app/pages/Master/UnitMaster/AddUnit";
import ListUnit from "app/pages/Master/UnitMaster/ListUnit";
import SupplierRouteMiddleware from "./middleware/auth/supplierMasterValidRoute";
import UnitRouteMiddleware from "./middleware/auth/unitValidRoute";
import palletMiddleware from "./middleware/auth/PalletMiddleware";
import PartyMiddleware from "./middleware/auth/PartyMiddleware";
import ItemNameMiddleware from "./middleware/auth/ItemNameMiddleware";
import ItemCodeMiddleware from "./middleware/auth/ItemCodeMiddleware";
import GradeMiddleware from "./middleware/auth/GradeMiddleware";
import AddPallet from "app/pages/Master/PalletMaster/AddPallet";
import ListPallet from "app/pages/Master/PalletMaster/ListPallet";
import AddGrade from "app/pages/Master/GradeMaster/AddGrade";
import ListGrade from "app/pages/Master/GradeMaster/ListGrade";
import AddParty from "app/pages/Master/PartyMaster/AddParty";
import ListParty from "app/pages/Master/PartyMaster/ListParty";
import AddItemName from "app/pages/Master/ItemNameMaster/AddItemName";
import ListItemName from "app/pages/Master/ItemNameMaster/ListItemName";
import AddItemCode from "app/pages/Master/ItemCodeMaster/AddItemCode";
import ListItemCode from "app/pages/Master/ItemCodeMaster/ListItemCode";




export const masterRoutes = [
  {
    middleware: [
      {
        element: SupplierRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/supplier",
        element: <Page component={ListSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/add",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
      {
        path: "/master/supplier/edit",
        element: <Page component={AddSupplier} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: palletMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/pallet",
        element: <Page component={ListPallet} layout={"vertical-default"} />,
      },
      {
        path: "/master/pallet/add",
        element: <Page component={AddPallet} layout={"vertical-default"} />,
      },
      {
        path: "/master/pallet/edit",
        element: <Page component={AddPallet} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: UnitRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/unit",
        element: <Page component={ListUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/add",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
      {
        path: "/master/unit/edit",
        element: <Page component={AddUnit} layout={"vertical-default"} />,
      },
    ],
  },
  {
    middleware: [
      {
        element: GradeMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/grade",
        element: <Page component={ListGrade} layout={"vertical-default"} />,
      },
      {
        path: "/master/grade/add",
        element: <Page component={AddGrade} layout={"vertical-default"} />,
      },
      {
        path: "/master/grade/edit",
        element: <Page component={AddGrade} layout={"vertical-default"} />,
      },
    ],
  },

  {
    middleware: [
      {
        element: PartyMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/party",
        element: <Page component={ListParty} layout={"vertical-default"} />,
      },
      {
        path: "/master/party/add",
        element: <Page component={AddParty} layout={"vertical-default"} />,
      },
      {
        path: "/master/party/edit",
        element: <Page component={AddParty} layout={"vertical-default"} />,
      },
    ],
  },

 
  {
    middleware: [
      {
        element: ItemNameMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/item-name",
        element: <Page component={ListItemName} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-name/add",
        element: <Page component={AddItemName} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-name/edit",
        element: <Page component={AddItemName} layout={"vertical-default"} />,
      },
    ],
  },


  {
    middleware: [
      {

        element: ItemCodeMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/master/item-code",
        element: <Page component={ListItemCode} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-code/add",
        element: <Page component={AddItemCode} layout={"vertical-default"} />,
      },
      {
        path: "/master/item-code/edit",
        element: <Page component={AddItemCode} layout={"vertical-default"} />,
      },
    ],
  },


];
