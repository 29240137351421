import {
  ALL_CREATED_GROUPING_FAIL,
  ALL_CREATED_GROUPING_REQUEST,
  ALL_CREATED_GROUPING_SUCCESS,
  ALL_GROUPING_HISTORY_FAIL,
  ALL_GROUPING_HISTORY_REQUEST,
  ALL_GROUPING_HISTORY_SUCCESS,
} from "app/utils/constants/groupingConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  createdGroups: [],
  groupsHistory: [],
  TotalPage: null,
};

export const groupingReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //Issued Smoking
    case ALL_CREATED_GROUPING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_CREATED_GROUPING_SUCCESS:
      return {
        ...state,
        loading: false,
        createdGroups: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_CREATED_GROUPING_FAIL:
      return {
        loading: false,
        createdGroups: [],
        error: action.payload,
      };

    //Grouping History
    case ALL_GROUPING_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_GROUPING_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsHistory: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_GROUPING_HISTORY_FAIL:
      return {
        loading: false,
        groupsHistory: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
