import Div from "@jumbo/shared/Div/Div";
import { Button, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import ImageUpload from "app/components/UploadImages";
import { Axios } from "index";

export default function CreateFinishing() {
  const [groupNo, setGroupNo] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const finishingData = {
    group_no: state?.group_no,
    finishing_no_of_pcs: state?.available_pressed_pcs,
    finishing_length: "",
    finishing_width: "",
    finishing_sqm: "",
    finishing_remarks: "",
    finishing_images: [],
    tapping_id: state?.tapping_id,
    ready_sheet_form_inventory_id: state?.ready_sheet_form_inventory_id,
    ready_sheet_form_history_id: state?.ready_sheet_form_history_id,
    pressing_id: state?.pressing_id,
    status: state?.status,
    issued_for_finishing_id: state?._id,
  };

  const validationSchema = yup.object().shape({
    finishing_no_of_pcs: yup.string().required("No of Pieces is required"),
    finishing_length: yup
      .number()
      .typeError("Length must be a number")
      .required("Length is required"),
    finishing_width: yup
      .number()
      .typeError("Width must be a number")
      .required("Width is required"),
    finishing_sqm: yup
      .number()
      .typeError("Sqm must be a number")
      .required("Sqm is required"),
  });

  const handleSubmit = async (values) => {
    const form = new FormData();
    // Iterate over the entries of the object and append each key-value pair to the FormData object
    Object.entries(values).forEach((entry) => {
      if (entry[0] == "finishing_images") {
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.finishing.createFinishing,
        form,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Finishing Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/factory/finishing");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const calculateSqm = (e, setFieldValue, data) => {
    const { name, value } = e.target;
    if (name == "finishing_length") {
      const sqm =
        (value * (data.finishing_width || 0) * data?.finishing_no_of_pcs) /
        10000;
      setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
    } else if (name == "finishing_width") {
      const sqm =
        (value * (data.finishing_length || 0) * data?.finishing_no_of_pcs) /
        10000;
      setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
    } else {
      const sqm =
        ((data.finishing_length || 0) * (data.finishing_width || 0) * value) /
        10000;
      setFieldValue("finishing_sqm", parseFloat(sqm.toFixed(2)));
    }
    setFieldValue([name], value);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Create Finishing</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={finishingData}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                        <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                        <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                        {/* <TableCell sx={{ color: "white" }}>Group Pcs</TableCell> */}
                        <TableCell sx={{ color: "white" }}>Grade</TableCell>
                        <TableCell sx={{ color: "white" }}>
                          Pallet No.
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          Physical Location
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{state?.group_details?.group_no}</TableCell>
                        <TableCell>
                          {
                            state?.cutting_details?.cutting_id?.[0]
                              ?.item_details?.item_data?.[0]?.item_name
                          }
                        </TableCell>
                        <TableCell>
                          {
                            state?.cutting_details?.cutting_id?.[0]
                              ?.item_details?.item_data?.[0]?.item_code
                          }
                        </TableCell>
                        {/* <TableCell>{state?.group_details?.group_pcs}</TableCell> */}
                        <TableCell>
                          {state?.group_details?.group_grade}
                        </TableCell>
                        <TableCell>
                          {state?.group_details?.group_pallete_no}
                        </TableCell>
                        <TableCell>
                          {state?.group_details?.group_physical_location}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Length
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Width
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "100px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Original Pressing Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pressing Sqm
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Avl Prssing Pcs
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* <TableBody>
                      <TableRow>
                      {console.log(state?.pressing_details?.pressing_sqm,"state?.pressing_details?.pressing_sqm")}
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.ready_sheet_form_history_details?.group_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_width}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_no_of_peices}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_sqm}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.status}
                        </TableCell>
                      </TableRow>
                    </TableBody> */}
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_width}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_no_of_peices}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.pressing_details?.pressing_sqm}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left", px: 1 }}>
                          {state?.available_pressed_pcs}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            px: 1,
                            textTransform: "capitalize",
                          }}
                        >
                          {state?.status}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%", display: "flex", columnGap: 5 }}>
                <Div
                  sx={{
                    width: "50%",
                  }}
                >
                  <Div
                    sx={{
                      width: "100%",
                      display: "flex",
                      columnGap: 3,
                      flexWrap: "wrap",
                    }}
                  >
                    <Div sx={{ width: "22.3%" }}>
                      <Typography variant="h5">Finishing Length*</Typography>
                      <TextField
                        type="number"
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={values?.finishing_length}
                        name="finishing_length"
                        onChange={(e) => calculateSqm(e, setFieldValue, values)}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="finishing_length"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "22.3%" }}>
                      <Typography variant="h5">Finishing Width*</Typography>
                      <TextField
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={values?.finishing_width}
                        name="finishing_width"
                        onChange={(e) => calculateSqm(e, setFieldValue, values)}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="finishing_width"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                    <Div sx={{ width: "22.3%" }}>
                      <Typography variant="h5">
                        Finishing No. of Pieces*
                      </Typography>
                      <TextField
                        type="number"
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        // disabled
                        inputProps={{
                          min: 1,
                          max: state?.available_pressed_pcs,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (
                            isNaN(inputValue) ||
                            inputValue < 0 ||
                            inputValue > state?.available_pressed_pcs
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={values?.finishing_no_of_pcs}
                        onChange={(e) => calculateSqm(e, setFieldValue, values)}
                        name="finishing_no_of_pcs"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="finishing_no_of_pcs"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>

                    <Div sx={{ width: "22.3%" }}>
                      <Typography variant="h5">Finishing sqm*</Typography>
                      <TextField
                        type="number"
                        sx={{ width: "100%", ...disabledStyling }}
                        disabled
                        size="small"
                        value={values?.finishing_sqm}
                        name="finishing_sqm"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="finishing_sqm"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>

                    <Div sx={{ width: "100%" }}>
                      <Typography variant="h5">Finishing Remarks</Typography>
                      <TextField
                        // disabled
                        sx={{ width: "100%" }}
                        size="small"
                        value={values?.finishing_remarks}
                        name="finishing_remarks"
                        onChange={(e) => {
                          setFieldValue("finishing_remarks", e.target.value);
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="finishing_remarks"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div>
                <Div sx={{ width: "50%" }}>
                  <Div sx={{ width: "100%" }}>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"finishing_images"}
                    />
                    <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                      <ErrorMessage
                        name="finishing_images"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                ></Div>
              </Div>

              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/factory/finishing");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Finishing
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
