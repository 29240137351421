import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Switch, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { addRole } from "app/services/apis/addRole";
import { updateRole } from "app/services/apis/updateRole";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function PermissionList() {
  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const { state } = useLocation();

  const headingStyle = {
    minWidth: "20%",
    fontSize: "1rem",
    fontWeight: "bold",
  };
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 4,
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [role_name, setRoleName] = useState(
    state?.role_name ? state?.role_name : ""
  );
  const [roleRemarks, setRoleRemarks] = useState(
    state?.roles_remarks ? state?.roles_remarks : ""
  );

  const [status, setStatus] = useState(state?.status === false ? false : true);

  const [check, setCheck] = useState(
    state?.permissions
      ? state?.permissions
      : {
          user_view: false,
          user_edit: false,
          user_create: false,
          role_view: false,
          role_edit: false,
          role_create: false,
          supplier_master_view: false,
          supplier_master_edit: false,
          supplier_master_create: false,

          pallete_master_view: false,
          pallete_master_edit: false,
          pallete_master_create: false,

          party_master_view: false,
          party_master_edit: false,
          party_master_create: false,

          unit_master_view: false,
          unit_master_edit: false,
          unit_master_create: false,

          item_name_master_view: false,
          item_name_master_edit: false,
          item_name_master_create: false,

          item_code_master_view: false,
          item_code_master_edit: false,
          item_code_master_create: false,

          grade_master_view: false,
          grade_master_edit: false,
          grade_master_create: false,

          grouped_veneer_view: false,
          grouped_veneer_edit: false,
          grouped_veneer_create: false,

          other_goods_view: false,
          other_goods_edit: false,
          other_goods_create: false,

          inventory_view: false,
          inventory_edit: false,
          inventory_create: false,

          grouping_view: false,
          grouping_edit: false,
          grouping_create: false,

          smoking_view: false,
          smoking_edit: false,
          smoking_create: false,

          dying_view: false,
          dying_edit: false,
          dying_create: false,

          cutting_view: false,
          cutting_edit: false,
          cutting_create: false,

          tapping_view: false,
          tapping_edit: false,
          tapping_create: false,

          ready_sheet_form_view: false,
          ready_sheet_form_edit: false,
          ready_sheet_form_create: false,

          pressing_view: false,
          pressing_edit: false,
          pressing_create: false,

          finishing_view: false,
          finishing_edit: false,
          finishing_create: false,
          qc_view: false,
          qc_edit: false,
          qc_create: false,

          orders_view: false,
          orders_edit: false,
          orders_create: false,

          dispatch_view: false,
          dispatch_edit: false,
          dispatch_create: false,
        }
  );

  const [selectAll, setSelectAll] = useState({
    edit: false,
    view: false,
    create: false,
  });

  const handleChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const handleAllView = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, view: e.target.checked });
    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_view")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleAllEdit = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, edit: e.target.checked });

    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_edit")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleAllCreate = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, create: e.target.checked });
    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_create")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleSubmit = async (e) => {
    if (role_name == "") {
      return Swal.fire({ icon: "warning", title: "Fill Role Name", text: "" });
    }
    setSubmitting(true);

    if (pathname == "/dashboard/addrole") {
      const details = {
        name: role_name,
        roles_remarks: roleRemarks,
        permissions: check,
        status: status,
      };
      const data = await addRole(details);

      if (data.status == 201) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/roles");
      } else {
        setSubmitting(false);

        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } else {
      const details = {
        id: state._id,
        name: role_name,
        roles_remarks: roleRemarks,
        permissions: check,
        status: status,
      };
      const data = await updateRole(details);

      if (data.status == 200) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/roles");
      } else {
        setSubmitting(false);

        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    //for SelectAll Checkbox
    ["create", "edit", "view"].map((permission) => {
      const roles = Object.entries(check)
        .filter(
          (ele) => ele[0].split("_")[ele[0].split("_").length - 1] == permission
        )
        .every((ele) => ele[1] == true);
      if (roles) {
        setSelectAll((prev) => ({ ...prev, [permission]: true }));
      }
    });
  }, [check]);

  return (
    <Div sx={{ width: "100%" }}>
      <Div sx={{ display: "flex", columnGap: 3 }}>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Role Name*</Typography>
          <TextField
            size="small"
            value={role_name}
            sx={{
              width: "100%",
              height: "39px",
            }}
            onChange={(event) => {
              setRoleName(event.target.value);
            }}
          />
        </Div>
        <Div sx={{ width: "20%" }}>
          <Typography variant="h5">Remark</Typography>
          <TextField
            multiline
            size="small"
            value={roleRemarks}
            sx={{
              width: "100%",
              height: "39px",
            }}
            onChange={(event) => {
              setRoleRemarks(event.target.value);
            }}
          />
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 3,
        }}
      >
        <Typography sx={headingStyle}>Select</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox checked={selectAll.view} onChange={handleAllView} />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox checked={selectAll.edit} onChange={handleAllEdit} />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={selectAll.create}
            onChange={handleAllCreate}
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 3,
        }}
      >
        <Typography sx={headingStyle}>User Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_view}
            onChange={handleChange}
            name="user_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_edit}
            onChange={handleChange}
            name="user_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_create}
            onChange={handleChange}
            name="user_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Roles & Permissions</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_view}
            onChange={handleChange}
            name="role_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_edit}
            onChange={handleChange}
            name="role_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_create}
            onChange={handleChange}
            name="role_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Supplier Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.supplier_master_view}
            onChange={handleChange}
            name="supplier_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.supplier_master_edit}
            onChange={handleChange}
            name="supplier_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.supplier_master_create}
            onChange={handleChange}
            name="supplier_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Pallete Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pallete_master_view}
            onChange={handleChange}
            name="pallete_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pallete_master_edit}
            onChange={handleChange}
            name="pallete_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pallete_master_create}
            onChange={handleChange}
            name="pallete_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Party Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.party_master_view}
            onChange={handleChange}
            name="party_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.party_master_edit}
            onChange={handleChange}
            name="party_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.party_master_create}
            onChange={handleChange}
            name="party_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Unit Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.unit_master_view}
            onChange={handleChange}
            name="unit_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.unit_master_edit}
            onChange={handleChange}
            name="unit_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.unit_master_create}
            onChange={handleChange}
            name="unit_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Item Name Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_name_master_view}
            onChange={handleChange}
            name="item_name_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_name_master_edit}
            onChange={handleChange}
            name="item_name_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_name_master_create}
            onChange={handleChange}
            name="item_name_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Item Type Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_code_master_view}
            onChange={handleChange}
            name="item_code_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_code_master_edit}
            onChange={handleChange}
            name="item_code_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.item_code_master_create}
            onChange={handleChange}
            name="item_code_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Grade Master</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grade_master_view}
            onChange={handleChange}
            name="grade_master_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grade_master_edit}
            onChange={handleChange}
            name="grade_master_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grade_master_create}
            onChange={handleChange}
            name="grade_master_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}> Raw Veneer</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.inventory_view}
            onChange={handleChange}
            name="inventory_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.inventory_edit}
            onChange={handleChange}
            name="inventory_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.inventory_create}
            onChange={handleChange}
            name="inventory_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}> Other Goods</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.other_goods_view}
            onChange={handleChange}
            name="other_goods_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.other_goods_edit}
            onChange={handleChange}
            name="other_goods_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.other_goods_create}
            onChange={handleChange}
            name="other_goods_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Grouped Veneer</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouped_veneer_view}
            onChange={handleChange}
            name="grouped_veneer_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouped_veneer_edit}
            onChange={handleChange}
            name="grouped_veneer_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouped_veneer_create}
            onChange={handleChange}
            name="grouped_veneer_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Ready Sheet Form</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.ready_sheet_form_view}
            onChange={handleChange}
            name="ready_sheet_form_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.ready_sheet_form_edit}
            onChange={handleChange}
            name="ready_sheet_form_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.ready_sheet_form_create}
            onChange={handleChange}
            name="ready_sheet_form_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Ready For Dispatch Inventory</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.qc_view}
            onChange={handleChange}
            name="qc_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.qc_edit}
            onChange={handleChange}
            name="qc_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.qc_create}
            onChange={handleChange}
            name="qc_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Grouping</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouping_view}
            onChange={handleChange}
            name="grouping_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouping_edit}
            onChange={handleChange}
            name="grouping_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.grouping_create}
            onChange={handleChange}
            name="grouping_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Smoking</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.smoking_view}
            onChange={handleChange}
            name="smoking_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.smoking_edit}
            onChange={handleChange}
            name="smoking_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.smoking_create}
            onChange={handleChange}
            name="smoking_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Dying</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dying_view}
            onChange={handleChange}
            name="dying_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dying_edit}
            onChange={handleChange}
            name="dying_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dying_create}
            onChange={handleChange}
            name="dying_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Cutting</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.cutting_view}
            onChange={handleChange}
            name="cutting_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.cutting_edit}
            onChange={handleChange}
            name="cutting_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.cutting_create}
            onChange={handleChange}
            name="cutting_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Tappping</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.tapping_view}
            onChange={handleChange}
            name="tapping_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.tapping_edit}
            onChange={handleChange}
            name="tapping_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.tapping_create}
            onChange={handleChange}
            name="tapping_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Pressing</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pressing_view}
            onChange={handleChange}
            name="pressing_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pressing_edit}
            onChange={handleChange}
            name="pressing_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.pressing_create}
            onChange={handleChange}
            name="pressing_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Finishing</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.finishing_view}
            onChange={handleChange}
            name="finishing_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.finishing_edit}
            onChange={handleChange}
            name="finishing_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.finishing_create}
            onChange={handleChange}
            name="finishing_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Orders</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.orders_view}
            onChange={handleChange}
            name="orders_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.orders_edit}
            onChange={handleChange}
            name="orders_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.orders_create}
            onChange={handleChange}
            name="orders_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Dispatch</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dispatch_view}
            onChange={handleChange}
            name="dispatch_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dispatch_edit}
            onChange={handleChange}
            name="dispatch_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.dispatch_create}
            onChange={handleChange}
            name="dispatch_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ mt: 5 }}>
        <Typography variant="h5">Status</Typography>
        <Switch
          onChange={(e) => {
            setStatus(status === true ? false : true);
          }}
          defaultChecked={status === true ? true : false}
          sx={{
            p: 0,
            width: "70px",
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: status === true ? "green" : "red",
              width: "90%",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: status === true ? "green" : "red",
            },
          }}
        />
      </Div>
      <Div
        sx={{
          width: "93.5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/roles");
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ width: "100px" }}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
}
