import AllApis from "app/Apis";
import {
  ALL_CUTTING_DONE_FAIL,
  ALL_CUTTING_DONE_REQUEST,
  ALL_CUTTING_DONE_SUCCESS,
  ALL_DYED_GROUPS_FAIL,
  ALL_DYED_GROUPS_REQUEST,
  ALL_DYED_GROUPS_SUCCESS,
  ALL_DYED_INDIVIDUALS_FAIL,
  ALL_DYED_INDIVIDUALS_REQUEST,
  ALL_DYED_INDIVIDUALS_SUCCESS,
  ALL_FINISHING_DONE_FAIL,
  ALL_FINISHING_DONE_REQUEST,
  ALL_FINISHING_DONE_SUCCESS,
  ALL_ISSUED_FOR_DYING_GROUPS_FAIL,
  ALL_ISSUED_FOR_DYING_GROUPS_REQUEST,
  ALL_ISSUED_FOR_DYING_GROUPS_SUCCESS,
  ALL_ISSUED_FOR_SMOKING_GROUPS_FAIL,
  ALL_ISSUED_FOR_SMOKING_GROUPS_REQUEST,
  ALL_ISSUED_FOR_SMOKING_GROUPS_SUCCESS,
  ALL_ISSUE_FOR_CUTTING_FAIL,
  ALL_ISSUE_FOR_CUTTING_REQUEST,
  ALL_ISSUE_FOR_CUTTING_SUCCESS,
  ALL_ISSUE_FOR_FINISHING_FAIL,
  ALL_ISSUE_FOR_FINISHING_REQUEST,
  ALL_ISSUE_FOR_FINISHING_SUCCESS,
  ALL_ISSUE_FOR_PRESSING_FAIL,
  ALL_ISSUE_FOR_PRESSING_REQUEST,
  ALL_ISSUE_FOR_PRESSING_SUCCESS,
  ALL_ISSUE_FOR_TAPPING_FAIL,
  ALL_ISSUE_FOR_TAPPING_REQUEST,
  ALL_ISSUE_FOR_TAPPING_SUCCESS,
  ALL_PRESSING_DONE_FAIL,
  ALL_PRESSING_DONE_REQUEST,
  ALL_PRESSING_DONE_SUCCESS,
  ALL_SMOKED_GROUPS_FAIL,
  ALL_SMOKED_GROUPS_REQUEST,
  ALL_SMOKED_GROUPS_SUCCESS,
  ALL_SMOKED_INDIVIDUALS_FAIL,
  ALL_SMOKED_INDIVIDUALS_REQUEST,
  ALL_SMOKED_INDIVIDUALS_SUCCESS,
  ALL_TAPPING_DONE_FAIL,
  ALL_TAPPING_DONE_REQUEST,
  ALL_TAPPING_DONE_SUCCESS,
} from "app/utils/constants/factoryConstants";
import { Axios } from "index";

//cutting
export const getIssueForCuttingList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          revert_status: "active",
        },
        searchFields: {
          string: [],
          numbers: [
            "group_id.group_no",
            "group_id.group_length",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.group_pcs",
            "group_id.total_item_sqm_available",
            "cutting_issued_sqm",
            // "cutting_item_details.cutting_quantity.total"
          ],
          arrayField: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "cutting_item_details.item_name",
            "cutting_item_details.item_code",
            "cutting_item_details.cutting_quantity.total"
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUE_FOR_CUTTING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.cutting.issueForCutting}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUE_FOR_CUTTING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUE_FOR_CUTTING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getCuttingDoneList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["cutting_remarks"],
          numbers: [
            "group_history_id.group_id.group_no",
            "group_history_id.group_id.group_length",
            "group_history_id.group_id.group_width",
            "group_history_id.group_id.group_no_of_pattas_available",
            "group_history_id.group_id.group_sqm_available",
            "group_history_id.group_id.group_pcs",
            "group_history_id.group_id.total_item_sqm_available",
          ],
          arrayField: [
            "group_history_id.group_id.group_grade",
            "group_history_id.group_id.orientation",
            "group_history_id.group_id.book_type",
            "group_history_id.group_id.group_pallete_no",
            "group_history_id.group_id.group_physical_location",
            "group_history_id.group_id.book_type",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "group_history_id.cutting_item_details.item_name",
            "group_history_id.cutting_item_details.item_code",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_CUTTING_DONE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.cutting.cuttingDone}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_CUTTING_DONE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CUTTING_DONE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//smoking
export const getIssuedForSmokingGroupList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "issued for smoking",
        },
        searchFields: {
          string: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "group_id.book_type",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "group_id.item_details.item_name",
            "group_id.item_details.item_code",
          ],
          numbers: [
            "group_id.group_no",
            "group_id.group_length",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.group_pcs",
            "group_id.total_item_sqm_available",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUED_FOR_SMOKING_GROUPS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.smoking.issuedForSmokingGroups}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUED_FOR_SMOKING_GROUPS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUED_FOR_SMOKING_GROUPS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllSmokedGroups =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [],
          numbers: [
            "group_id.group_no",
            "group_id.group_length",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.group_pcs",
            "group_id.total_item_sqm_available",
            "liters_of_ammonia_used",
            "process_time",
          ],
          arrayField: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "group_id.book_type",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "item_details.item_name",
            "item_details.item_code",
            "group_smoked_remarks",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_SMOKED_GROUPS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.smoking.smokedGroups}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_SMOKED_GROUPS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SMOKED_GROUPS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllSmokedIndividuals =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_details.item_name",
            "item_details.item_code",
            "item_details.item_log_no",
            "item_details.item_grade",
            "item_details.item_pallete_no",
            "item_details.item_physical_location",
            "item_details.supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "individual_smoked_remarks",
          ],
          numbers: [
            "item_details.item_length",
            "item_details.item_width",
            "item_details.item_available_sqm",
            "item_details.item_bundle_no",
            "item_details.item_available_pattas",
            "liters_of_ammonia_used",
            "process_time",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_SMOKED_INDIVIDUALS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.smoking.smokedIndividuals}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_SMOKED_INDIVIDUALS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_SMOKED_INDIVIDUALS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// Dying
export const getIssuedForDyingGroupList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "issued for dying",
        },
        searchFields: {
          string: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "group_id.book_type",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "group_id.item_details.item_name",
            "group_id.item_details.item_code",
          ],
          numbers: [
            "group_id.group_no",
            "group_id.group_length",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.group_pcs",
            "group_id.total_item_sqm_available",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUED_FOR_DYING_GROUPS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dying.issuedForDyingGroups}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUED_FOR_DYING_GROUPS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUED_FOR_DYING_GROUPS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllDyedIndividuals =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "item_details.item_name",
            "item_details.item_code",
            "item_details.item_log_no",
            "item_details.item_grade",
            "item_details.item_pallete_no",
            "item_details.item_physical_location",
            "item_details.supplier_details.supplier_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "individual_dying_remarks",
          ],
          numbers: [
            "item_details.item_length",
            "item_details.item_width",
            "item_details.item_available_sqm",
            "item_details.item_bundle_no",
            "item_details.item_available_pattas",
            "liters_of_ammonia_used",
            "process_time",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_DYED_INDIVIDUALS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dying.dyedIndividuals}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_DYED_INDIVIDUALS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DYED_INDIVIDUALS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllDyedGroups =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["group_dying_remarks"],
          numbers: [
            "group_id.group_no",
            "group_id.group_length",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.group_pcs",
            "group_id.total_item_sqm_available",
            "liters_of_ammonia_used",
            "process_time",
          ],
          arrayField: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "group_id.book_type",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "item_details.item_name",
            "item_details.item_code",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_DYED_GROUPS_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.dying.dyedGroups}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_DYED_GROUPS_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DYED_GROUPS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// Tapping

export const getIssueForTappingList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          revert_status: "active",
        },
        searchFields: {
          string: [],
          numbers: ["group_data.group_no"],
          arrayField: [
            "created_employee_id.first_name",
            "cutting_id.cutting_id.item_details.item_data.item_name",
            "cutting_id.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUE_FOR_TAPPING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.tapping.issuedForTapping}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUE_FOR_TAPPING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUE_FOR_TAPPING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getTappingDoneList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["tapping_remarks"],
          numbers: ["group_data.group_no"],
          arrayField: [
            "created_employee_id.first_name",
            "cutting_id.cutting_id.item_details.item_data.item_name",
            "cutting_id.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_TAPPING_DONE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.tapping.TappingDone}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_TAPPING_DONE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_TAPPING_DONE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// Pressing
export const getIssueForPressingList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          revert_status: "active",
        },
        searchFields: {
          string: [],
          numbers: [
            "group_no",
            "ready_sheet_form_history_details.ready_sheet_form_length",
            "ready_sheet_form_history_details.ready_sheet_form_width",
            "ready_sheet_form_history_details.ready_sheet_form_approved_pcs",
            "ready_sheet_form_history_details.ready_sheet_form_sqm",
          ],
          arrayField: [
            "ready_sheet_form_history_details.ready_sheet_form_pallete_no",
            "ready_sheet_form_history_details.ready_sheet_form_physical_location",
            "cutting_details.cutting_id.item_details.item_data.item_name",
            "cutting_details.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUE_FOR_PRESSING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.pressing.issuedForPressing}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUE_FOR_PRESSING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUE_FOR_PRESSING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getPressingDoneList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: ["pressing_remarks"],
          numbers: [
            "group_no",
            "pressing_length",
            "pressing_width",
            "pressing_no_of_peices",
            "pressing_sqm",
          ],
          arrayField: [
            "ready_sheet_form_history_details.ready_sheet_form_pallete_no",
            "ready_sheet_form_history_details.ready_sheet_form_physical_location",
            "cutting_details.cutting_id.item_details.item_data.item_name",
            "cutting_details.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_PRESSING_DONE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.pressing.pressingDone}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_PRESSING_DONE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PRESSING_DONE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

// Finishing
export const getIssueForFinishingList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          revert_status: "active",
        },
        searchFields: {
          string: ["status"],
          numbers: [
            "group_no",
            "pressing_details.pressing_length",
            "pressing_details.pressing_width",
            "pressing_details.pressing_no_of_peices",
            "pressing_details.pressing_sqm",
            "available_pressed_pcs",
          ],
          arrayField: [
            "cutting_details.cutting_id.item_details.item_data.item_name",
            "cutting_details.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_ISSUE_FOR_FINISHING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.finishing.issuedForFinishing}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_ISSUE_FOR_FINISHING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ISSUE_FOR_FINISHING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getFinishingDoneList =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "cutting_details.cutting_id.item_details.item_data.item_name",
            "cutting_details.cutting_id.item_details.item_data.item_code",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "finishing_remarks",
          ],
          numbers: [
            "group_no",
            "finishing_length",
            "finishing_width",
            "finishing_no_of_peices",
            "finishing_sqm",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_FINISHING_DONE_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.finishing.finishingDone}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_FINISHING_DONE_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_FINISHING_DONE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
