/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import {
  Autocomplete,
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import AllApis from "app/Apis";
import { formatDate } from "app/components/Function/formatDate";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllReadyForDispatch } from "app/redux/actions/dispatchAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CuttingDetailsModal } from "../Modal/cuttingDetailsModal";
import { FinishingDetailsModal } from "../Modal/finishingDetailsModal";
import { PressingDetailsModal } from "../Modal/pressingDetailsModal";
import { ReadySheetDetailsModal } from "../Modal/readySheetFormDetailsModal";

export default function ReadyForDispatchTable({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const { allReadyForDispatch, error, TotalPage, loading } = useSelector(
    (state) => state.dispatchReducer
  );
  const [open, setOpen] = useState(false);
  const [readySheetOpen, setReadySheetOpen] = useState(false);
  const [pressingOpen, setPressingOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [finishingOpen, setFinishingOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [palleteList, setPalleteList] = useState([]);

  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllReadyForDispatch(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "revert":
        revertReadyForDispatch(menuItem?.data?._id);
        break;
    }
  };

  const revertReadyForDispatch = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Revert",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.dispatch.revertReadyForDispatch(id),
            {},
            config
          );
          if (response) {
            dispatch(getAllReadyForDispatch(searchTerm, sort, sortBy, page, filter));
            Swal.fire({
              title: "Reverted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error?.response?.data?.error,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const editReadyForDispatch = async (id, name, value) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    setIsLoading(true);
    try {
      const response = await Axios.patch(
        AllApis.dispatch.editReadyForDispatch(id),
        {
          [name]:
            (name == "ready_sheet_form_pallete_no" && value?.pallete_no) || "",
          ready_sheet_form_physical_location:
            value?.item_physical_location || "",
        },
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(
          getAllReadyForDispatch(searchTerm, sort, sortBy, page, filter)
        );
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(async () => {
    try {
      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "qc_length"}
                  direction={sort}
                  onClick={() => handleSort("qc_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Length
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "qc_width"}
                  direction={sort}
                  onClick={() => handleSort("qc_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Width
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "qc_no_of_pcs_available"}
                  direction={sort}
                  onClick={() => handleSort("qc_no_of_pcs_available")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "qc_sqm"}
                  direction={sort}
                  onClick={() => handleSort("qc_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  QC Sqm
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_pallete_no"}
                  direction={sort}
                  onClick={() => handleSort("ready_sheet_form_pallete_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pallet No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "200px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "ready_sheet_form_physical_location"}
                  direction={sort}
                  onClick={() =>
                    handleSort("ready_sheet_form_physical_location")
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Physical Location
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Finishing Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Pressing Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Ready Sheet Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                QC Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              {permissions?.qc_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allReadyForDispatch?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_name
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_code
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.qc_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.qc_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.qc_no_of_pcs_available}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.qc_sqm?.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    id="tags-standard"
                    options={palleteList || []}
                    name="ready_sheet_form_pallete_no"
                    value={row?.ready_sheet_form_pallete_no}
                    isOptionEqualToValue={(option, value) =>
                      option.pallete_no === value
                    }
                    getOptionLabel={(option) => {
                      if (option instanceof Object) {
                        return option.pallete_no;
                      } else {
                        return option;
                      }
                    }}
                    onChange={(e, newValue) => {
                      editReadyForDispatch(
                        row._id,
                        "ready_sheet_form_pallete_no",
                        newValue
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.pallete_no}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {/* <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="physical_location"
                      value={row?.item_physical_location}
                      onChange={(e) => {
                        editRawVeneer(
                          row._id,
                          "item_physical_location",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                      <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                      <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
                      <MenuItem value={"VEENEER SECTION"}>
                        VEENEER SECTION
                      </MenuItem>
                      <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
                    </Select> */}
                  <TextField
                    multiline
                    size="small"
                    value={row?.ready_sheet_form_physical_location}
                    sx={{ width: "100%", ...disabledStyling }}
                    name="ready_sheet_form_physical_location"
                    disabled
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setFinishingOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setPressingOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setReadySheetOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.cutting_details?.cutting_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
                {permissions?.qc_edit && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <SettingsBackupRestoreIcon />,
                          title: "Revert",
                          action: "revert",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CuttingDetailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {readySheetOpen && (
            <ReadySheetDetailsModal
              open={readySheetOpen}
              setOpen={setReadySheetOpen}
              rowData={rowData}
            />
          )}
          {pressingOpen && (
            <PressingDetailsModal
              open={pressingOpen}
              setOpen={setPressingOpen}
              rowData={rowData}
            />
          )}
          {finishingOpen && (
            <FinishingDetailsModal
              open={finishingOpen}
              setOpen={setFinishingOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
