import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import {
  getFinishingDoneList,
  getIssueForFinishingList,
} from "app/redux/actions/factoryAction";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FinishingDoneList from "./FinishingDoneTable";
import IssuedForFinishingTable from "./IssueForFinishingTable";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";

const FactoryFinishingList = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [groupNoList, setGroupNoList] = useState([]);
  const [itemNameList, setItemNameList] = useState([]);
  const [emptyVal, setEmptyVal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });

  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const { factoryReducer } = useSelector((state) => state);

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      dispatch(getIssueForFinishingList(search, sort, sortBy, page, filters));
    } else if (value == 1) {
      dispatch(getFinishingDoneList(search, sort, sortBy, page, filters));
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      dispatch(getIssueForFinishingList(searchTerm, sort, sortBy, "", filters));
    } else if (value == 1) {
      dispatch(getFinishingDoneList(searchTerm, sort, sortBy, "", filters));
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      dispatch(getIssueForFinishingList(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getFinishingDoneList(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    if (newValue == 0) {
      dispatch(getIssueForFinishingList("", sort, sortBy, page, {}));
    } else if (newValue == 1) {
      dispatch(getFinishingDoneList("", sort, sortBy, page, {}));
    }
  };

  const exportIssuedFinishingCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
        revert_status: "active",
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issuedForFinishing,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportFinishingDoneCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.finishingDone,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    try {
      const groupNoList = await Axios.get(`${AllApis.dropdownList.group_no}`);
      setGroupNoList(groupNoList?.data?.result);
      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name}`);
      setItemNameList(itemNameList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (value == 0) {
      dispatch(getIssueForFinishingList(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(getFinishingDoneList(searchTerm, sort, sortBy, page, {}));
    }
  }, [sort, page]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Typography variant="h1" sx={{ mt: -4 }}>
        Finishing
      </Typography>
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={groupNoList || []}
              getOptionLabel={(option) => option?.toString() || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  group_no: newValue != null ? newValue : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Group No" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              freeSolo
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={itemNameList || []}
              getOptionLabel={(option) => option?.item_name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "cutting_details.cutting_id.item_details.item_data.item_name":
                    newValue != null ? newValue?.item_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.item_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Item Name" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={
                  value == 0 ? "From Date of Issued" : "From Date of Finishing"
                }
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{
                  color: "#E73145",
                  fontSize: "0.75rem",
                  ml: 1,
                  mt: 0.5,
                }}
              >
                {value == 0
                  ? "Select From Date of Issued"
                  : "Select From Date of Finishing"}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                label={
                  value == 0 ? "To Date of Issued" : "To Date of Finishing"
                }
                sx={datepickerStyling}
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue
                      .startOf("day")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                {value == 0 ? "To Date of Issued" : "To Date of Finishing"}
              </Typography>
            )}
          </Div>
        </Box>
        <Box
          sx={{
            mt: 4,
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>
          {permissions?.finishing_view && (
            <Button
              disabled={
                (value == 0 && factoryReducer?.issueForFinising?.length == 0) ||
                (value == 1 && factoryReducer?.finishingDone?.length == 0)
              }
              variant="contained"
              onClick={async () => {
                value == 0 && exportIssuedFinishingCsv(filters);
                value == 1 && exportFinishingDoneCsv(filters);
              }}
            >
              Export CSV
            </Button>
          )}
        </Box>
      </Div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Issued For Finishing" {...a11yProps(0)} />
            <Tab label="Finishing Done" {...a11yProps(1)} />
          </Tabs>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setSearchTerm(e.target.value);
              setPage(1);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                if (value == 0) {
                  dispatch(
                    getIssueForFinishingList(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filters
                    )
                  );
                } else if (value == 1) {
                  dispatch(
                    getFinishingDoneList("", "desc", "updated_at", 1, filters)
                  );
                }
              }
            }}
            sx={{ width: 300, ml: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>

        <CustomTabPanel value={value} index={0}>
          {loaded ? (
            <IssuedForFinishingTable
              filter={filters}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loaded ? (
            <FinishingDoneList
              filter={filters}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default FactoryFinishingList;
