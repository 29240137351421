import Div from "@jumbo/shared/Div";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AllApis from "app/Apis";
import {
  getAllSmokedGroups,
  getAllSmokedIndividuals,
  getIssuedForSmokingGroupList,
} from "app/redux/actions/factoryAction";
import { getAllIssuedSmoking } from "app/redux/actions/inventoryAction";
import { supplierList } from "app/services/apis/supplierList";
import dayjs from "dayjs";
import { Axios } from "index";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IssuedForSmokingGroups from "./IssuedForSmokingGroupList";
import IssuedForSmokingIndividual from "./IssuedForSmokingIndividual";
import SmokedGroupsTable from "./groupedSmoked";
import SmokedIndividualsTable from "./individualSmoked";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";

const FactorySmokingList = () => {
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("updated_at");
  const [itemNameList, setItemNameList] = useState([]);
  const [itemCodeList, setItemCodeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);
  const [emptyVal, setEmptyVal] = useState();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [addGroup, setAddGroup] = useState([]);
  const [groupNoList, setGroupNoList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const { factoryReducer, inventoryReducer } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("updated_at");
    setSearchTerm("");
  };

  const filterFun = (type) => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        if (type == "item_id" || type == "group_id" || type == "item_details") {
          filter[`${[type]}.${i}`] = filters[i];
        } else {
          filter[`${i}`] = filters[i];
        }
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };

  const handleSearch = (search) => {
    setPage(1);
    if (value == 0) {
      dispatch(
        getAllIssuedSmoking(search, sort, sortBy, page, filterFun("item_id"))
      );
    } else if (value == 1) {
      dispatch(
        getIssuedForSmokingGroupList(
          search,
          sort,
          sortBy,
          page,
          filterFun("group_id")
        )
      );
    } else if (value == 2) {
      dispatch(
        getAllSmokedIndividuals(
          search,
          sort,
          sortBy,
          page,
          filterFun("item_details")
        )
      );
    } else if (value == 3) {
      dispatch(
        getAllSmokedGroups(search, sort, sortBy, page, filterFun("group_id"))
      );
    }
    setLoaded(true);
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  const exportIssuedSmokeIndividualCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
        status: "issued for smoking",
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issueForSmoking,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportIssuedSmokeGroupCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.issuedForSmokingGroups,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const exportSmokedIndividualCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.smokedIndividual,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const exportSmokedGroupCsv = async (filter) => {
    const body = {
      filters: {
        ...filter,
      },
    };
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        AllApis.reports.smokedGrouped,
        body,
        config
      );
      if (response?.status == 200) {
        window.open(response?.data?.result);
        Swal.fire({
          title: "Downloaded",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (searchTerm !== "") {
  //     debouncedHandleSearch(searchTerm);
  //   }
  //   return () => {
  //     debouncedHandleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    //to deal with search lagging
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    } else {
      setLoaded(true);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleFilter = () => {
    setPage(1);
    if (value == 0) {
      dispatch(
        getAllIssuedSmoking(searchTerm, sort, sortBy, "", filterFun("item_id"))
      );
    } else if (value == 1) {
      dispatch(
        getIssuedForSmokingGroupList(
          searchTerm,
          sort,
          sortBy,
          "",
          filterFun("group_id")
        )
      );
    } else if (value == 2) {
      dispatch(
        getAllSmokedIndividuals(
          searchTerm,
          sort,
          sortBy,
          "",
          filterFun("item_details")
        )
      );
    } else if (value == 3) {
      dispatch(
        getAllSmokedGroups(searchTerm, sort, sortBy, "", filterFun("group_id"))
      );
    }
  };
  const handleClear = () => {
    TabChange();
    if (value == 0) {
      dispatch(getAllIssuedSmoking(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(
        getIssuedForSmokingGroupList(searchTerm, sort, sortBy, page, {})
      );
    } else if (value == 2) {
      dispatch(getAllSmokedIndividuals(searchTerm, sort, sortBy, page, {}));
    } else if (value == 3) {
      dispatch(getAllSmokedGroups(searchTerm, sort, sortBy, page, {}));
    }
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    TabChange();
    setAddGroup([]);
    if (newValue == 0) {
      dispatch(getAllIssuedSmoking("", sort, sortBy, page, {}));
    } else if (newValue == 1) {
      dispatch(getIssuedForSmokingGroupList("", sort, sortBy, page, {}));
    } else if (newValue == 2) {
      dispatch(getAllSmokedIndividuals("", sort, sortBy, page, {}));
    } else if (newValue == 3) {
      dispatch(getAllSmokedGroups("", sort, sortBy, page, {}));
    }
  };

  useEffect(async () => {
    try {
      const data = await supplierList();
      if (data.data.status == true) {
        setSupplierNameList(data.data.result);
      }
      const groupNoList = await Axios.get(`${AllApis.dropdownList.group_no}`);
      setGroupNoList(groupNoList?.data?.result);

      const itemNameList = await Axios.get(`${AllApis.dropdownList.item_name}`);
      setItemNameList(itemNameList?.data?.result);

      const itemCodeList = await Axios.get(`${AllApis.dropdownList.item_code}`);
      setItemCodeList(itemCodeList?.data?.result);

      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);

      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (value == 0) {
      dispatch(getAllIssuedSmoking(searchTerm, sort, sortBy, page, {}));
    } else if (value == 1) {
      dispatch(
        getIssuedForSmokingGroupList(searchTerm, sort, sortBy, page, {})
      );
    } else if (value == 2) {
      dispatch(getAllSmokedIndividuals(searchTerm, sort, sortBy, page, {}));
    } else if (value == 3) {
      dispatch(getAllSmokedGroups(searchTerm, sort, sortBy, page, {}));
    }
  }, [sort, page]);

  useEffect(() => {
    //to deal with search input lagging
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    // Clean-up function to clear the timer in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <Typography variant="h1" sx={{ mt: -4 }}>
        Smoking
      </Typography>
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        {value == 0 || value == 2 ? (
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={supplierNameList || []}
                getOptionLabel={(option) => option?.supplier_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "supplier_details.supplier_name":
                      newValue != null ? newValue?.supplier_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.supplier_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Supplier Name"
                  />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={itemNameList || []}
                getOptionLabel={(option) => option?.item_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_name: newValue != null ? newValue?.item_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.item_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Item Name" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={itemCodeList || []}
                getOptionLabel={(option) => option?.item_code || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_code: newValue != null ? newValue?.item_code : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.item_code}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Item Type" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={gradeList || []}
                getOptionLabel={(option) => option?.grade_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_grade: newValue != null ? newValue?.grade_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.grade_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Grade" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={palleteList || []}
                getOptionLabel={(option) => option?.pallete_no || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    item_pallete_no:
                      newValue != null ? newValue?.pallete_no : "",
                    item_physical_location:
                      newValue != null ? newValue?.item_physical_location : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.pallete_no}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Pallet No" />
                )}
              />
            </Div>
            <FormControl sx={{ width: "23%" }} size="small">
              {/* <InputLabel id="demo-select-small-label">
                Physical Location
              </InputLabel>
              <Select
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%" }}
                size="small"
                labelId="demo-select-small-label"
                id="demo-simple-select"
                label="Physical Location"
                name="physical_location"
                defaultValue={""}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    item_physical_location: e.target.value
                      ? e.target.value
                      : "",
                  });
                }}
              >
                <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
              <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
              <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
              <MenuItem value={"VENEER SECTION"}>VENEER SECTION</MenuItem>
              <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
              </Select> */}
              {(filters?.item_physical_location == "" ||
                filters?.item_physical_location == undefined) && (
                <InputLabel id="demo-select-small-label">
                  Physical Location
                </InputLabel>
              )}
              <TextField
                multiline
                size="small"
                label={
                  (filters?.item_physical_location != "" ||
                    filters?.item_physical_location != undefined) &&
                  "Physical Location"
                }
                value={filters?.item_physical_location || ""}
                sx={{ width: "100%", ...disabledStyling }}
                name="item_physical_location"
                disabled
                inputProps={{ shrink: true }}
                InputLabelProps={{
                  style: { color: "#98A3AA" }, // Change the color of the label here
                }}
              />
            </FormControl>

            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  label={
                    value == 0 || value == 1
                      ? "From Issued Date"
                      : "From Smoked Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  }
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <Typography
                  sx={{
                    color: "#E73145",
                    fontSize: "0.75rem",
                    ml: 1,
                    mt: 0.5,
                  }}
                >
                  {value == 0 || value == 1
                    ? "Select From Issued Date"
                    : "Select From Smoked Date"}
                </Typography>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label={
                    value == 0 || value == 1
                      ? "To Issued Date"
                      : "To Smoked Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <Typography
                  sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
                >
                  {value == 0 || value == 1
                    ? "To Issued Date"
                    : "To Smoked Date"}
                </Typography>
              )}
            </Div>
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={groupNoList || []}
                getOptionLabel={(option) => option?.toString() || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    group_no: newValue != null ? newValue : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Group No" />
                )}
              />
            </Div>

            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={gradeList || []}
                getOptionLabel={(option) => option?.grade_name || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    group_grade: newValue != null ? newValue?.grade_name : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.grade_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Grade" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={palleteList || []}
                getOptionLabel={(option) => option?.pallete_no || ""}
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    group_pallete_no:
                      newValue != null ? newValue?.pallete_no : "",
                    group_physical_location:
                      newValue != null ? newValue?.item_physical_location : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.pallete_no}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Pallet No" />
                )}
              />
            </Div>
            <FormControl sx={{ width: "23%" }} size="small">
              {/* <InputLabel id="demo-select-small-label">
                Physical Location
              </InputLabel>
              <Select
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%" }}
                size="small"
                labelId="demo-select-small-label"
                id="demo-simple-select"
                label="Physical Location"
                name="physical_location"
                defaultValue={""}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    group_physical_location: e.target.value
                      ? e.target.value
                      : "",
                  });
                }}
              >
                <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                <MenuItem value={"TEXTURE GODOWN"}>TEXTURE GODOWN</MenuItem>
                <MenuItem value={"VEENEER SECTION"}>VEENEER SECTION</MenuItem>
                <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
              </Select> */}
              {(filters?.group_physical_location == "" ||
                filters?.group_physical_location == undefined) && (
                <InputLabel id="demo-select-small-label">
                  Physical Location
                </InputLabel>
              )}
              <TextField
                multiline
                size="small"
                label={
                  (filters?.group_physical_location != "" ||
                    filters?.group_physical_location != undefined) &&
                  "Physical Location"
                }
                value={filters?.group_physical_location || ""}
                sx={{ width: "100%", ...disabledStyling }}
                name="group_physical_location"
                disabled
                inputProps={{ shrink: true }}
                InputLabelProps={{
                  style: { color: "#98A3AA" }, // Change the color of the label here
                }}
              />
            </FormControl>

            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  label={
                    value == 0 || value == 1
                      ? " From Issued Date"
                      : " From Smoked Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  }
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <Typography
                  sx={{
                    color: "#E73145",
                    fontSize: "0.75rem",
                    ml: 1,
                    mt: 0.5,
                  }}
                >
                  {value == 0 || value == 1
                    ? "Select From Issued Date"
                    : "Select From Smoked Date"}
                </Typography>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label={
                    value == 0 || value == 1
                      ? " To Issued Date"
                      : " To Smoked Date"
                  }
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <Typography
                  sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
                >
                  {value == 0 || value == 1
                    ? "Select To Issued Date"
                    : "Select To Smoked Date"}
                </Typography>
              )}
            </Div>
          </Box>
        )}
        <Box
          sx={{
            mt: 4,
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Div>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Div>
          {permissions.smoking_view && (
            <Button
              disabled={
                (value == 0 && inventoryReducer?.issuedSmoking?.length == 0) ||
                (value == 1 &&
                  factoryReducer?.issuedForSmokingGroups?.length == 0) ||
                (value == 2 &&
                  factoryReducer?.smokedIndividuals?.length == 0) ||
                (value == 3 && factoryReducer?.smokedGroups?.length == 0)
              }
              variant="contained"
              onClick={async () => {
                value == 0 &&
                  exportIssuedSmokeIndividualCsv(filterFun("item_id"));
                value == 1 && exportIssuedSmokeGroupCsv(filterFun("group_id"));
                value == 2 &&
                  exportSmokedIndividualCsv(filterFun("item_details"));
                value == 3 && exportSmokedGroupCsv(filterFun("group_id"));
              }}
            >
              Export CSV
            </Button>
          )}
        </Box>
      </Div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Issued For Smoking Individual" {...a11yProps(0)} />
            <Tab label="Issued For Smoking Group" {...a11yProps(1)} />
            <Tab label="Smoked Individual" {...a11yProps(2)} />
            <Tab label="Smoked Grouped" {...a11yProps(3)} />
          </Tabs>
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setLoaded(false);
              setSearchTerm(e.target.value);
              setPage(1);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("updated_at");
                if (value == 0) {
                  dispatch(
                    getAllIssuedSmoking(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("item_id")
                    )
                  );
                } else if (value == 1) {
                  dispatch(
                    getIssuedForSmokingGroupList(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("group_id")
                    )
                  );
                } else if (value == 2) {
                  dispatch(
                    getAllSmokedIndividuals(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("item_details")
                    )
                  );
                } else if (value == 3) {
                  dispatch(
                    getAllSmokedGroups(
                      "",
                      "desc",
                      "updated_at",
                      1,
                      filterFun("group_id")
                    )
                  );
                }
              }
            }}
            sx={{ width: 300, ml: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loaded ? (
            <IssuedForSmokingIndividual
              filter={filterFun("item_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loaded ? (
            <IssuedForSmokingGroups
              filter={filterFun("group_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
              addGroup={addGroup}
              setAddGroup={setAddGroup}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {loaded ? (
            <SmokedIndividualsTable
              filter={filterFun("item_details")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {loaded ? (
            <SmokedGroupsTable
              filter={filterFun("group_id")}
              page={page}
              setPage={setPage}
              searchTerm={searchTerm}
              sort={sort}
              setSort={setSort}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          ) : (
            <FullScreenLoader />
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default FactorySmokingList;
