import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import AllApis from "app/Apis";
import { getPendingGroupOrderList } from "app/redux/actions/orderAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./popup.css";

export const PendingGroupItemDetailsModal = ({ open, setOpen, rowData }) => {
  const { pendingGroupOrder } = useSelector((state) => state.orderReducer);
  const [row, setRow] = useState(rowData);

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const theme = useTheme();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleStatus = async (e, i, data) => {
    try {
      const data = row?.group_order_details;
      data[i].order_status = e.target.value;
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const response = await Axios.patch(
        AllApis.order.edit(row?._id),
        { group_order_details: data },
        config
      );
      if (response.status == 200) {
        Toast.fire({
          timer: 1000,
          icon: "success",
          title: "Status Updated",
          background: theme.palette.background.paper,
        });
        dispatch(getPendingGroupOrderList("", "desc", "updated_at", 1, {}));
      }
    } catch (error) {}
  };
  useEffect(() => {
    const filterData = pendingGroupOrder.filter((ele) => ele._id === row._id);
    if (filterData?.length > 0) {
      setRow(filterData[0]);
    } else {
      setOpen(false);
    }
  }, [pendingGroupOrder]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Order Group Details (Order No : {rowData?.order_no})
        </Typography>
        <TableContainer component={Paper}>
          <Table border={2}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Group No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "350px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "150px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Pcs
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Pcs
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Pcs
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sqm
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Total Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "300px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Remark
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row?.group_order_details?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_group_no ? row?.order_group_no : "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_required_pcs}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_dispatched_pcs_qty}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_balance_pcs_qty}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_required_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.total_order_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_remarks || "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
