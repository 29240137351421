import {
  ALL_COMPLETE_GROUP_ORDERS_FAIL,
  ALL_COMPLETE_GROUP_ORDERS_REQUEST,
  ALL_COMPLETE_GROUP_ORDERS_SUCCESS,
  ALL_COMPLETE_RAW_ORDERS_FAIL,
  ALL_COMPLETE_RAW_ORDERS_REQUEST,
  ALL_COMPLETE_RAW_ORDERS_SUCCESS,
  ALL_PENDING_GROUP_ORDERS_FAIL,
  ALL_PENDING_GROUP_ORDERS_REQUEST,
  ALL_PENDING_GROUP_ORDERS_SUCCESS,
  ALL_PENDING_RAW_ORDERS_FAIL,
  ALL_PENDING_RAW_ORDERS_REQUEST,
  ALL_PENDING_RAW_ORDERS_SUCCESS,
} from "app/utils/constants/ordersConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  pendingRawOrder: [],
  pendingGroupOrder: [],
  completeRawOrder: [],
  completeGroupOrder: [],
  TotalPage: null,
};

export const orderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //pending raw orders
    case ALL_PENDING_RAW_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PENDING_RAW_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingRawOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PENDING_RAW_ORDERS_FAIL:
      return {
        loading: false,
        pendingRawOrder: [],
        error: action.payload,
      };
    //pending group orders
    case ALL_PENDING_GROUP_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PENDING_GROUP_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingGroupOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PENDING_GROUP_ORDERS_FAIL:
      return {
        loading: false,
        pendingGroupOrder: [],
        error: action.payload,
      };
    //complete raw orders
    case ALL_COMPLETE_RAW_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_COMPLETE_RAW_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        completeRawOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_COMPLETE_RAW_ORDERS_FAIL:
      return {
        loading: false,
        completeRawOrder: [],
        error: action.payload,
      };

    //complete group orders
    case ALL_COMPLETE_GROUP_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_COMPLETE_GROUP_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        completeGroupOrder: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_COMPLETE_GROUP_ORDERS_FAIL:
      return {
        loading: false,
        completeGroupOrder: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
