import Div from "@jumbo/shared/Div/Div";
import InfoIcon from "@mui/icons-material/Info";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import { getCurrentDate } from "app/components/Function/getCurrentDate";
import { RawInventoryModal } from "app/components/Modal/rawInventoryModal";
import ImageUpload from "app/components/UploadImages";
import { Axios } from "index";

export default function CreateGroup() {
  const [loaded, setLoaded] = useState(false);
  const [groupNo, setGroupNo] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [palleteList, setPalleteList] = useState([]);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [selectedBundles, setSelectedBundles] = useState(state);
  const [other_details, setOtherDetails] = useState(state);
  const createGroup = {
    item_details: [],
    group_images: [],
    total_item_sqm_original: "",
    group_length: "",
    group_width: "",
    group_no_of_pattas_original: "",
    group_sqm_available: "",
    group_grade: "",
    orientation: "",
    book_type: "",
    group_pallete_no: state?.[0]?.item_pallete_no,
    group_physical_location: state?.[0]?.item_physical_location,
    date_of_grouping: getCurrentDate(),
    group_pcs: "",
  };

  const validationSchema = yup.object().shape({
    item_details: yup.array().of(yup.string()), // Assuming item_details is an array of strings
    // group_images: yup
    //   .array()
    //   .of(yup.string())
    //   .test(
    //     "at-least-one-item",
    //     "Upload Images",
    //     (value) => value && value.length > 0 && value.length <= 8
    //   ),
    group_grade: yup.string().required("Group grade is required"),
    orientation: yup.string().required("Orientation is required"),
    group_length: yup
      .number()
      .typeError("Length must be a number")
      .required("Length is required"),
    group_width: yup
      .number()
      .typeError("Width must be a number")
      .required("Width is required"),
    group_pcs: yup
      .number()
      .typeError("Pieces must be a number")
      .required("No of Pcs is required"),
    group_sqm_available: yup
      .number()
      .typeError("Group Sqm must be a number")
      .required("Group Sqm is required"),
    book_type: yup.string().required("Formation Type is required"),
    group_pallete_no: yup.string().required("Group palette number is required"),
    group_physical_location: yup
      .string()
      .required("Group physical location is required"),
    date_of_grouping: yup.date().required("Date of grouping is required"),
  });
  const handleSubmit = async (values) => {
    const value = {
      ...values,
      ...other_details,
      item_details: selectedBundles.map((ele) => ele._id),
      group_no: groupNo,
    };
    const form = new FormData();
    Object.entries(value).forEach((entry) => {
      if (entry[0] == "group_images") {
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] == "item_details") {
        form.append(entry[0], JSON.stringify(entry[1]));
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.grouping.addGrouping,
        form,
        config
      );
      if (response?.data?.status) {
        Swal.fire({
          title: "Group Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/grouping");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const removeRow = (index, setFieldValue, values) => {
    const updateData = [...selectedBundles];
    const sqm = parseFloat(
      ((values.group_length || 0) *
        (values.group_width || 0) *
        (other_details?.group_no_of_pattas_original -
          updateData[index].item_available_pattas)) /
        10000
    ).toFixed(2);
    setFieldValue("group_sqm_available", sqm);
    updateData.splice(index, 1);
    setSelectedBundles(updateData);
  };

  const calculateSqm = (e, setFieldValue, data) => {
    const { name, value } = e.target;
    if (name == "group_length") {
      const sqm = parseFloat(
        (value * (data.group_width || 0) * data?.group_pcs) / 10000
      ).toFixed(2);
      setFieldValue("group_sqm_available", sqm);
    } else if (name == "group_width") {
      const sqm = parseFloat(
        (value * (data.group_length || 0) * data?.group_pcs) / 10000
      ).toFixed(2);
      setFieldValue("group_sqm_available", sqm);
    } else {
      const sqm = parseFloat(
        ((data.group_length || 0) * (data.group_width || 0) * value) / 10000
      ).toFixed(2);
      setFieldValue("group_sqm_available", sqm);
    }
    setFieldValue([name], value);
  };
  useEffect(async () => {
    try {
      const gradeList = await Axios.get(`${AllApis.dropdownList.grade}`);
      setGradeList(gradeList?.data?.result);
      const palleteList = await Axios.get(`${AllApis.dropdownList.pallete}`);
      setPalleteList(palleteList?.data?.result);
    } catch (error) {}
  }, []);

  useEffect(() => {
    const obj = {
      // group_length: 0,
      // group_width: 0,
      group_no_of_pattas_original: 0,
      group_no_of_pattas_available: 0,
      // group_sqm_available: 0,
      total_item_sqm_original: 0,
      total_item_sqm_available: 0,
    };
    const updatedOtherDetails = selectedBundles.reduce((acc, ele, i) => {
      // Assuming ele is an object with properties like group_length, group_width, etc.
      // acc.group_length += ele.item_length;
      // acc.group_width += ele.item_width;
      acc.group_no_of_pattas_original += ele.item_available_pattas;
      acc.group_no_of_pattas_available += ele.item_available_pattas;
      // acc.group_sqm_available += ele.item_available_sqm;
      acc.total_item_sqm_original += ele.item_available_sqm;
      acc.total_item_sqm_available += ele.item_available_sqm;

      return acc;
    }, obj);
    updatedOtherDetails.total_item_sqm_original = parseFloat(
      updatedOtherDetails.total_item_sqm_original.toFixed(2)
    );
    updatedOtherDetails.total_item_sqm_available = parseFloat(
      updatedOtherDetails.total_item_sqm_available.toFixed(2)
    );
    setOtherDetails(updatedOtherDetails);
  }, [selectedBundles]);

  useEffect(async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const groupNo = await Axios.get(
      `${AllApis.grouping.latestGroupNo}`,
      config
    );
    setGroupNo(groupNo?.data?.latestGroupNo);
  }, [refresh]);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/dashboard/grouping/edit" ? "Edit Group" : "Create Group"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={createGroup}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, initialValues }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "145px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Name
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Item Type
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Log No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "108px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Bundle No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "90px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Length
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "70px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Width
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "105px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          No. Of Pattas
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "130px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Item Sqm
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "75px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Grade
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "110px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Pallet No
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "155px",
                            verticalAlign: "middle",
                            color: "white",
                            px: 1,
                          }}
                        >
                          Physical Location
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "85px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Details
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "95px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Supplier
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            minWidth: "120px",
                            verticalAlign: "middle",
                            px: 1,
                            color: "white",
                          }}
                        >
                          Remove
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedBundles?.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_code}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_log_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_bundle_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_length}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_width}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_available_pattas}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_available_sqm}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_grade}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_pallete_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.item_physical_location}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 2 }}>
                            <Div
                              onClick={() => {
                                setOpen(true);
                                setRowData(row);
                              }}
                            >
                              <InfoIcon sx={{ cursor: "pointer" }} />
                            </Div>
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            {row?.supplier_details?.supplier_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left", px: 1 }}>
                            <RemoveCircleOutlineIcon
                              sx={{ color: "red", ml: 2, cursor: "pointer" }}
                              onClick={() =>
                                selectedBundles.length > 1 &&
                                removeRow(i, setFieldValue, values)
                              }
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.created_employee_id?.first_name}{" "}
                            {row?.created_employee_id?.last_name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Div>
              <Div sx={{ mt: 4, width: "100%" }}>
                <Div sx={{ width: "100%", display: "flex", columnGap: 3 }}>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Date of Grouping*</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD-MM-YYYY"
                        maxDate={dayjs()}
                        defaultValue={
                          values?.date_of_grouping !== ""
                            ? dayjs(values?.date_of_grouping)
                            : null
                        }
                        onChange={(newValue) => {
                          setFieldValue(
                            "date_of_grouping",
                            newValue
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                        }}
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="date_of_grouping"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                  <Div
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <Div sx={{ width: "90%" }}>
                      <Typography variant="h5">Group No.</Typography>
                      <TextField
                        disabled
                        sx={{ width: "100%", ...disabledStyling }}
                        size="small"
                        value={groupNo}
                        name="group_no"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="group_no"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </Div>

                    <RefreshIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setRefresh(!refresh)}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">No. of Pattas</Typography>
                    <TextField
                      disabled
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={other_details?.group_no_of_pattas_original}
                      name="group_no_of_pattas_original"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_no_of_pattas_original"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>

                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    columnGap: 3,
                    flexWrap: "wrap",
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Length*</Typography>
                    <TextField
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (isNaN(inputValue) || inputValue < 0) {
                          e.preventDefault();
                        }
                      }}
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={values?.group_length}
                      name="group_length"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_length"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Width*</Typography>
                    <TextField
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);
                        if (isNaN(inputValue) || inputValue < 0) {
                          e.preventDefault();
                        }
                      }}
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={values?.group_width}
                      name="group_width"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_width"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">No. of Pcs*</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      type="number"
                      size="small"
                      value={values?.group_pcs}
                      name="group_pcs"
                      onChange={(e) => calculateSqm(e, setFieldValue, values)}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_pcs"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Group sqm</Typography>
                    <TextField
                      sx={{ width: "100%", ...disabledStyling }}
                      disabled
                      size="small"
                      value={values?.group_sqm_available}
                      name="group_sqm_available"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_sqm_available"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Total Item Sqm</Typography>
                    <TextField
                      disabled
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      value={other_details?.total_item_sqm_original}
                      name="total_item_sqm_original"
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="total_item_sqm_original"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Grade*</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={gradeList || []}
                      value={values?.group_grade}
                      name="group_grade"
                      isOptionEqualToValue={(option, value) =>
                        option.grade_name === value
                      }
                      getOptionLabel={(option) => {
                        if (option instanceof Object) {
                          return option.grade_name;
                        } else {
                          return option;
                        }
                      }}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setFieldValue("group_grade", newValue?.grade_name);
                        } else {
                          setFieldValue("group_grade", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.grade_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_grade"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Pallet No.*</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={palleteList || []}
                      name="group_pallete_no"
                      value={values?.group_pallete_no}
                      isOptionEqualToValue={(option, value) =>
                        option.pallete_no === value
                      }
                      getOptionLabel={(option) => {
                    
                        if (option instanceof Object) {
                          return option.pallete_no;
                        } else {
                          return option;
                        }
                      }}
                      onChange={(e, newValue) => {
                        if (newValue != null) {
                          setFieldValue(
                            "group_pallete_no",
                            newValue?.pallete_no
                          );
                          setFieldValue(
                            "group_physical_location",
                            newValue?.item_physical_location
                          );
                        } else {
                          setFieldValue("group_pallete_no", "");
                          setFieldValue("group_physical_location", "");
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.pallete_no}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                        />
                      )}
                    />
                    <Div sx={{ height: "30px" }}>
                      <ErrorMessage
                        name="group_pallete_no"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Physical Location</Typography>
                    {/* <Select
                      disabled
                      sx={{ width: "100%", ...disabledStyling }}
                      size="small"
                      name="group_physical_location"
                      defaultValue={values?.group_physical_location}
                      onChange={(e) => {
                        setFieldValue(
                          "group_physical_location",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value={"TERRACE"}>TERRACE</MenuItem>
                      <MenuItem value={"ROLL GODOWN"}>ROLL GODOWN</MenuItem>
                      <MenuItem value={"TEXTURE GODOWN"}>
                        TEXTURE GODOWN
                      </MenuItem>
                      <MenuItem value={"VEENEER SECTION"}>
                        VEENEER SECTION
                      </MenuItem>
                      <MenuItem value={"GODWON-1"}>GODWON-1</MenuItem>
                    </Select> */}
                    <TextField
                      multiline
                      size="small"
                      value={values?.group_physical_location}
                      sx={{ width: "100%", ...disabledStyling }}
                      name="group_physical_location"
                      disabled
                    />
                    <ErrorMessage
                      name="group_physical_location"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Orientation*</Typography>
                    <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="orientation"
                      defaultValue={values?.orientation}
                      onChange={(e) => {
                        setFieldValue("orientation", e.target.value);
                      }}
                    >
                      <MenuItem value={"horizontal"}>Horizontal</MenuItem>
                      <MenuItem value={"vertical"}>Vertical</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="orientation"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Formation Type*</Typography>
                    <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="book_type"
                      defaultValue={values?.book_type}
                      onChange={(e) => {
                        setFieldValue("book_type", e.target.value);
                      }}
                    >
                      <MenuItem value={"book match"}>Book Match</MenuItem>
                      <MenuItem value={"slip match"}>Slip Match</MenuItem>
                      <MenuItem value={"mix match"}>Mix Match</MenuItem>
                      <MenuItem value={"head tail"}>Head Tail</MenuItem>
                    </Select>
                    <ErrorMessage
                      name="book_type"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "41.5%" }}>
                    <Typography variant="h5">Remark</Typography>
                    <TextField
                      multiline
                      size="small"
                      value={values?.group_remarks}
                      sx={{ width: "100%" }}
                      name="group_remarks"
                      onChange={(e) =>
                        setFieldValue("group_remarks", e.target.value)
                      }
                    />
                  </Div>
                </Div>
                <Div sx={{ width: "50%", mt: 3 }}>
                  <Div sx={{ width: "100%" }}>
                    <ImageUpload
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"group_images"}
                    />
                    <Div sx={{ height: "30px", mt: -2, textAlign: "center" }}>
                      <ErrorMessage
                        name="group_images"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
              {open && (
                <RawInventoryModal
                  open={open}
                  setOpen={setOpen}
                  rowData={rowData}
                />
              )}
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/grouping");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  Create Group
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
