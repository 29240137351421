import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

export const DispatchedRawItemDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Dispatched Details (Order No : {rowData?.order_details?.order_no})
        </Typography>
        <TableContainer component={Paper}>
          <Table border={2.5}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "350px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "150px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Type
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Dyeds
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Smokes
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Total
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sqm
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Total Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "300px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Remark
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.raw_dispatch_details?.map((ele, index) =>
                ele?.dispatch.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {ele?.item_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.raw_details?.item_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.raw_details?.item_code}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.natural}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.dyed}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.smoked}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.total}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.raw_details?.item_length}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.raw_details?.item_width}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.dispatch_sqm}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {
                        rowData?.order_details?.raw_order_details.filter(
                          (orderData) => orderData?.item_no == ele?.item_no
                        )?.[0]?.order_rate
                      }
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.total_item_amount}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {ele?.item_remarks || "-"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
