/* eslint-disable default-case */
import Div from "@jumbo/shared/Div";
import InfoIcon from "@mui/icons-material/Info";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatDate } from "app/components/Function/formatDate";
import { getPressingDoneList } from "app/redux/actions/factoryAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CuttingDetailsModal } from "../Modal/cuttingDetailsModal";
import { ReadySheetDetailsModal } from "../Modal/readySheetFormDetailsModal";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function PressingDoneList({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pressingDone, error, TotalPage, loading } = useSelector(
    (state) => state.factoryReducer
  );
  const [open, setOpen] = useState(false);
  const [readySheetOpen, setReadySheetOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [createModal, setCreateModal] = useState(false);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getPressingDoneList(searchTerm, sort, sortBy, newPage, filter));
  };


  return (
    <>
      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={sortBy === "group_no"}
                  direction={sort}
                  onClick={() => handleSort("group_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Group No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_name"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_name"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "110px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={
                    sortBy ===
                    "cutting_details.cutting_id.item_details.item_data.item_code"
                  }
                  direction={sort}
                  onClick={() =>
                    handleSort(
                      "cutting_details.cutting_id.item_details.item_data.item_code"
                    )
                  }
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_length"}
                  direction={sort}
                  onClick={() => handleSort("pressing_length")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Length
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_width"}
                  direction={sort}
                  onClick={() => handleSort("pressing_width")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Width
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_no_of_peices"}
                  direction={sort}
                  onClick={() => handleSort("pressing_no_of_peices")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Pcs
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "pressing_sqm"}
                  direction={sort}
                  onClick={() => handleSort("pressing_sqm")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Pressing Sqm
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Ready Sheet Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Cutting Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Pressing Done Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "180px",
                  verticalAlign: "middle",
                  color: "white",
                  px: 1,
                }}
              >
                Remark
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "115px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pressingDone?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.ready_sheet_form_history_details?.group_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_name
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {
                    row?.cutting_details?.cutting_id?.[0]?.item_details
                      ?.item_data?.[0]?.item_code
                  }
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.pressing_length}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.pressing_width}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.pressing_no_of_peices}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.pressing_sqm}
                </TableCell>

                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setReadySheetOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.cutting_details?.cutting_id);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {formatDate(row?.created_at)}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.pressing_remarks ? row?.pressing_remarks : "-"}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CuttingDetailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {readySheetOpen && (
            <ReadySheetDetailsModal
              open={readySheetOpen}
              setOpen={setReadySheetOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
