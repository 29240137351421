import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Dialog,
  DialogContent,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import AllApis from "app/Apis";
import { getPendingRawOrderList } from "app/redux/actions/orderAction";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./popup.css";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

export const PendingRawItemDetailsModal = ({ open, setOpen, rowData }) => {
  const { pendingRawOrder } = useSelector((state) => state.orderReducer);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );
  const [row, setRow] = useState(rowData);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const Swal = useSwalWrapper();
  const theme = useTheme();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    customClass: {
      container: "popupImportant",
    },
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleStatus = async (e, i, data) => {
    try {
      const data = row?.raw_order_details;
      data[i].order_status = e.target.value;
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const response = await Axios.patch(
        AllApis.order.edit(row?._id),
        { raw_order_details: data },
        config
      );
      if (response.status == 200) {
        Toast.fire({
          timer: 1000,
          icon: "success",
          title: "Status Updated",
          background: theme.palette.background.paper,
        });
        dispatch(getPendingRawOrderList("", "desc", "updated_at", 1, {}));
      }
    } catch (error) {}
  };
  useEffect(() => {
    const filterData = pendingRawOrder.filter((ele) => ele._id === row._id);
    if (filterData?.length > 0) {
      setRow(filterData[0]);
    } else {
      setOpen(false);
    }
  }, [pendingRawOrder]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Order Raw Details (Order No : {rowData?.order_no})
        </Typography>
        <TableContainer component={Paper}>
          <Table border={2.5}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "350px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "150px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Item Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Dyed
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Required Smoked
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Dyed
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Smoked
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total Dispatched
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Naturals
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Dyed
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Balance Smoked
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "110px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Total Balance
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sqm
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Total Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "300px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Remark
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "120px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row?.raw_order_details?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_item_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.natural || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.dyed || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.smoked || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.required_quantity?.total || 0}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.natural || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.dyed || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.smoked || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.dispatched_quantity?.total || 0}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.natural || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.dyed || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.smoked || 0}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.balance_quantity?.total || 0}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.total_order_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.item_remarks || "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.order_status == "closed" ? (
                      <Typography variant="h5">Closed</Typography>
                    ) : (
                      <Select
                        sx={{ ...disabledStyling }}
                        disabled={!permissions?.orders_edit}
                        value={row?.order_status}
                        onChange={(e) => handleStatus(e, i)}
                        size="small"
                      >
                        <MenuItem value={"open"}>Open</MenuItem>
                        <MenuItem value={"closed"}>Close</MenuItem>
                      </Select>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};
