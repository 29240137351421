import Page from "@jumbo/shared/Page";
import groupedVeneerMiddleware from "./middleware/auth/groupedVeneerValidRoute";
import GroupedVeneerList from "app/pages/Inventory/GroupedVeneer";

export const groupedVeneerRoutes = [
  {
    middleware: [
      {
        element: groupedVeneerMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/inventory/groupedveneer",
        element: <Page component={GroupedVeneerList} layout={"vertical-default"} />,
      },
    ],
  },
];
