import Div from "@jumbo/shared/Div";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis";
import { Axios } from "index";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./popup.css";

export const FetchGroupNoQtyModal = ({
  open,
  setOpen,
  rowData,
  indexNo,
  dispatchedDetails,
  setDispatchedDetails,
}) => {
  const [GroupAvlData, setGroupAvlData] = useState([]);
  const [balanceQty, setBalanceQty] = useState(0);
  const [dispatchedQty, setDispatchedQty] = useState({
    item_no: 0,
    dispatch: [],
    total_pcs: 0,
    group_sqm: 0,
    total_amount: 0,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnChange = (e, index, data) => {
    const { name, value } = e.target;
    let previousData = [...dispatchedQty["dispatch"]];
    if (value != "") {
      // To add
      const sqm = parseFloat(
        (data?.qc_length * data?.qc_width * value) / 10000
      ).toFixed(2);
      const total_item_amount = parseFloat(sqm * rowData?.order_rate).toFixed(
        2
      );
      const qtyData = {
        qc_id: data?._id,
        qc_dispatched_qty: Number(value),
        dispatch_sqm: Number(sqm),
        total_item_amount: Number(total_item_amount),
      };

      previousData[index] = qtyData;
    } else {
      // To remove
      previousData.splice(index, 1);
    }

    const finalData = previousData.reduce(
      (acc, ele, i) => {
        acc.group_sqm += ele?.dispatch_sqm || 0;
        acc.total_pcs += ele?.qc_dispatched_qty || 0;
        acc.total_amount += ele?.total_item_amount || 0;
        return acc;
      },
      {
        group_sqm: 0,
        total_amount: 0,
        total_pcs: 0,
      }
    );
    setDispatchedQty((prev) => ({
      ...prev,
      ...finalData,
      dispatch: previousData,
      item_no: rowData?.item_no,
    }));
    setBalanceQty(finalData?.total_pcs);
  };

  useEffect(() => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const fetchData = async () => {
      try {
        const AvlGroupData = await Axios.post(
          `${AllApis.dispatch.getAvlGroupData(rowData?.order_group_no)}`,
          {},
          config
        );
        setGroupAvlData(AvlGroupData?.data?.result);
      } catch (error) {
        setGroupAvlData([]);
      }
    };
    open && fetchData();
    const isDataFilledBefore = dispatchedDetails?.filter(
      (ele) => ele?.item_no == rowData?.item_no
    );

    if (isDataFilledBefore.length > 0) {
      setDispatchedQty(isDataFilledBefore[0]);
      setBalanceQty(isDataFilledBefore[0]?.total_pcs);
    } else {
      setBalanceQty(0);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Add Dispatched Quantity (Group No : {rowData?.order_group_no})
        </Typography>
        <Div sx={{ display: "flex", columnGap: 5, mt: 2 }}>
          <Typography variant="h5" color="initial" fontWeight={600}>
            Required Pcs : {rowData?.order_balance_pcs_qty}
          </Typography>
          <Typography variant="h5" color="initial" fontWeight={600}>
            Dispatching Pcs : {balanceQty}
          </Typography>
        </Div>
        <TableContainer component={Paper}>
          <Table border={2}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "80px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Sr No.
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Length
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Width
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Available Pcs
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Available Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Pcs
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Rate Per Sqm
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    width: "100px",
                    verticalAlign: "middle",
                    px: 1,
                    color: "white",
                  }}
                >
                  Dispatched Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {GroupAvlData?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {i + 1}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.qc_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.qc_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.qc_no_of_pcs_available}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {row?.qc_sqm}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    <TextField
                      size="small"
                      name="dyed"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 0,
                        max: row?.qc_no_of_pcs_available,
                      }}
                      onKeyDown={(e) => {
                        const inputValue = parseInt(e.target.value + e.key, 10);

                        if (
                          isNaN(inputValue) ||
                          inputValue < 0 ||
                          inputValue > row?.qc_no_of_pcs_available
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => handleOnChange(e, i, row)}
                      defaultValue={
                        dispatchedQty["dispatch"]?.[i]?.qc_dispatched_qty
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {dispatchedQty["dispatch"]?.[i]?.dispatch_sqm
                      ? dispatchedQty["dispatch"]?.[i]?.dispatch_sqm
                      : "--"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.order_rate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {dispatchedQty["dispatch"]?.[i]?.total_item_amount
                      ? dispatchedQty["dispatch"]?.[i]?.total_item_amount
                      : "--"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {GroupAvlData.length === 0 && (
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
                height: "100px",
                p: 5,
              }}
              variant="h5"
            >
              No Data Found
            </Typography>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ textAlign: "center" }}>
        <Button
          disabled={balanceQty == 0}
          variant="contained"
          onClick={() => {
            if (balanceQty > rowData?.order_balance_pcs_qty) {
              Swal.fire({
                position: "center",
                icon: "warning",
                text: "Dispatching More than Required Pcs",
                customClass: {
                  container: "popupImportant",
                },
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              const previous = [...dispatchedDetails];
              previous?.length === 0
                ? (previous[indexNo] = dispatchedQty)
                : previous.splice(indexNo, 1, dispatchedQty);
              setDispatchedDetails(previous);
              setOpen(false);
            }
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
