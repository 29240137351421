//ReadyForDispatch
export const ALL_READY_FOR_DISPATCH_REQUEST = "ALL_READY_FOR_DISPATCH_REQUEST";
export const ALL_READY_FOR_DISPATCH_SUCCESS = "ALL_READY_FOR_DISPATCH_SUCCESS";
export const ALL_READY_FOR_DISPATCH_FAIL = "ALL_READY_FOR_DISPATCH_FAIL";

//Raw Dispatch
export const RAW_DISPATCH_REQUEST = "RAW_DISPATCH_REQUEST";
export const RAW_DISPATCH_SUCCESS = "RAW_DISPATCH_SUCCESS";
export const RAW_DISPATCH_FAIL = "RAW_DISPATCH_FAIL";

//Group Dispatch
export const GROUP_DISPATCH_REQUEST = "GROUP_DISPATCH_REQUEST";
export const GROUP_DISPATCH_SUCCESS = "GROUP_DISPATCH_SUCCESS";
export const GROUP_DISPATCH_FAIL = "GROUP_DISPATCH_FAIL";
