/* eslint-disable default-case */
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import AllApis from "app/Apis";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getGroupDispatchedList } from "app/redux/actions/dispatchAction";
import { displayDateFun } from "app/utils/constants/functions";
import { Axios } from "index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CompleteGroupItemDetailsModal } from "../Modal/completeGroupItemDetails";
import { DispatchedGroupItemDetailsModal } from "../Modal/dispatchedGroupItemDetails";

export default function DispatchedGroupOrders({
  page,
  setPage,
  searchTerm,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupDispatched, error, TotalPage, loading } = useSelector(
    (state) => state.dispatchReducer
  );

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dispatchedOpen, setDispatchedOpen] = useState(false);
  const permissions = useSelector(
    (state) => state?.userReducer?.user?.[0]?.role_id?.permissions
  );

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getGroupDispatchedList(searchTerm, sort, sortBy, newPage, filter));
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "delete":
        deleteRawVeneer(menuItem?.data?._id);
        break;
    }
  };

  const deleteRawVeneer = async (id) => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await Axios.post(
            AllApis.dispatch.deleteGroupDispatch(id),
            {},
            config
          );
          if (response) {
            dispatch(getGroupDispatchedList("", "desc", "updated_at", 1, {}));
            Swal.fire({
              title: "Deleted",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          Swal.fire({
            title: error.message,
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  // px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "order_details.order_no"}
                  direction={sort}
                  onClick={() => handleSort("order_details.order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "invoice_no"}
                  direction={sort}
                  onClick={() => handleSort("invoice_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "order_details.customer_name"}
                  direction={sort}
                  onClick={() => handleSort("order_details.customer_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Customer Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "order_details.place"}
                  direction={sort}
                  onClick={() => handleSort("order_details.place")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "order_details.order_mode"}
                  direction={sort}
                  onClick={() => handleSort("order_details.order_mode")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order Mode
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "108px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Dispatched Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "created_employee_id.first_name"}
                  direction={sort}
                  onClick={() => handleSort("created_employee_id.first_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "90px",
                  verticalAlign: "middle",
                  px: 1,
                  color: "white",
                }}
              >
                Dispatched Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "300px",
                  verticalAlign: "middle",
                  px: 1,
                }}
              >
                <TableSortLabel
                  active={sortBy === "dispatch_remarks"}
                  direction={sort}
                  onClick={() => handleSort("dispatch_remarks")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              {permissions?.dispatch_edit == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "95px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupDispatched?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.order_details?.order_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.invoice_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.order_details?.customer_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.order_details?.place}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.order_details?.order_mode}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {displayDateFun(row?.dispatched_date)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", px: 1, textTransform: "capitalize" }}
                >
                  {row?.created_employee_id?.first_name}{" "}
                  {row?.created_employee_id?.last_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setOpen(true);
                      setRowData(row?.order_details);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 2 }}>
                  <Div
                    onClick={() => {
                      setDispatchedOpen(true);
                      setRowData(row);
                    }}
                  >
                    <InfoIcon sx={{ cursor: "pointer" }} />
                  </Div>
                </TableCell>
                <TableCell sx={{ textAlign: "left", px: 1 }}>
                  {row?.dispatch_remarks || "-"}
                </TableCell>
                {permissions?.dispatch_edit == true && (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <DeleteIcon />,
                          title: `Delete`,
                          action: "delete",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {open && (
            <CompleteGroupItemDetailsModal
              open={open}
              setOpen={setOpen}
              rowData={rowData}
            />
          )}
          {dispatchedOpen && (
            <DispatchedGroupItemDetailsModal
              open={dispatchedOpen}
              setOpen={setDispatchedOpen}
              rowData={rowData}
            />
          )}
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
