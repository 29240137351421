import AllApis from "app/Apis";
import {
  ALL_CREATED_GROUPING_FAIL,
  ALL_CREATED_GROUPING_REQUEST,
  ALL_CREATED_GROUPING_SUCCESS,
  ALL_GROUPING_HISTORY_FAIL,
  ALL_GROUPING_HISTORY_REQUEST,
  ALL_GROUPING_HISTORY_SUCCESS,
} from "app/utils/constants/groupingConstant";
import { Axios } from "index";

//created groups
export const getAllCreatedGroups =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
          status: "available",
          split: false,
        },
        searchFields: {
          string: [
            "group_grade",
            "orientation",
            "book_type",
            "group_pallete_no",
            "group_physical_location",

            "group_remarks",
          ],
          numbers: [
            "group_no",
            "group_length",
            "group_width",
            "group_no_of_pattas_available",
            "group_sqm_available",
            "group_pcs",
            "total_item_sqm_available",
          ],
          arrayField: [
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "item_details.item_name",
            "item_details.item_code",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_CREATED_GROUPING_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.grouping.createdGroupList}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_CREATED_GROUPING_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CREATED_GROUPING_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//created groups
export const getAllGroupHistory =
  (search_value, sort, sortBy, page, filter) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [],
          numbers: [
            "group_id.group_length",
            "group_id.group_pcs",
            "group_id.group_no",
            "group_id.group_width",
            "group_id.group_no_of_pattas_available",
            "group_id.group_sqm_available",
            "group_id.total_item_sqm_available",
          ],
          arrayField: [
            "group_id.group_grade",
            "group_id.orientation",
            "group_id.book_type",
            "group_id.group_pallete_no",
            "group_id.group_physical_location",
            "created_employee_id.first_name",
            "created_employee_id.last_name",
            "cutting_item_details.item_name",
            "cutting_item_details.item_code",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      dispatch({ type: ALL_GROUPING_HISTORY_REQUEST });

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      const data = await Axios.post(
        `${AllApis.grouping.groupHistory}?${urlParams.toString()}`,
        body,
        config
      );

      dispatch({
        type: ALL_GROUPING_HISTORY_SUCCESS,
        payload: {
          data: data?.data?.result,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_GROUPING_HISTORY_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
