import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import AllApis from "app/Apis";
import { Axios } from "index";
import { useEffect, useState } from "react";

export const IssuedQuantityDetails = ({ open, setOpen, groupNo }) => {
  const [issuedDetails, setIssuedDetails] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    try {
      const issuedQuantityDetails = await Axios.get(
        `${AllApis.order.issuedDetailsBasedOnGroupNo(groupNo)}`
      );
      setIssuedDetails(issuedQuantityDetails?.data?.result);
    } catch (error) {}
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Group Available Quantity (Group No : {`${groupNo}`})
        </Typography>
        <Div sx={{ mt: 2 }}>
          <Table border={2}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell sx={{ color: "white", px: 1 }}>
                  {" "}
                  Group Availabe Pattas
                </TableCell>

                <TableCell sx={{ color: "white", px: 1 }}>
                  {" "}
                  Smoking Issued Pattas
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  {" "}
                  Dying Issued Pattas
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  {" "}
                  Cutting Issued Pattas
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Tapping Issued Pattas
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Ready Sheet Form Pcs
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Pressing Issued Pcs
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  Finishing Issued Pcs
                </TableCell>
                <TableCell sx={{ color: "white", px: 1 }}>
                  QC Done Pcs
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ bgcolor: "#EDEBEB" }}>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.group_available}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_smoking}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_dying}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_cutting}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_tapping}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.ready_sheet_form}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_pressing}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.issue_for_finishing}
                </TableCell>
                <TableCell sx={{ px: 1 }}>
                  {issuedDetails?.qc_done_inventory}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
