import {
  ALL_READY_FOR_DISPATCH_FAIL,
  ALL_READY_FOR_DISPATCH_REQUEST,
  ALL_READY_FOR_DISPATCH_SUCCESS,
  GROUP_DISPATCH_FAIL,
  GROUP_DISPATCH_REQUEST,
  GROUP_DISPATCH_SUCCESS,
  RAW_DISPATCH_FAIL,
  RAW_DISPATCH_REQUEST,
  RAW_DISPATCH_SUCCESS,
} from "app/utils/constants/dispatchConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allReadyForDispatch: [],
  rawDispatched: [],
  groupDispatched: [],
  TotalPage: null,
};

export const dispatchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_READY_FOR_DISPATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_READY_FOR_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        allReadyForDispatch: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_READY_FOR_DISPATCH_FAIL:
      return {
        loading: false,
        allReadyForDispatch: [],
        error: action.payload,
      };

    case RAW_DISPATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RAW_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        rawDispatched: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case RAW_DISPATCH_FAIL:
      return {
        loading: false,
        rawDispatched: [],
        error: action.payload,
      };

    case GROUP_DISPATCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GROUP_DISPATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        groupDispatched: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case GROUP_DISPATCH_FAIL:
      return {
        loading: false,
        groupDispatched: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
