import Div from "@jumbo/shared/Div";
import {
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

export const PressingDetailsModal = ({ open, setOpen, rowData }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1000}>
      <DialogContent>
        <Typography variant="h3" color="initial" fontWeight={600}>
          Pressing Details:-
        </Typography>
        <Div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Group No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Pressing Length
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Pressing Width
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Pressing Pcs
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "145px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Pressing Sqm
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.ready_sheet_form_history_details?.group_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.pressing_details?.pressing_length}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.pressing_details?.pressing_width}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.pressing_details?.pressing_no_of_peices}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", px: 1 }}>
                    {rowData?.pressing_details?.pressing_sqm}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
