import Div from "@jumbo/shared/Div/Div";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { LoadingButton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AllApis from "app/Apis";
import ImageUpload from "app/components/UploadImages";
import { Axios } from "index";
import { CreateCuttingModal } from "../Modal/CreateCuttingBundleModal";
import { getCurrentDate } from "app/components/Function/getCurrentDate";

export default function CreateCutting() {
  const [indexNo, setIndexNo] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [rejectedBundles, setRejectedBundles] = useState({
    item_details: state?.cutting_item_details.map((ele) => {
      return {
        final_cutting_quantity: {
          natural: ele?.cutting_quantity?.natural,
          dyed: ele?.cutting_quantity?.dyed,
          smoked: ele?.cutting_quantity?.smoked,
          total: ele?.cutting_quantity?.total,
        },
        cutting_length: ele?.item_length,
        cutting_width: ele?.item_width,
        cutting_sqm: (
          (ele?.item_length * ele?.item_width * ele?.cutting_quantity?.total) /
          10000
        ).toFixed(2),
        cutting_no_of_pattas: ele?.cutting_quantity?.total,
        item_id: ele?._id,
      };
    }),
    cutting_date: getCurrentDate(),
    cutting_remarks: "",
  });

  const [images, setImages] = useState([]);

  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const handleOnChange = (e, indexNo, rowData) => {
    const { name, value } = e.target;
    let quantity = rejectedBundles?.["item_details"]?.[indexNo]?.item_id
      ? rejectedBundles?.["item_details"]?.[indexNo]
      : {
          final_cutting_quantity: {
            natural: 0,
            dyed: 0,
            smoked: 0,
            total: 0,
          },
          cutting_length: 0,
          cutting_width: 0,
          cutting_sqm: 0,
          cutting_no_of_pattas: 0,
        };

    let rejectedQty;

    if (name == "cutting_length" || name == "cutting_width") {
      rejectedQty = {
        ...quantity,
        [name]: Number(value),
      };
    } else {
      rejectedQty = {
        ...quantity,
        final_cutting_quantity: {
          ...quantity.final_cutting_quantity,
          [name]: Number(value),
        },
      };
    }
    const { natural, dyed, smoked } = rejectedQty.final_cutting_quantity;
    const totalReceived =
      (parseFloat(natural) || 0) +
      (parseFloat(dyed) || 0) +
      (parseFloat(smoked) || 0);
    rejectedQty.final_cutting_quantity.total = totalReceived;
    rejectedQty.cutting_no_of_pattas = totalReceived;
    // setQuantity((prev) => ({ ...prev, ...rejectedQty }));
    if (rejectedQty?.cutting_length != 0 && rejectedQty?.cutting_width != 0) {
      const sqm = parseFloat(
        (Number(rejectedQty?.cutting_length || 0) *
          Number(rejectedQty?.cutting_width || 0) *
          rejectedQty?.cutting_no_of_pattas || 0) / 10000
      ).toFixed(2);
      rejectedQty.cutting_sqm = sqm;
    }
    // setQuantity((prev) => ({ ...prev, cutting_sqm: sqm }));

    const rejectedArr = [...rejectedBundles["item_details"]];
    rejectedArr[indexNo] = {
      ...rejectedQty,
      item_id: rowData?._id,
    };
    setRejectedBundles({
      ...rejectedBundles,
      item_details: rejectedArr,
    });
  };

  const handleSubmit = async () => {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };
    const body = {
      cutting_date: rejectedBundles?.cutting_date,
      cutting_remarks: rejectedBundles?.cutting_remarks,
      issued_for_cutting_id: state?._id,
      group_id: state?.group_id?._id,
      issued_for_cutting_date: state?.created_at,
      group_history_id: state?.group_history_id?._id,
      // item_details: rejectedBundles?.item_details.filter(
      //   (ele) => ele?.["final_cutting_quantity"]?.["total"] != null
      // ),
      item_details: rejectedBundles?.item_details.filter(
        (obj) =>
          Object.values(obj).some((val) => val !== 0) &&
          obj.final_cutting_quantity.total !== 0
      ),
      cutting_images: images,
    };
    if (body?.item_details?.length == 0) {
      return Swal.fire({
        title: "Please Fill Cutting Data",
        icon: "warning",
        timer: 1000,
        showConfirmButton: false,
      });
    }
    const form = new FormData();
    // Iterate over the entries of the object and append each key-value pair to the FormData object
    Object.entries(body).forEach((entry) => {
      if (entry[0] == "cutting_images") {
        entry[1].forEach((file, index) => {
          form.append(entry[0], file);
        });
      } else if (entry[0] == "item_details") {
        form.append(entry[0], JSON.stringify(entry[1]));
      } else {
        form.append(entry[0], entry[1]);
      }
    });
    try {
      setSubmitting(true);
      const response = await Axios.post(
        AllApis.cutting.createCutting,
        form,
        config
      );
      if (response?.data?.message) {
        Swal.fire({
          title: "Cutting Created Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboard/factory/cutting");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Create Cutting
      </Typography>
      <Div>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell sx={{ color: "white" }}>Group No.</TableCell>
                <TableCell sx={{ color: "white" }}>Item Name</TableCell>
                <TableCell sx={{ color: "white" }}>Item Type</TableCell>
                <TableCell sx={{ color: "white" }}>Group Pcs</TableCell>
                <TableCell sx={{ color: "white" }}>Grade</TableCell>
                <TableCell sx={{ color: "white" }}>Pallet No.</TableCell>
                <TableCell sx={{ color: "white" }}>Physical Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{state?.group_id?.group_no}</TableCell>
                <TableCell>
                  {state?.cutting_item_details?.[0]?.item_name}
                </TableCell>
                <TableCell>
                  {state?.cutting_item_details?.[0]?.item_code}
                </TableCell>
                <TableCell>{state?.group_id?.group_pcs}</TableCell>
                <TableCell>{state?.group_id?.group_grade}</TableCell>
                <TableCell>{state?.group_id?.group_pallete_no}</TableCell>
                <TableCell>
                  {state?.group_id?.group_physical_location}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Div sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Log No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Bundle No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "90px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Length
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Width
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Sqm
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Supplier
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "120px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Issued Cutting Pattas
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Cutting Pattas
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Length
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Width
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                    }}
                  >
                    Sqm
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "150px",
                      verticalAlign: "middle",
                      px: 1,
                      color: "white",
                      position: "sticky",
                      right: 0,
                      height: "58px",
                      zIndex: 1,
                      bgcolor: "#202020",
                    }}
                  >
                    Add Cutting Quantity
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.cutting_item_details?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_log_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_bundle_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_length}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.item_width}
                    </TableCell>

                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {(
                        (row?.item_length *
                          row?.item_width *
                          row?.cutting_quantity?.total) /
                        10000
                      ).toFixed(2)}
                    </TableCell>

                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.supplier_details?.supplier_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", px: 1 }}>
                      {row?.cutting_quantity?.total || 0}
                    </TableCell>
                    {row?.cutting_quantity?.natural > 0 && (
                      <TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="natural"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.cutting_quantity?.natural,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue > row?.cutting_quantity?.natural
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          value={
                            rejectedBundles?.item_details?.[i]
                              ?.final_cutting_quantity?.natural
                          }
                        />
                      </TableCell>
                    )}
                    {row?.cutting_quantity?.dyed > 0 && (
                      <TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="dyed"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.cutting_quantity?.dyed,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue > row?.cutting_quantity?.dyed
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          value={
                            rejectedBundles?.item_details?.[i]
                              ?.final_cutting_quantity?.dyed
                          }
                        />
                      </TableCell>
                    )}
                    {row?.cutting_quantity?.smoked > 0 && (
                      <TableCell sx={{ width: "10%", px: 1 }}>
                        {" "}
                        <TextField
                          size="small"
                          name="smoked"
                          type="number"
                          sx={{ width: "100%" }}
                          inputProps={{
                            min: 0,
                            max: row?.cutting_quantity?.smoked,
                          }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );
                            if (
                              isNaN(inputValue) ||
                              inputValue < 0 ||
                              inputValue > row?.cutting_quantity?.smoked
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleOnChange(e, i, row)}
                          value={
                            rejectedBundles?.item_details?.[i]
                              ?.final_cutting_quantity?.smoked
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell sx={{ width: "10%", px: 1 }}>
                      <TextField
                        size="small"
                        name="cutting_length"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleOnChange(e, i, row)}
                        value={
                          rejectedBundles?.item_details?.[i]?.cutting_length
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: "10%", px: 1 }}>
                      <TextField
                        size="small"
                        name="cutting_width"
                        type="number"
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleOnChange(e, i, row)}
                        value={
                          rejectedBundles?.item_details?.[i]?.cutting_width
                        }
                      />
                    </TableCell>
                    <TableCell sx={{ width: "10%", px: 1 }}>
                      <TextField
                        disabled
                        sx={{ ...disabledStyling }}
                        size="small"
                        name="cutting_sqm"
                        value={rejectedBundles?.item_details?.[i]?.cutting_sqm}
                      />
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        textAlign: "left",
                        px: 2,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "white",
                      }}
                    >
                      <Div
                        onClick={() => {
                          setIndexNo(i);
                          setRowData(row);
                          setOpen(true);
                        }}
                      >
                        <ControlPointIcon sx={{ cursor: "pointer", ml: 3 }} />
                      </Div>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Div>
        <Div sx={{ width: "20%", mt: 2 }}>
          <Typography variant="h5">Cutting Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              format="DD-MM-YYYY"
              maxDate={dayjs()}
              defaultValue={
                rejectedBundles?.cutting_date !== ""
                  ? dayjs(rejectedBundles?.cutting_date)
                  : null
              }
              onChange={(newValue) => {
                setRejectedBundles((prev) => ({
                  ...prev,
                  cutting_date: newValue
                    .startOf("day")
                    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                }));
              }}
            />
          </LocalizationProvider>
        </Div>

        <Div sx={{ width: "50%", mt: 2 }}>
          <Div sx={{ width: "100%" }}>
            <ImageUpload
              setFieldValue={setImages}
              name={"cutting_images"}
              type={"withoutFormik"}
            />
          </Div>
          <Div>
            <Typography variant="h5">Remark</Typography>
            <TextField
              multiline
              size="small"
              value={rejectedBundles?.cutting_remark}
              sx={{ width: "100%" }}
              name="cutting_remark"
              onChange={(e) => {
                setRejectedBundles((prev) => ({
                  ...prev,
                  cutting_remarks: e.target.value,
                }));
              }}
            />
          </Div>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 5,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/dashboard/factory/cutting");
                }
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            // disabled={
            //   rejectedBundles?.item_details.length !==
            //   state?.cutting_item_details.length
            // }
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
          >
            Add For Cutting
          </LoadingButton>
        </Div>
      </Div>

      {/* <CreateCuttingModal
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        indexNo={indexNo}
        rejectedBundles={rejectedBundles}
        setRejectedBundles={setRejectedBundles}
      /> */}
    </Div>
  );
}
